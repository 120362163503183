import React, { useState, useEffect, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Scrollbars } from 'react-custom-scrollbars-2'
import debounce from 'lodash.debounce'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { createFilter } from 'react-select'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { ERROR_MESSAGE, FACEBOOK, TIKTOK, TWITTER, INSTAGRAM, PINTEREST, LINKEDIN } from 'consts'
import { pxToRem } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import withConfirm from 'utils/withConfirm'
import { Flex, Box, Grid } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import { H4, Text } from 'components/atoms/Typography'
import Input from 'components/atoms/Input'
import ErrorLabel from 'components/atoms/ErrorLabel'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import DropDown from 'shared/DropDown'
import ErrorFocus from 'shared/ErrorFocus'
import { ROUTE_BOOST_CONFIGURATION } from 'routes/Calendar/consts'
import SocialProfileImage from 'routes/Calendar/components/SocialProfileImage'
import {
  NAME,
  PROFILE,
  ACCOUNT,
  BUDGET_IN_DOLLARS,
  DAYS,
  BID_AMOUNT,
  DELAY,
  INTERESTS,
  WORK_POSITIONS,
  COUNTRIES,
  REGIONS,
  CITIES,
  GENDERS,
  AGE_MIN,
  AGE_MAX,
  IDENTITY,
  LOCATIONS,
  LANGUAGES,
  AGES,
  FUNDING_INSTRUMENT,
  FUNCTIONS,
  INDUSTRIES,
  GENDER,
  AGE_BUCKET,
  INTEREST,
  GEO,
  LOCALE,
  INCLUDE_AUDIENCES,
  EXCLUDE_AUDIENCES,
  INTEREST_CATEGORIES,
  DELAY_TYPE_MINUTES,
  DELAY_TYPE_HOURS,
  DELAY_TYPE_DAYS,
  DELAY_OPTIONS,
} from './consts'
import FacebookConfiguration from './Configurations/FacebookConfiguration'
import TikTokConfiguration from './Configurations/TikTokConfiguration'
import TwitterConfiguration from './Configurations/TwitterConfiguration'
import LinkedInConfiguration from './Configurations/LinkedInConfiguration'
import PinterestConfiguration from './Configurations/PinterestConfiguration'
import {
  collectTiktokIdentities,
  collectTiktokData,
  collectTwitterFundingInstruments,
  collectMetaAudiences,
  collectTwitterAudiences,
  collectLinkedInAudiences,
} from './helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    max-width: 800px;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${COLOR_CONSTANTS.DAISY};
  opacity: 0.3;
`

const AVAILABLE_NETWORKS = [FACEBOOK, TWITTER, TIKTOK, INSTAGRAM, LINKEDIN, PINTEREST]

const DEFAULT_HEIGHT = pxToRem(40)

const getConfigurationByNetwork = ({ code }) => {
  switch (code) {
    case FACEBOOK:
    case INSTAGRAM:
      return FacebookConfiguration
    case TIKTOK:
      return TikTokConfiguration
    case TWITTER:
      return TwitterConfiguration
    case LINKEDIN:
      return LinkedInConfiguration
    case PINTEREST:
      return PinterestConfiguration
    default:
      return null
  }
}

const formatProfileOptions = ({ picture_url, value, label, network: { code } }) => (
  <Flex alignItems="center" key={value}>
    <SocialProfileImage code={code} picture_url={picture_url} width="24px" height="24px" />
    <Flex ml="s" flexDirection="column">
      <Text>{label}</Text>
    </Flex>
  </Flex>
)

// TODO:: save search results

const BoostConfigurationModal = ({ confirm, isOpen, handleDismiss, boostId, selectedProfile, publicationId }) => {
  const formRef = useRef(null)

  const [isBoostConfigurationSaving, setIsBoostConfigurationSaving] = useState(false)
  const [isGettingInitialData, setIsGettingInitialData] = useState(true)
  const [isGettingData, setIsGettingData] = useState(false)
  const [formData, setFormData] = useState({})
  const [formDataTrigger, setFormDataTrigger] = useState(null)
  const [data, setData] = useState({ configuration: {} })
  const [profiles, setProfiles] = useState([])
  const [specialTriggerForGettingFormData, setSpecialTriggerForGettingFormData] = useState(null)
  const [specialTriggerForGettingTikTokIdentities, setSpecialTriggerForGettingTikTokIdentities] = useState(null)
  const [
    specialTriggerForGettingTwitterFundingInstruments,
    setSpecialTriggerForGettingTwitterFundingInstruments,
  ] = useState(null)
  const [specialTriggerForGettingMetaAudiences, setSpecialTriggerForGettingMetaAudiences] = useState(null)
  const [specialTriggerForGettingTikTokData, setSpecialTriggerForGettingTikTokData] = useState(null)
  const [specialTriggerForGettingPinterestAudiences, setSpecialTriggerForGettingPinterestAudiences] = useState(null)
  const [specialTriggerForGettingTwitterAudiences, setSpecialTriggerForGettingTwitterAudiences] = useState(null)
  const [specialTriggerForGettingLinkedInAudiences, setSpecialTriggerForGettingLinkedInAudiences] = useState(null)

  const getData = async () => {
    try {
      const promises = []

      if (!selectedProfile) {
        promises.push(request({ path: 'profiles' }))
      }

      if (boostId) {
        promises.push(request({ path: `${ROUTE_BOOST_CONFIGURATION}/boost/${boostId}` }))
      }

      const responses = await Promise.allSettled(promises)

      let profiles_request
      let boost_request

      if (selectedProfile) {
        // eslint-disable-next-line prefer-destructuring
        boost_request = responses[0]
      } else {
        // eslint-disable-next-line prefer-destructuring
        profiles_request = responses[0]
        // eslint-disable-next-line prefer-destructuring
        boost_request = responses[1]
      }

      let profiles_for_setting = []

      if (selectedProfile) {
        profiles_for_setting = [selectedProfile].filter(({ network: { code } }) => AVAILABLE_NETWORKS.includes(code))
      } else if (profiles_request) {
        const { value: { profiles } = {} } = profiles_request

        if (profiles) {
          profiles.forEach((profile) => {
            profile.value = profile.id
            profile.label = profile.nickname || profile.name || profile.username
          })

          profiles_for_setting = profiles.filter(({ network: { code } }) => AVAILABLE_NETWORKS.includes(code))
        }
      }

      setProfiles([...profiles_for_setting])

      if (boost_request) {
        const { value: { data: boost } = {} } = boost_request

        if (boost) {
          const { configuration } = boost

          for (const key in configuration) {
            if (configuration.hasOwnProperty(key)) {
              if (key === PROFILE) {
                configuration[key].value = configuration[key].profile_gid
                configuration[key].label =
                  configuration[key].nickname || configuration[key].name || configuration[key].username
              } else if (key === IDENTITY) {
                const { identity_id, display_name, profile_image, ...other } = configuration[key]

                configuration[key] = {
                  value: identity_id,
                  label: display_name,
                  picture_url: profile_image,
                  ...other,
                }
              } else if (key === FUNDING_INSTRUMENT) {
                const { id, description, ...other } = configuration[key]
                configuration[key] = {
                  value: id,
                  label: description,
                  ...other,
                }
              } else if (key === DELAY) {
                configuration[key] = configuration[key]
              } else if (Array.isArray(configuration[key])) {
                configuration[key].forEach((item) => {
                  item.value = item.id
                  item.label = item.name
                })
              } else if (typeof configuration[key] === 'object') {
                configuration[key].value = configuration[key].id
                configuration[key].label = configuration[key].name
              }
            }
          }

          if (boost.formData) {
            // accounts data will be collected in the formData function after useEffect fires

            const { profile } = configuration || {}
            const { profile_gid, network: { code } = {} } = profile

            if (boost.formData.identities) {
              setFormDataTrigger({
                ...{
                  keys: [code, profile_gid, configuration.account.profile_gid, 'identities'],
                  data: collectTiktokIdentities({ data: boost.formData.identities }),
                },
              })
            }

            if (boost.formData.funding_instruments) {
              setFormDataTrigger({
                ...{
                  keys: [code, profile_gid, configuration.account.id, 'funding_instruments'],
                  data: collectTwitterFundingInstruments({ data: boost.formData.funding_instruments }),
                },
              })
            }
          }

          setData({ ...boost })

          const { profile } = boost.configuration
          const { value, network: { code } = {} } = profile

          setSpecialTriggerForGettingFormData({ ...{ boost, profile_gid: value, code } })
        }
      } else if (profiles_for_setting.length === 1) {
        const boost = {
          configuration: {
            profile: profiles_for_setting[0],
          },
        }

        setData({ ...boost })

        const { value, network: { code } = {} } = profiles_for_setting[0]

        setSpecialTriggerForGettingFormData({ ...{ profile_gid: value, code } })
      }
    } catch (error) {
      errorHelper({ error, componentName: BoostConfigurationModal.displayName, functionName: 'getData' })
    } finally {
      setIsGettingInitialData(false)
    }
  }

  useEffect(() => {
    if (isOpen) {
      getData()
    }
  }, [isOpen])

  useEffect(() => {
    if (formDataTrigger) {
      const { keys, data } = formDataTrigger

      keys.reduce((accumulator, key, currentIndex) => {
        if (!accumulator[key]) {
          accumulator[key] = {}
        }

        if (currentIndex === keys.length - 1) {
          accumulator[key] = data
        }

        return accumulator[key]
      }, formData)

      setFormData({ ...formData })
    }
  }, [formDataTrigger])

  useEffect(() => {
    if (specialTriggerForGettingFormData) {
      // eslint-disable-next-line no-use-before-define
      getFormData(specialTriggerForGettingFormData)
    }
  }, [specialTriggerForGettingFormData])

  useEffect(() => {
    if (specialTriggerForGettingTikTokIdentities) {
      // eslint-disable-next-line no-use-before-define
      getTikTokIdentities(specialTriggerForGettingTikTokIdentities)
    }
  }, [specialTriggerForGettingTikTokIdentities])

  useEffect(() => {
    if (specialTriggerForGettingTwitterFundingInstruments) {
      // eslint-disable-next-line no-use-before-define
      getTwitterFundingInstruments(specialTriggerForGettingTwitterFundingInstruments)
    }
  }, [specialTriggerForGettingTwitterFundingInstruments])

  useEffect(() => {
    if (specialTriggerForGettingMetaAudiences) {
      // eslint-disable-next-line no-use-before-define
      getMetaAudiences(specialTriggerForGettingMetaAudiences)
    }
  }, [specialTriggerForGettingMetaAudiences])

  useEffect(() => {
    if (specialTriggerForGettingTikTokData) {
      // eslint-disable-next-line no-use-before-define
      getTikTokData(specialTriggerForGettingTikTokData)
    }
  }, [specialTriggerForGettingTikTokData])

  useEffect(() => {
    if (specialTriggerForGettingPinterestAudiences) {
      // eslint-disable-next-line no-use-before-define
      getPinterestAudiences(specialTriggerForGettingPinterestAudiences)
    }
  }, [specialTriggerForGettingPinterestAudiences])

  useEffect(() => {
    if (specialTriggerForGettingTwitterAudiences) {
      // eslint-disable-next-line no-use-before-define
      getTwitterAudiences(specialTriggerForGettingTwitterAudiences)
    }
  }, [specialTriggerForGettingTwitterAudiences])

  useEffect(() => {
    if (specialTriggerForGettingLinkedInAudiences) {
      // eslint-disable-next-line no-use-before-define
      getLinkedInAudiences(specialTriggerForGettingLinkedInAudiences)
    }
  }, [specialTriggerForGettingLinkedInAudiences])

  const getFormData = async ({ boost, profile_gid, code, setFieldValue }) => {
    let accounts_filtered = []

    try {
      setIsGettingData(true)

      let path = `${ROUTE_BOOST_CONFIGURATION}/${code}/formdata?profile_gid=${profile_gid}`

      if (boost && boost.formData && boost.formData.accounts) {
        path += `&include_accounts=false`
      }

      const response = await request({
        path,
        show_error: false,
      })

      const { error, accounts } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        if (!formData[code]) {
          formData[code] = {}
        }

        if (!formData[code][profile_gid]) {
          formData[code][profile_gid] = {}
        }

        if (accounts || (boost && boost.formData && boost.formData.accounts)) {
          accounts_filtered = (accounts || boost.formData.accounts).map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))

          formData[code][profile_gid].accounts = (accounts || boost.formData.accounts).map((item) => ({
            ...item,
            value: item.id,
            label: item.name,
          }))
        }

        const same_format_keys = [
          'genders',
          // 'objectives', 03-12-2024 objectives are hidden from the UI
          'ages',
          'functions',
          'industries',
          'interests',
          'locations',
          'locales',
        ]

        same_format_keys.forEach((key) => {
          if (response[key]) {
            formData[code][profile_gid][key] = response[key].map((item) => ({
              value: item.id.toString(),
              label: item.name,
              type: item.type,
              description: item.description,
            }))
          }
        })

        setFormData({ ...formData })
      }
    } catch (error) {
      errorHelper({ error, componentName: BoostConfigurationModal.displayName, functionName: 'getFormData' })
    } finally {
      setIsGettingData(false)

      setSpecialTriggerForGettingFormData(null)

      if (accounts_filtered && accounts_filtered.length !== 0) {
        let account

        if (setFieldValue) {
          if (accounts_filtered.length === 1) {
            // eslint-disable-next-line prefer-destructuring
            account = accounts_filtered[0]

            setFieldValue(ACCOUNT, account)

            if (code === TIKTOK) {
              setFieldValue(IDENTITY, null)

              setSpecialTriggerForGettingTikTokIdentities({
                ...{
                  tiktokads_profile_gid: account.profile_gid,
                  code,
                  profile_gid,
                  setFieldValue,
                },
              })
            } else if (code === TWITTER) {
              setFieldValue(FUNDING_INSTRUMENT, null)

              setSpecialTriggerForGettingTwitterFundingInstruments({
                ...{
                  account_id: account.value,
                  code,
                  profile_gid,
                  setFieldValue,
                },
              })
            }
          }
        }

        if (boost && boost.configuration && boost.configuration.account) {
          // eslint-disable-next-line prefer-destructuring
          account = boost.configuration.account
        }

        if (account) {
          if (code === FACEBOOK || code === INSTAGRAM) {
            setSpecialTriggerForGettingMetaAudiences({
              ...{
                account_id: account.id,
                code,
                profile_gid,
              },
            })
          } else if (code === TIKTOK) {
            setSpecialTriggerForGettingTikTokData({
              ...{
                tiktokads_profile_gid: account.profile_gid,
                code,
                profile_gid,
              },
            })
          } else if (code === PINTEREST) {
            setSpecialTriggerForGettingPinterestAudiences({
              ...{
                account_id: account.id,
                code,
                profile_gid,
              },
            })
          } else if (code === TWITTER) {
            setSpecialTriggerForGettingTwitterAudiences({
              ...{
                account_id: account.id,
                code,
                profile_gid,
              },
            })
          } else if (code === LINKEDIN) {
            setSpecialTriggerForGettingLinkedInAudiences({
              ...{
                account_id: account.id,
                code,
                profile_gid,
              },
            })
          }
        }
      }
    }
  }

  const getTikTokIdentities = async ({ tiktokads_profile_gid, code, profile_gid, setFieldValue }) => {
    if (!formData?.[code]?.[profile_gid]?.[tiktokads_profile_gid]?.identities) {
      try {
        setIsGettingData(true)

        const response = await request({
          path: `${ROUTE_BOOST_CONFIGURATION}/${code}/identities?tiktokads_profile_gid=${tiktokads_profile_gid}`,
        })

        const { error, identities = [] } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          // eslint-disable-next-line no-lonely-if
          if (identities) {
            const identities_collected = collectTiktokIdentities({ data: identities })

            setFormDataTrigger({
              ...{
                keys: [code, profile_gid, tiktokads_profile_gid, 'identities'],
                data: identities_collected,
              },
            })

            if (setFieldValue && identities.length === 1) {
              setFieldValue(IDENTITY, identities_collected[0])
            }
          }
        }
      } catch (error) {
        errorHelper({ error, componentName: BoostConfigurationModal.displayName, functionName: 'getTikTokIdentities' })
      } finally {
        setIsGettingData(false)
      }
    }
    setSpecialTriggerForGettingTikTokIdentities(null)
  }

  const getTwitterFundingInstruments = async ({ account_id, code, profile_gid, setFieldValue }) => {
    if (!formData?.[code]?.[profile_gid]?.[account_id]?.funding_instruments) {
      try {
        setIsGettingData(true)

        const response = await request({
          path: `${ROUTE_BOOST_CONFIGURATION}/${code}/funding_instruments?profile_gid=${profile_gid}&account_id=${account_id}`,
        })

        const { error, funding_instruments = [] } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (funding_instruments) {
          const funding_instruments_collected = collectTwitterFundingInstruments({
            data: funding_instruments,
          })

          setFormDataTrigger({
            ...{
              keys: [code, profile_gid, account_id, 'funding_instruments'],
              data: funding_instruments_collected,
            },
          })

          if (setFieldValue && funding_instruments.length === 1) {
            setFieldValue(FUNDING_INSTRUMENT, funding_instruments_collected[0])
          }
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BoostConfigurationModal.displayName,
          functionName: 'getTwitterFundingInstruments',
        })
      } finally {
        setIsGettingData(false)
      }
    }
    setSpecialTriggerForGettingTwitterFundingInstruments(null)
  }

  const getMetaAudiences = async ({ account_id, code, profile_gid }) => {
    if (!formData?.[code]?.[profile_gid]?.[account_id]?.audiences) {
      try {
        setIsGettingData(true)

        const response = await request({
          path: `${ROUTE_BOOST_CONFIGURATION}/${code}/audiences?profile_gid=${profile_gid}&ad_account_id=${account_id}`,
        })

        const { error, audiences = [] } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (audiences) {
          setFormDataTrigger({
            ...{
              keys: [code, profile_gid, account_id, 'audiences'],
              data: collectMetaAudiences({
                data: audiences,
              }),
            },
          })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BoostConfigurationModal.displayName,
          functionName: 'getMetaAudiences',
        })
      } finally {
        setIsGettingData(false)
      }
    }
    setSpecialTriggerForGettingMetaAudiences(null)
  }

  const getTikTokData = async ({ tiktokads_profile_gid, code, profile_gid }) => {
    if (
      !formData?.[code]?.[profile_gid]?.[tiktokads_profile_gid]?.audiences ||
      !formData?.[code]?.[profile_gid]?.[tiktokads_profile_gid]?.interests
    ) {
      try {
        setIsGettingData(true)

        const response = await request({
          path: `${ROUTE_BOOST_CONFIGURATION}/${code}/data?tiktokads_profile_gid=${tiktokads_profile_gid}`,
        })

        const { error, audiences = [], interest_categories = [] } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (audiences || interest_categories) {
          setFormDataTrigger({
            ...{
              keys: [code, profile_gid, tiktokads_profile_gid, 'audiences'],
              data: audiences ? collectTiktokData({ data: audiences }) : [],
            },
          })

          setFormDataTrigger({
            ...{
              keys: [code, profile_gid, tiktokads_profile_gid, 'interests'],
              data: interest_categories
                ? collectTiktokData({
                    data: interest_categories,
                  })
                : [],
            },
          })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BoostConfigurationModal.displayName,
          functionName: 'getTikTokData',
        })
      } finally {
        setIsGettingData(false)
      }
    }
    setSpecialTriggerForGettingTikTokData(null)
  }

  const getPinterestAudiences = async ({ account_id, code, profile_gid }) => {
    if (!formData?.[code]?.[profile_gid]?.[account_id]?.audiences) {
      try {
        setIsGettingData(true)

        const response = await request({
          path: `${ROUTE_BOOST_CONFIGURATION}/${code}/audiences?profile_gid=${profile_gid}&ad_account_id=${account_id}`,
        })

        const { error, audiences = [] } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (audiences) {
          setFormDataTrigger({
            ...{
              keys: [code, profile_gid, account_id, 'audiences'],
              data: collectMetaAudiences({
                data: audiences,
              }),
            },
          })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BoostConfigurationModal.displayName,
          functionName: 'getPinterestAudiences',
        })
      } finally {
        setIsGettingData(false)
      }
    }
    setSpecialTriggerForGettingPinterestAudiences(null)
  }

  const getTwitterAudiences = async ({ account_id, code, profile_gid }) => {
    if (!formData?.[code]?.[profile_gid]?.[account_id]?.audiences) {
      try {
        setIsGettingData(true)

        const response = await request({
          path: `${ROUTE_BOOST_CONFIGURATION}/${code}/audiences?profile_gid=${profile_gid}&ad_account_id=${account_id}`,
        })

        const { error, audiences = [] } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (audiences) {
          setFormDataTrigger({
            ...{
              keys: [code, profile_gid, account_id, 'audiences'],
              data: collectTwitterAudiences({
                data: audiences,
              }),
            },
          })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BoostConfigurationModal.displayName,
          functionName: 'getTwitterAudiences',
        })
      } finally {
        setIsGettingData(false)
      }
    }
    setSpecialTriggerForGettingTwitterAudiences(null)
  }

  const getLinkedInAudiences = async ({ account_id, code, profile_gid }) => {
    if (!formData?.[code]?.[profile_gid]?.[account_id]?.audiences) {
      try {
        setIsGettingData(true)

        const response = await request({
          path: `${ROUTE_BOOST_CONFIGURATION}/${code}/audiences?profile_gid=${profile_gid}&ad_account_id=${account_id}`,
        })

        const { error, audiences = [] } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (audiences) {
          setFormDataTrigger({
            ...{
              keys: [code, profile_gid, account_id, 'audiences'],
              data: collectLinkedInAudiences({
                data: audiences,
              }),
            },
          })
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BoostConfigurationModal.displayName,
          functionName: 'getLinkedInAudiences',
        })
      } finally {
        setIsGettingData(false)
      }
    }
    setSpecialTriggerForGettingLinkedInAudiences(null)
  }

  const createValidationSchema = (values) => {
    const isDropdownValueRequiredShape = {
      value: Yup.string().required(),
      label: Yup.string().required(),
    }

    const data = {
      [NAME]: Yup.string()
        .max(100, `Boost name is too long - should be 100 chars maximum.`)
        .required('Boost name is required.'),
      [PROFILE]: Yup.object()
        .shape(isDropdownValueRequiredShape)
        .typeError('Profile is required.')
        .required('Profile is required.'),
      [BUDGET_IN_DOLLARS]: Yup.number()
        .min(0, `Budget in dollars must be not less than 0`)
        .positive("Budget can't start with a minus")
        .required('Budget in dollars is required'),
      [DAYS]: Yup.number()
        .min(0, `Day value must be not less than 0`)
        .max(365, `Maximum days value must be not more than 365`)
        .positive("Day value can't start with a minus")
        .required('Days value is required'),
      [DELAY]: Yup.object()
        .test({
          name: DELAY,
          test(data) {
            const { type, value } = data

            let message = ''

            if (!value) {
              message = 'Delay is required.'
            } else if (value <= 0) {
              message = `Delay must be greater than zero.`
            } else if (type === DELAY_TYPE_MINUTES && value < 10) {
              message = `Delay must be at least 10 minutes.`
            } else if ((type === DELAY_TYPE_HOURS && value > 8760) || (type === DELAY_TYPE_DAYS && value > 365)) {
              message = `Delay can't be more than 1 year due to network specifications.`
            }

            return !message
              ? true
              : this.createError({
                  message,
                  path: DELAY,
                })
          },
        })
        .typeError('Delay information is required.')
        .required('Delay information is required.'),
    }

    if (values[PROFILE]) {
      const { network: { code } = {}, value } = values[PROFILE]

      if (code === FACEBOOK) {
        data[BID_AMOUNT] = Yup.number()
          .min(0, `Bid amount in dollars must be not less than 0`)
          .positive("Bid amount can't start with a minus")
          .required('Bid amount in dollars is required')
      }

      let formDataProfile = {}

      if (formData?.[code]?.[value]) {
        formDataProfile = formData[code][value]
      }

      const { accounts = [] } = formDataProfile || {}

      data[ACCOUNT] = Yup.object()
        .shape(isDropdownValueRequiredShape)
        .typeError('Account information is required.')
        .required('Account information is required.')
        .test({
          name: ACCOUNT,
          test(data) {
            const found_account = accounts.find((account) => account.value.toString() === data.value.toString())

            return found_account
              ? true
              : this.createError({
                  message: 'This account is not valid.',
                  path: ACCOUNT,
                })
          },
        })

      if (values[ACCOUNT]) {
        const { [values[ACCOUNT].profile_gid]: formDataAccountByProfile, [values[ACCOUNT].id]: formDataAccountById } =
          formDataProfile || {}

        // eslint-disable-next-line prefer-const
        let { audiences = [], identities = [] } = formDataAccountByProfile || {}

        const { funding_instruments = [], audiences: audiencesTwitter = [] } = formDataAccountById || {}

        if (code === TWITTER) {
          audiences = audiencesTwitter
        }

        if (code === TIKTOK) {
          data[IDENTITY] = Yup.object()
            .shape(isDropdownValueRequiredShape)
            .typeError('Identity is required.')
            .required('Identity is required.')
            .test({
              name: IDENTITY,
              test(data) {
                const found_identity = identities.find(
                  (identity) => identity.value.toString() === data.value.toString()
                )

                return found_identity
                  ? true
                  : this.createError({
                      message: 'This identity is not valid.',
                      path: IDENTITY,
                    })
              },
            })
        } else if (code === TWITTER) {
          data[FUNDING_INSTRUMENT] = Yup.object()
            .shape(isDropdownValueRequiredShape)
            .typeError('Funding instrument is required.')
            .required('Funding instrument is required.')
            .test({
              name: FUNDING_INSTRUMENT,
              test(data) {
                console.log('funding_instruments', funding_instruments)
                const found_funding_instrument = funding_instruments.find(
                  (funding_instrument) => funding_instrument.value.toString() === data.value.toString()
                )

                return found_funding_instrument
                  ? true
                  : this.createError({
                      message: 'This funding instrument is not valid.',
                      path: FUNDING_INSTRUMENT,
                    })
              },
            })
        }

        if (values[INCLUDE_AUDIENCES]) {
          data[INCLUDE_AUDIENCES] = Yup.array().test({
            name: INCLUDE_AUDIENCES,
            test(data) {
              const not_found_audiences_labels = []
              data.forEach(({ value, label }) => {
                if (!audiences.find((audience) => audience.value.toString() === value.toString())) {
                  not_found_audiences_labels.push(label)
                }
              })

              return not_found_audiences_labels.length === 0
                ? true
                : this.createError({
                    message: `Audiences not found: ${not_found_audiences_labels.join(', ')}`,
                    path: INCLUDE_AUDIENCES,
                  })
            },
          })
        }

        if (values[EXCLUDE_AUDIENCES]) {
          data[EXCLUDE_AUDIENCES] = Yup.array().test({
            name: EXCLUDE_AUDIENCES,
            test(data) {
              const not_found_audiences_labels = []

              data.forEach(({ value, label }) => {
                if (!audiences.find((audience) => audience.value.toString() === value.toString())) {
                  not_found_audiences_labels.push(label)
                }
              })

              return not_found_audiences_labels.length === 0
                ? true
                : this.createError({
                    message: `Audiences not found: ${not_found_audiences_labels.join(', ')}`,
                    path: EXCLUDE_AUDIENCES,
                  })
            },
          })
        }
      }
    }

    return Yup.object().shape(data)
  }

  const searchForData = async ({ value, network, id, method, objective_type = '', countries = '', regions = '' }) => {
    const updatedValue = value.trim()

    if (updatedValue && updatedValue.length > 1) {
      let path = `${ROUTE_BOOST_CONFIGURATION}/${network}/search?profile_gid=${id}&q=${value}&method=${method}`

      if (objective_type) {
        path += `&objective_type=${objective_type}`
      }

      if (countries) {
        path += `&countries=${countries}`
      }

      if (regions) {
        path += `&regions=${regions}`
      }

      try {
        const response = await request({
          path,
        })

        const { error } = response
        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          return response.map(({ id, name, ...props }) => ({
            value: id,
            label: name,
            ...props,
          }))
        }
      } catch (error) {
        errorHelper({
          error,
          componentName: BoostConfigurationModal.displayName,
          functionName: `searchForData (network: ${network}, profile_gid: ${id}, method: ${method}, value: ${value})`,
        })
      }
    }
    return []
  }

  const loadSuggestedOptions = React.useCallback(
    debounce((loadData, callback) => {
      searchForData(loadData).then((options) => {
        callback(options)
      })
    }, 500),
    []
  )

  const handleClickCloseModal = () => {
    if (!isBoostConfigurationSaving) {
      handleDismiss()
    }
  }

  const handleSubmitForm = async ({ values, skipTiktokErrors = false }) => {
    const { [PROFILE]: { name: profileName, network: { code } = {} } = {} } = values

    const { label: identityName } = values[IDENTITY] || {}

    if (!skipTiktokErrors && code === TIKTOK && identityName !== profileName) {
      confirm({
        fn: () => () => handleSubmitForm({ values, skipTiktokErrors: true }),
        message: `The name of selected TikTok profile does not match the name of the selected identity. Are you sure you have selected an Ad Account that is connected with this TikTok profile? <a style="color: ${colors.primary};" href="https://support.vistasocial.com/" target="_blank">Learn more</a>.`,
        action: 'Confirm',
      })

      return
    }

    try {
      setIsBoostConfigurationSaving(true)

      const body = {
        [NAME]: values[NAME],
        profile_gid: values[PROFILE].value,
        configuration: {
          kind: values[PROFILE].network.code,
          account_id: values[ACCOUNT].value,
        },
      }

      const keys = [
        DELAY,
        BUDGET_IN_DOLLARS,
        DAYS,
        BID_AMOUNT,
        GENDERS,
        INTERESTS,
        WORK_POSITIONS,
        COUNTRIES,
        REGIONS,
        CITIES,
        AGE_MIN,
        AGE_MAX,
        IDENTITY,
        // OBJECTIVE, // 03-12-2024 objective is hidden on the ui
        LOCATIONS,
        LANGUAGES,
        AGES,
        FUNDING_INSTRUMENT,
        INTERESTS,
        FUNCTIONS,
        INDUSTRIES,
        GENDER,
        AGE_BUCKET,
        INTEREST,
        GEO,
        LOCALE,
        INCLUDE_AUDIENCES,
        EXCLUDE_AUDIENCES,
        INTEREST_CATEGORIES,
      ]

      keys.forEach((key) => {
        if (values[key]) {
          if (key === IDENTITY) {
            body.configuration.identity_id = values[key].value
            body.configuration.identity_type = values[key].identity_type
          } else if (key === FUNDING_INSTRUMENT) {
            const { value, label, ...other } = values[key]

            body.configuration[FUNDING_INSTRUMENT] = {
              id: value,
              name: label,
              ...other,
            }
          } else if (key === DELAY) {
            body.configuration[DELAY] = {
              type: values[DELAY].type,
              value: Number(values[DELAY].value),
            }
          } else if (Array.isArray(values[key])) {
            body.configuration[key] = values[key].map(({ value, label, type, description }) => {
              const data = {
                id: value,
                name: label,
              }

              if (type) {
                data.type = type
              }

              if (description) {
                data.description = description
              }

              return data
            })
          } else if (typeof values[key] === 'object') {
            body.configuration[key] = {
              id: values[key].value,
              name: values[key].label,
            }

            if (values[key].description) {
              body.configuration[key].description = values[key].description
            }
          } else {
            body.configuration[key] = values[key]
          }
        }
      })

      if (data && data._id && publicationId) {
        body.publication_id = publicationId
      }

      const response = await request({
        path: `${ROUTE_BOOST_CONFIGURATION}${data._id ? `/${data._id}` : ''}`,
        method: data._id ? 'PATCH' : 'POST',
        body,
      })

      const { error, data: return_data, target_result_boosted } = response || {}

      setIsBoostConfigurationSaving(false)

      if (error || !response) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        if (target_result_boosted) {
          if (target_result_boosted.error) {
            Alert.error(`Boost failed: ${target_result_boosted.error || ERROR_MESSAGE}`, { timeout: 5000 })
          } else {
            Alert.success('Post has been boosted!', { timeout: 5000 })
          }
        } else {
          Alert.success(data && data._id ? 'Boost has been updated.' : 'Boost has been created.', { timeout: 5000 })
        }

        handleDismiss({ boost: return_data, target_result_boosted, isNew: !data || !data._id })
      }
    } catch (error) {
      errorHelper({ error, componentName: BoostConfigurationModal.displayName, functionName: 'handleSubmitForm' })
      setIsBoostConfigurationSaving(false)
    }
  }

  const handleSubmitFormFromButton = () => {
    formRef.current.handleSubmit()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">{boostId ? 'Edit boost' : 'Create boost'}</H4>
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" flex="1" position="relative">
            <StyledProgressWrapper
              display={isGettingInitialData || isGettingData ? 'flex' : 'none'}
              alignItems="center"
              justifyContent="center"
            >
              <ClipLoader size="50" color={colors.primary} />
            </StyledProgressWrapper>

            <Scrollbars universal>
              <Formik
                initialValues={{
                  [NAME]: data[NAME] || '',
                  [PROFILE]: data.configuration[PROFILE] || null,
                  [ACCOUNT]: data.configuration[ACCOUNT] || null,
                  [BUDGET_IN_DOLLARS]: data.configuration[BUDGET_IN_DOLLARS] || '',
                  [DAYS]: data.configuration[DAYS] || '',
                  [BID_AMOUNT]: data.configuration[BID_AMOUNT] || 1,
                  [DELAY]: data.configuration[DELAY] || { value: 1, type: DELAY_TYPE_HOURS },
                  [IDENTITY]: data.configuration[IDENTITY] || null, // tiktok
                  [FUNDING_INSTRUMENT]: data.configuration[FUNDING_INSTRUMENT] || null, // twitter
                  [INCLUDE_AUDIENCES]: data.configuration[INCLUDE_AUDIENCES] || null, // facebook, instagram, tiktok, pinterest, twitter, linkedin
                  [EXCLUDE_AUDIENCES]: data.configuration[EXCLUDE_AUDIENCES] || null, // facebook, instagram, tiktok, pinterest
                  [INTERESTS]: data.configuration[INTERESTS] || null, // facebook, twitter, instagram
                  [WORK_POSITIONS]: data.configuration[WORK_POSITIONS] || null, // facebook, instagram
                  [COUNTRIES]: data.configuration[COUNTRIES] || null, // facebook, instagram
                  [REGIONS]: data.configuration[REGIONS] || null, // facebook, instagram
                  [CITIES]: data.configuration[CITIES] || null, // facebook, instagram
                  [GENDERS]: data.configuration[GENDERS] || null, // facebook, instagram, linkedin
                  [AGE_MIN]: data.configuration[AGE_MIN] || '', // facebook, instagram
                  [AGE_MAX]: data.configuration[AGE_MAX] || '', // facebook, instagram
                  //   [OBJECTIVE]: data.configuration[OBJECTIVE] || null, // tiktok, pinterest, 03-12-2024 objective is hidden on the ui
                  [LOCATIONS]: data.configuration[LOCATIONS] || null, // tiktok, twitter, linkedin
                  [LANGUAGES]: data.configuration[LANGUAGES] || null, // tiktok, twitter
                  [AGES]: data.configuration[AGES] || null, // tiktok, linkedin
                  [INDUSTRIES]: data.configuration[INDUSTRIES] || null, // linkedin
                  [FUNCTIONS]: data.configuration[FUNCTIONS] || null, // linkedin
                  [GENDER]: data.configuration[GENDER] || null, // pinterest
                  [AGE_BUCKET]: data.configuration[AGE_BUCKET] || null, // pinterest
                  [INTEREST]: data.configuration[INTEREST] || null, // pinterest
                  [GEO]: data.configuration[GEO] || null, // pinterest
                  [LOCALE]: data.configuration[LOCALE] || null, // pinterest
                  [INTEREST_CATEGORIES]: data.configuration[INTEREST_CATEGORIES] || null, // tiktok
                }}
                onSubmit={(values) => {
                  return handleSubmitForm({ values })
                }}
                validationSchema={() => {
                  return Yup.lazy((values) => {
                    return createValidationSchema(values)
                  })
                }}
                autocomplete="off"
                enableReinitialize
                ref={formRef}
              >
                {({ values, handleChange, setFieldValue, errors, touched }) => {
                  let ConfigurationComponent = null

                  let formDataProfile = {}

                  if (values[PROFILE]) {
                    const { network: { code } = {}, value } = values[PROFILE]

                    ConfigurationComponent = getConfigurationByNetwork({ code })

                    if (formData[code] && formData[code][value]) {
                      formDataProfile = formData[code][value]
                    }
                  }

                  return (
                    <Box p="m">
                      {!isGettingInitialData && (
                        <Fragment>
                          <Box width="100%">
                            <Input
                              id={NAME}
                              label="Boost name"
                              placeholder="Describe your boost"
                              value={values[NAME]}
                              onChange={handleChange(NAME)}
                              error={errors[NAME] && touched[NAME] && errors[NAME]}
                              width="100%"
                            />
                          </Box>

                          <Flex flexDirection="column" mt="m">
                            <Text mb="s">Boost delay after publishing</Text>
                            <Flex alignItems="center">
                              <Flex width="30%">
                                <Input
                                  placeholder="Pick a number"
                                  value={values[DELAY].value}
                                  onChange={(e) => {
                                    const { target: { value } = {} } = e
                                    values[DELAY].value = value
                                    setFieldValue(DELAY, values[DELAY])
                                  }}
                                  width="100%"
                                  type="number"
                                />
                              </Flex>
                              <Flex width="70%" flexDirection="column">
                                <DropDown
                                  placeholder="Please select a delay option"
                                  value={
                                    values[DELAY].type
                                      ? DELAY_OPTIONS.find((option) => option.value === values[DELAY].type)
                                      : DELAY_OPTIONS[0]
                                  }
                                  height={DEFAULT_HEIGHT}
                                  onChange={(option) => {
                                    values[DELAY].type = option.value
                                    setFieldValue(DELAY, values[DELAY])
                                  }}
                                  options={DELAY_OPTIONS}
                                  openMenuOnFocus
                                />
                              </Flex>
                            </Flex>
                            {errors[DELAY] && touched[DELAY] && errors[DELAY] && (
                              <ErrorLabel error={errors[DELAY]} mt="xxs" />
                            )}
                          </Flex>

                          <Box mt="m">
                            <DropDown
                              id={PROFILE}
                              label="Profile"
                              placeholder="Please select a profile"
                              value={values[PROFILE]}
                              height={DEFAULT_HEIGHT}
                              onChange={(option) => {
                                setFieldValue(PROFILE, option)

                                if (option) {
                                  const { value, network: { code } = {} } = option
                                  setSpecialTriggerForGettingFormData({
                                    ...{ profile_gid: value, code, setFieldValue },
                                  })
                                }

                                if ((option && values[PROFILE] && values[PROFILE].value !== option.value) || !option) {
                                  setFieldValue(ACCOUNT, null)
                                  setFieldValue(IDENTITY, null) // this is for tiktok only
                                  setFieldValue(FUNDING_INSTRUMENT, null) // this is for twitter only
                                }
                              }}
                              options={profiles}
                              openMenuOnFocus
                              formatOptionLabel={formatProfileOptions}
                              filterOption={createFilter({
                                ignoreCase: true,
                                ignoreAccents: true,
                                matchFrom: 'any',
                                stringify: (option) =>
                                  `${option.label} ${option.value} ${option.data.name || ''} ${option.data.username ||
                                    ''} ${option.data.nickname || ''} ${
                                    option.data.network ? option.data.network.display || '' : ''
                                  }`,
                                trim: true,
                              })}
                              error={errors[PROFILE] && touched[PROFILE] && errors[PROFILE]}
                            />
                          </Box>
                        </Fragment>
                      )}

                      {values[PROFILE] && (
                        <Fragment>
                          <Box mt="m">
                            <DropDown
                              id={ACCOUNT}
                              label="Ad account"
                              placeholder="Please select an ad account"
                              value={values[ACCOUNT]}
                              height={DEFAULT_HEIGHT}
                              onChange={(option) => {
                                setFieldValue(ACCOUNT, option)

                                if (option) {
                                  if (!values[ACCOUNT] || values[ACCOUNT].value !== option.value) {
                                    if (values[PROFILE].network.code === TIKTOK) {
                                      setFieldValue(IDENTITY, null)
                                      setFieldValue(INCLUDE_AUDIENCES, null)
                                      setFieldValue(EXCLUDE_AUDIENCES, null)

                                      setSpecialTriggerForGettingTikTokIdentities({
                                        ...{
                                          tiktokads_profile_gid: option.profile_gid,
                                          code: values[PROFILE].network.code,
                                          profile_gid: values[PROFILE].value,
                                          setFieldValue,
                                        },
                                      })

                                      setSpecialTriggerForGettingTikTokData({
                                        ...{
                                          tiktokads_profile_gid: option.profile_gid,
                                          code: values[PROFILE].network.code,
                                          profile_gid: values[PROFILE].value,
                                        },
                                      })
                                    } else if (values[PROFILE].network.code === TWITTER) {
                                      setFieldValue(FUNDING_INSTRUMENT, null)

                                      setSpecialTriggerForGettingTwitterFundingInstruments({
                                        ...{
                                          account_id: option.value,
                                          code: values[PROFILE].network.code,
                                          profile_gid: values[PROFILE].value,
                                          setFieldValue,
                                        },
                                      })

                                      setFieldValue(INCLUDE_AUDIENCES, null)

                                      setSpecialTriggerForGettingTwitterAudiences({
                                        ...{
                                          account_id: option.value,
                                          code: values[PROFILE].network.code,
                                          profile_gid: values[PROFILE].value,
                                        },
                                      })
                                    } else if (
                                      values[PROFILE].network.code === FACEBOOK ||
                                      values[PROFILE].network.code === INSTAGRAM
                                    ) {
                                      setFieldValue(INCLUDE_AUDIENCES, null)
                                      setFieldValue(EXCLUDE_AUDIENCES, null)

                                      setSpecialTriggerForGettingMetaAudiences({
                                        ...{
                                          account_id: option.value,
                                          code: values[PROFILE].network.code,
                                          profile_gid: values[PROFILE].value,
                                        },
                                      })
                                    } else if (values[PROFILE].network.code === PINTEREST) {
                                      setFieldValue(INCLUDE_AUDIENCES, null)
                                      setFieldValue(EXCLUDE_AUDIENCES, null)

                                      setSpecialTriggerForGettingPinterestAudiences({
                                        ...{
                                          account_id: option.value,
                                          code: values[PROFILE].network.code,
                                          profile_gid: values[PROFILE].value,
                                        },
                                      })
                                    } else if (values[PROFILE].network.code === LINKEDIN) {
                                      setFieldValue(INCLUDE_AUDIENCES, null)

                                      setSpecialTriggerForGettingLinkedInAudiences({
                                        ...{
                                          account_id: option.value,
                                          code: values[PROFILE].network.code,
                                          profile_gid: values[PROFILE].value,
                                        },
                                      })
                                    }
                                  }
                                }
                              }}
                              options={formDataProfile.accounts || []}
                              openMenuOnFocus
                              error={errors[ACCOUNT] && touched[ACCOUNT] && errors[ACCOUNT]}
                            />
                          </Box>
                        </Fragment>
                      )}

                      {values[ACCOUNT] && (
                        <Fragment>
                          {(values[PROFILE].network.code === TWITTER || values[PROFILE].network.code === TIKTOK) && (
                            <ConfigurationComponent
                              values={values}
                              setFieldValue={setFieldValue}
                              errors={errors}
                              touched={touched}
                              formDataProfile={formDataProfile}
                              loadSuggestedOptions={loadSuggestedOptions}
                              showOtherSelectors={false} // twitter, tiktok configuration block
                            />
                          )}

                          <Flex mt="m" alignItems="center" width="100%">
                            <Grid
                              width="100%"
                              gridGap="m"
                              gridTemplateColumns={`repeat(${
                                values[PROFILE] && values[PROFILE].network && values[PROFILE].network.code === FACEBOOK
                                  ? 3
                                  : 2
                              }, 1fr)`}
                            >
                              <Flex flexDirection="column" width="100%">
                                <Input
                                  id={BUDGET_IN_DOLLARS}
                                  label="Budget"
                                  placeholder="Budget (expressed in dollars, euros, etc)"
                                  value={values[BUDGET_IN_DOLLARS]}
                                  onChange={(e) => {
                                    let { value } = e.target
                                    if (value) {
                                      value = Number(value)
                                    }
                                    if (value >= 0) {
                                      setFieldValue(BUDGET_IN_DOLLARS, value)
                                    }
                                  }}
                                  min={0}
                                  error={
                                    errors[BUDGET_IN_DOLLARS] && touched[BUDGET_IN_DOLLARS] && errors[BUDGET_IN_DOLLARS]
                                  }
                                  type="number"
                                  width="100%"
                                />
                              </Flex>
                              <Flex flexDirection="column" width="100%">
                                <Input
                                  id={DAYS}
                                  label="Campaign days"
                                  placeholder="Number of days"
                                  value={values[DAYS]}
                                  onChange={(e) => {
                                    let { value } = e.target
                                    if (value) {
                                      value = Number(value)
                                    }
                                    if (value >= 0 && value <= 365) {
                                      setFieldValue(DAYS, value)
                                    }
                                  }}
                                  min={0}
                                  max={365}
                                  error={errors[DAYS] && touched[DAYS] && errors[DAYS]}
                                  type="number"
                                  width="100%"
                                />
                              </Flex>

                              {values[PROFILE] && values[PROFILE].network && values[PROFILE].network.code === FACEBOOK && (
                                <Flex flexDirection="column" width="100%">
                                  <Input
                                    id={BID_AMOUNT}
                                    label="Bid amount (CPM)"
                                    placeholder="Bid amount (expressed in dollars, euros, etc)"
                                    value={values[BID_AMOUNT]}
                                    onChange={(e) => {
                                      let { value } = e.target
                                      if (value) {
                                        value = Number(value)
                                      }
                                      if (value >= 0) {
                                        setFieldValue(BID_AMOUNT, value)
                                      }
                                    }}
                                    min={0}
                                    error={errors[BID_AMOUNT] && touched[BID_AMOUNT] && errors[BID_AMOUNT]}
                                    type="number"
                                    width="100%"
                                  />
                                </Flex>
                              )}
                            </Grid>
                          </Flex>

                          <ConfigurationComponent
                            values={values}
                            setFieldValue={setFieldValue}
                            errors={errors}
                            touched={touched}
                            formDataProfile={formDataProfile}
                            loadSuggestedOptions={loadSuggestedOptions}
                            showFundingInstrumentSelector={false} // twitter configuration block
                            showIdentitySelector={false} // tiktok configuration block
                          />
                        </Fragment>
                      )}

                      <ErrorFocus />
                    </Box>
                  )
                }}
              </Formik>
            </Scrollbars>
          </Flex>

          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray isSmall onClick={handleClickCloseModal} mr="s" isDisabled={isBoostConfigurationSaving}>
              Close
            </Button.Gray>

            <ButtonWithLoading
              isSmall
              onClick={handleSubmitFormFromButton}
              isLoading={isBoostConfigurationSaving}
              type="submit"
            >
              {isBoostConfigurationSaving
                ? `${publicationId ? 'Saving and retrying boost' : 'Saving boost'}`
                : `${publicationId ? 'Save and retry boosting' : 'Save boost'}`}
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper
            className="modal-close-icon"
            onClick={handleClickCloseModal}
            isDisabled={isBoostConfigurationSaving}
          >
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

BoostConfigurationModal.defaultProps = {
  boostId: null,
  selectedProfile: null,
  publicationId: '',
}

BoostConfigurationModal.propTypes = {
  confirm: PropTypes.func.isRequired,
  // user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  boostId: PropTypes.string,
  selectedProfile: PropTypes.object,
  publicationId: PropTypes.string,
}

BoostConfigurationModal.displayName = 'BoostConfigurationModal'

export default withConfirm(BoostConfigurationModal)
