import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Formik } from 'formik'
import * as Yup from 'yup'
import Alert from 'react-s-alert'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import moment from 'moment-timezone'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { COMMENT_TYPE_NOTE, ERROR_MESSAGE, USER_ROLE_MANAGE, USER_ROLE_CONTRIBUTOR } from 'consts'
import withConfirm from 'utils/withConfirm'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import TextArea from 'components/atoms/TextArea'
import Image from 'components/atoms/Image'
import Input from 'components/atoms/Input'
import Datepicker from 'components/molecules/Datepicker'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import Tooltip from 'components/molecules/Tooltip'
import DropDown from 'shared/DropDown'
import SliderCommentsPreview from '../SliderCommentsPreview'
import { GRADIENT_PALETTE } from '../../consts'
import { getSelectedProfileGroupsAndTimezone } from '../../helpers'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    max-width: 510px;
    width: 100%;
    top: 50%;
    transform: translate(0, -50%);
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    max-height: 700px;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledCommentIconWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.pill};
  box-shadow: 0px 0px 6px rgb(39 40 49 / 10%);
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const StyledCommentCounterWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledColorOptionsWrapper = styled(Flex)`
  margin-top: ${space.s};
  flex-wrap: wrap;
  gap: ${space.s};
`

const StyledGradientPaletteItemWrapper = styled(Flex)`
  margin-top: ${space.s};
  width: 24px;
  height: 24px;
  border: 1px solid transparent;
  background-image: ${({ backgroundImage }) => backgroundImage};
  cursor: pointer;
  border-radius: ${radius.pill};
  ${({ selected }) =>
    selected && `border: 1px solid ${colors.primaryText}; box-shadow: 0px 0px 2px 2px rgb(39 40 49 / 10%);`}
  &:hover {
    box-shadow: 0px 0px 2px 2px rgb(39 40 49 / 5%);
  }
`

const StyledTextArea = styled(TextArea)`
  margin-top: 0;
`

const VISIBILITY_OPTIONS = [
  { value: false, label: 'Public' },
  { value: true, label: 'Private' },
]

const { DATE, TITLE, DESCRIPTION, COLOR, VISIBILITY, ENTITIES } = {
  DATE: 'date',
  TITLE: 'title',
  DESCRIPTION: 'description',
  COLOR: 'color',
  VISIBILITY: 'isPrivate',
  ENTITIES: 'entities',
}

const MAX_NOTE_TO_REVIEWER_LENGTH = 500

const COLOR_OPTIONS = GRADIENT_PALETTE.map(({ color }) => ({ value: color, label: color }))

const DEFAULT_SLIDER_OPTIONS = {
  isOpen: false,
  data: null,
}

const NoteModal = ({ user, data, isOpen, handleDismiss, handleClickUpdateCalendarNotes, confirm }) => {
  const formRef = useRef(null)

  const [note, setNote] = useState(null)
  const [isSavingNote, setIsSavingNote] = useState(false)
  const [isRemovingNote, setIsRemovingNote] = useState(false)
  const [sliderOptions, setSliderOptions] = useState({ ...DEFAULT_SLIDER_OPTIONS })
  const [availableEntities, setAvailableEntities] = useState([])

  const { id, date, title, description, color, comments, entities = [], isCreator = true, isPrivate = false } =
    note || {}

  const { id: user_gid } = user

  useEffect(() => {
    if (isOpen) {
      const { entities = [] } = user

      const available_entities_temp = entities
        .filter(
          ({ entity_user }) =>
            !entity_user || entity_user.publish === USER_ROLE_MANAGE || entity_user.publish === USER_ROLE_CONTRIBUTOR
        )
        .map(({ id, name }) => ({ value: id, label: name }))

      available_entities_temp.sort((a, b) => (a.label.toLowerCase() < b.label.toLowerCase() ? -1 : 1))

      setAvailableEntities([...available_entities_temp])

      const { selectedEntitiesWithSelectedProfiles } = getSelectedProfileGroupsAndTimezone({ user })

      let selected_entities = []

      if (selectedEntitiesWithSelectedProfiles.length !== 0) {
        selectedEntitiesWithSelectedProfiles.forEach(({ id }) => {
          const foundEntity = available_entities_temp.find(({ value }) => value === id)
          if (foundEntity) {
            selected_entities.push(foundEntity)
          }
        })
      } else {
        selected_entities = available_entities_temp
      }

      if (data) {
        if (data.id) {
          data.isCreator = data.user_gid === user_gid
        } else {
          data.entities = selected_entities
        }

        setNote({ ...data })
      } else {
        setNote({ ...{ entities: selected_entities } })
      }
    }
  }, [isOpen])

  const createValidationSchema = () => {
    const isDropdownValueRequiredShape = {
      value: Yup.string().required(),
      label: Yup.string().required(),
    }
    return Yup.object().shape({
      [TITLE]: Yup.string().required('Title is required.'),
      [DATE]: Yup.date().required('Date is required.'),
      [DESCRIPTION]: Yup.string().max(
        MAX_NOTE_TO_REVIEWER_LENGTH,
        `Note is too long - should be ${MAX_NOTE_TO_REVIEWER_LENGTH} chars maximum.`
      ),
      [COLOR]: Yup.object()
        .shape(isDropdownValueRequiredShape)
        .typeError('Color is required.'),
      [VISIBILITY]: Yup.bool(),
      [ENTITIES]: Yup.array().of(Yup.object().shape(isDropdownValueRequiredShape)),
    })
  }

  const handleSubmitForm = async (values) => {
    const { permissions } = user

    const { publish } = permissions || {}
    const { canManage = false } = publish || {}

    if (canManage) {
      try {
        setIsSavingNote(true)

        const body = {
          [DATE]: moment(moment(values[DATE]).format('YYYY-MM-DD'), 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm'),
          [COLOR]: values[COLOR].value,
          [TITLE]: values[TITLE],
          [DESCRIPTION]: values[DESCRIPTION],
          [VISIBILITY]: values[VISIBILITY],
          [ENTITIES]: values[ENTITIES].map(({ value }) => value),
        }

        if (body[ENTITIES].length === 0) {
          body[ENTITIES] = availableEntities.map(({ value }) => value)
        }

        let response

        if (id) {
          response = await request({
            path: `publishing/note/${id}`,
            method: 'PATCH',
            body,
          })
        } else {
          response = await request({
            path: `publishing/note`,
            method: 'POST',
            body,
          })
        }

        setIsSavingNote(false)

        const { error, note } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          Alert.success(`Note has been saved.`, { timeout: 5000 })

          note.comments = comments || 0

          handleClickUpdateCalendarNotes({ id: response._id, note, type: id ? 'update' : 'new' })

          handleDismiss()
        }
      } catch (error) {
        setIsSavingNote(false)
        errorHelper({ error, componentName: NoteModal.displayName, functionName: 'handleSubmitForm' })
      }
    } else {
      Alert.error(`You don't have access to publishing. Please contact your account administrator.`)
    }
  }

  const handleSubmitFormFromButton = () => {
    formRef.current.handleSubmit()
  }

  const handleClickRemoveNote = async () => {
    const { permissions } = user

    const { publish } = permissions || {}
    const { canManage = false } = publish || {}

    if (canManage) {
      try {
        setIsRemovingNote(true)

        const response = await request({
          path: `publishing/note/${id}`,
          method: 'DELETE',
        })

        setIsRemovingNote(false)

        const { error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          Alert.success(`Note has been removed.`, { timeout: 5000 })

          handleClickUpdateCalendarNotes({ id: response._id, type: 'remove' })
          handleDismiss()
        }
      } catch (error) {
        setIsRemovingNote(false)
        errorHelper({ error, componentName: NoteModal.displayName, functionName: 'handleClickRemoveNote' })
      }
    } else {
      Alert.error(`You don't have access to publishing. Please contact your account administrator.`)
    }
  }

  const handleClickOpenComments = () => {
    const { selectedTimezone } = getSelectedProfileGroupsAndTimezone({ user })

    let entity_gids_temp = entities.map(({ value }) => value)

    if (entity_gids_temp.length === 0) {
      entity_gids_temp = user.entities ? user.entities.map(({ id }) => id) : []
    }

    setSliderOptions({
      isOpen: true,
      data: {
        comment_type_id: id,
        type: COMMENT_TYPE_NOTE,
        entity_gids: entity_gids_temp,
        timezone: selectedTimezone,
      },
    })
  }

  const handleClickCloseComments = ({ commentsCounter }) => {
    note.comments = commentsCounter
    setNote({ ...note })
    setSliderOptions({ ...DEFAULT_SLIDER_OPTIONS })
  }

  const handleClickCloseModal = () => {
    if (!isSavingNote && !isRemovingNote) {
      if (id) {
        handleClickUpdateCalendarNotes({ id: note.id, note, type: 'update' })
      }
      handleDismiss()
    } else {
      Alert.error(`Please wait a bit longer.`, { timeout: 5000 })
    }
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex="0">
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">{id ? 'Edit note' : 'Create a new note'}</H4>
            {id && (
              <Flex>
                <Tooltip
                  wrapperComp={
                    <StyledCommentIconWrapper ml="m" position="relative" onClick={handleClickOpenComments}>
                      <Image src="/assets/vistasocial/dashboard/comment_filled.svg" width="24px" height="24px" />
                      <StyledCommentCounterWrapper>
                        <Text fontSize="xxs">{comments || 0}</Text>
                      </StyledCommentCounterWrapper>
                    </StyledCommentIconWrapper>
                  }
                  message="Note comments"
                  isTriangleVisible={false}
                  width="150px"
                  defaultTopOffset={-70}
                  left="unset"
                  right={0}
                />
              </Flex>
            )}
          </StyledDialogEnvironmentWrapper>
          <Flex flexDirection="column" flex="1" position="relative">
            {note && (
              <Scrollbars universal autoHide>
                <Formik
                  initialValues={{
                    [DATE]: date || moment().toDate(),
                    [TITLE]: title || '',
                    [DESCRIPTION]: description || '',
                    [COLOR]: color ? { value: color, label: color } : COLOR_OPTIONS[3],
                    [VISIBILITY]: isPrivate || false,
                    [ENTITIES]: entities || [],
                  }}
                  onSubmit={handleSubmitForm}
                  validationSchema={createValidationSchema}
                  autocomplete="off"
                  ref={formRef}
                >
                  {({ values, handleChange, setFieldValue, errors, touched }) => {
                    return (
                      <Box px="m" mt="m">
                        <Box>
                          <Flex>
                            <Text mb="xs" fontSize="s">
                              Date
                            </Text>
                          </Flex>
                          <Flex alignItems="center">
                            <Flex>
                              <Datepicker
                                width="150px"
                                label=""
                                id={DATE}
                                value={values[DATE]}
                                onChange={(day) => {
                                  if (day) {
                                    setFieldValue(DATE, day)
                                  }
                                }}
                                error={errors[DATE] && touched[DATE] && errors[DATE]}
                                isClearDateEnabled={false}
                              />
                            </Flex>
                          </Flex>
                        </Box>

                        <StyledColorOptionsWrapper>
                          {COLOR_OPTIONS.map(({ value, label }) => (
                            <StyledGradientPaletteItemWrapper
                              key={value}
                              selected={value === values[COLOR].value}
                              onClick={() => {
                                setFieldValue(COLOR, { value, label })
                              }}
                              backgroundImage={value}
                            />
                          ))}
                        </StyledColorOptionsWrapper>

                        <Box mt="m">
                          <Input
                            label="Title"
                            placeholder=""
                            value={values[TITLE]}
                            name={[TITLE]}
                            id={[TITLE]}
                            onChange={handleChange(TITLE)}
                            error={errors[TITLE] && touched[TITLE] && errors[TITLE]}
                            width="100%"
                          />
                        </Box>

                        <Box mt="m">
                          <Text mb="xs" fontSize="s">
                            Note
                          </Text>
                          <StyledTextArea
                            placeholder=""
                            label="Note"
                            value={values[DESCRIPTION]}
                            onChange={handleChange(DESCRIPTION)}
                            error={errors[DESCRIPTION] && touched[DESCRIPTION] && errors[DESCRIPTION]}
                            width="100%"
                            rows="4"
                          />
                        </Box>
                        {isCreator && (
                          <Box mt="m">
                            <Text mb="xs" fontSize="s">
                              Visibility
                            </Text>
                            <DropDown
                              placeholder="Visibility"
                              label=""
                              value={VISIBILITY_OPTIONS.find(({ value }) => value === values[VISIBILITY])}
                              onChange={(option) => {
                                setFieldValue(VISIBILITY, option.value)
                              }}
                              options={VISIBILITY_OPTIONS}
                              openMenuOnFocus={false}
                              isSearchable={false}
                              menuPlacement="top"
                            />
                          </Box>
                        )}

                        <Box mt="m">
                          <Text mb="xs" fontSize="s">
                            Profile groups
                          </Text>
                          <DropDown
                            placeholder="Profile groups"
                            label=""
                            value={values[ENTITIES]}
                            onChange={(options) => {
                              if (options) {
                                setFieldValue(ENTITIES, options)
                              } else {
                                setFieldValue(ENTITIES, [])
                              }
                            }}
                            options={availableEntities}
                            openMenuOnFocus={false}
                            isSearchable
                            isMulti
                            menuPlacement="top"
                          />
                        </Box>
                      </Box>
                    )
                  }}
                </Formik>
              </Scrollbars>
            )}
          </Flex>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Flex>
              <Button.Gray isSmall onClick={handleClickCloseModal} mr="s">
                Cancel
              </Button.Gray>

              {id && (
                <ButtonWithLoading
                  buttonComp={Button.Gray}
                  isSmall
                  onClick={() => {
                    confirm({
                      fn: () => () => handleClickRemoveNote(),
                      message: `Are you sure you want to remove this note?`,
                      action: 'Remove',
                    })
                  }}
                  mr="s"
                  isLoading={isRemovingNote}
                >
                  Remove
                </ButtonWithLoading>
              )}
            </Flex>

            <ButtonWithLoading isSmall onClick={handleSubmitFormFromButton} type="submit" isLoading={isSavingNote}>
              {isSavingNote ? 'Saving' : 'Save'}
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>

        {sliderOptions.isOpen && (
          <SliderCommentsPreview
            isOpen={sliderOptions.isOpen}
            data={sliderOptions.data}
            handleDismiss={handleClickCloseComments}
            user={user}
          />
        )}
      </Box>
    </StyledDialogOverlay>
  )
}

NoteModal.defaultProps = {
  data: null,
  handleClickUpdateCalendarNotes: () => {},
}

NoteModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickUpdateCalendarNotes: PropTypes.func,
  data: PropTypes.object,
  confirm: PropTypes.func.isRequired,
}

NoteModal.displayName = 'NoteModal'

export default withConfirm(NoteModal)
