/* eslint-disable no-nested-ternary */
import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace, width } from 'styled-system'
import { DialogOverlay } from '@reach/dialog'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, fontSizes, fontWeights, radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import { pxToRem } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Icon from 'components/atoms/Icon'
import NoData from 'components/molecules/NoData'

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    height: 550px;
    min-height: 420px;
    flex-direction: column;
    outline: none;
    background-color: ${COLOR_CONSTANTS.WHITE};
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const StyledProductImage = styled(ImageWithFallback)`
  min-width: 80px;
  width: 80px;
  height: 80px;
  border-radius: ${radius.l};
`

const StyledProductWrapper = styled(Flex)`
  cursor: pointer;
  border-radius: ${radius.l};
  border: 1px solid ${COLOR_CONSTANTS.DISTANT_HORIZON};
  &:hover {
    background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  }
  ${({ isSelected }) =>
    isSelected && `background-color: ${COLOR_CONSTANTS.DAISY}; border: 1px solid ${colors.primary}`};
`

const StyledArrowWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  cursor: pointer;
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
    svg {
      fill: ${colors.primary};
    }
  }
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 55px;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${COLOR_CONSTANTS.WHITE};
`

const StyledInput = styled.input`
  padding-top: ${space.m};
  padding-bottom: ${space.m};
  padding-right: ${space.m};
  border: 1px solid ${({ isError }) => (isError ? colors.error : COLOR_CONSTANTS.SOLITUDE)};
  border-radius: ${radius.l};
  height: ${pxToRem(30)};
  color: ${colors.primaryText};
  font-size: ${fontSizes.xs};
  :focus {
    outline-style: none;
  }
  &::-webkit-input-placeholder,
  &:-moz-placeholder,
  &::placeholder {
    color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
    font-size: ${fontSizes.xs};
    font-weight: ${fontWeights.normal};
  }
  ${styledSpace};
  ${width};
`

const StyledIconSearch = styled(Flex)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

const StyledIconSearching = styled(Flex)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  right: ${space.xs};
  cursor: pointer;
`

const MAX_PRODUCTS = 25

const ProductTaggingModal = ({ isOpen, handleDismiss, product, handleClickSaveProductTag, profileId, profileName }) => {
  const scrollbarsRef = useRef(null)
  const searchInputRef = useRef(null)

  const [productTag, setProductTag] = useState({})
  const [isSearchingData, setIsSearchingData] = useState(true)
  const [availableProducts, setAvailableProducts] = useState([])
  const [issue, setIssue] = useState(false)
  const [scrollToProductId, setScrollToProductId] = useState(null)

  const getProducts = async ({ refresh = false } = {}) => {
    if (!issue) {
      let value = ''

      if (searchInputRef && searchInputRef.current && searchInputRef.current.value) {
        // eslint-disable-next-line prefer-destructuring
        value = searchInputRef.current.value
      }

      try {
        setIsSearchingData(true)
        const response = await request({
          method: 'GET',
          path: `instagram/products?profile_gid=${profileId}&q=${encodeURIComponent(value.trim())}&refresh=${refresh}`,
          show_error: false,
        })

        const { error, issue } = response

        if (error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else if (issue) {
          setIssue(issue)
        } else {
          if (response.length && product.product_id) {
            let foundProductId = null

            for (let j = 0; j < response.length; j++) {
              const { products = [], catalog_id } = response[j]

              for (let i = 0; i < products.length; i++) {
                const { product_id, product_variants = [] } = products[i]

                if (product_variants.length !== 0) {
                  const foundProductVariant = product_variants.find(
                    ({ product_id }) => product_id === product.product_id
                  )
                  if (foundProductVariant) {
                    response[j].products[i].isOpen = true
                    foundProductId = `${catalog_id}_${product_id}_${foundProductVariant.product_id}`
                  }
                } else if (product_id === product.product_id) {
                  foundProductId = `${catalog_id}_${product_id}`
                }

                if (foundProductId) {
                  break
                }
              }

              if (foundProductId) {
                break
              }
            }

            if (foundProductId) {
              setScrollToProductId(foundProductId)
            }
          }

          setAvailableProducts(response)
        }
      } catch (error) {
        errorHelper({ error, componentName: ProductTaggingModal.displayName, functionName: 'getProducts' })
      } finally {
        setIsSearchingData(false)
      }
    } else {
      Alert.error(`Application does not have permission for this action.`, { timeout: 5000 })
    }
  }

  useEffect(() => {
    if (scrollToProductId && availableProducts.length !== 0) {
      const el = document.getElementById(scrollToProductId)

      if (el) {
        const { top } = el.getBoundingClientRect()
        scrollbarsRef.current.scrollTop(top)
      }
      setScrollToProductId(null)
    }
  }, [availableProducts])

  useEffect(() => {
    if (isOpen) {
      getProducts()
      setProductTag({ ...product })
    }
  }, [isOpen])

  const handleClickSelectProduct = ({ product_id, product_name }) => {
    handleClickSaveProductTag({
      x: product.x,
      y: product.y,
      media_gid: product.media_gid,
      product_id,
      product_name,
      isForEdit: product.isForEdit,
    })
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex={0}>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Tag a product</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper flex="1">
            <Scrollbars universal ref={scrollbarsRef}>
              <Flex flexDirection="column" height="100%" width="100%" px="m">
                <Flex mt="m" flexDirection="column" position="relative" width="100%">
                  <StyledIconSearch p="s" justifyContent="center" alignItems="center" left="0">
                    <Image src="/assets/magnifier.svg" width="16px" height="16px" />
                  </StyledIconSearch>
                  <StyledInput
                    ref={searchInputRef}
                    pl="l"
                    placeholder="Search for products"
                    onKeyUp={(e) => {
                      if (e.keyCode === 13 && !isSearchingData) {
                        getProducts()
                      }
                    }}
                    isSmall
                    width="100%"
                  />
                  <StyledIconSearching
                    justifyContent="center"
                    alignItems="center"
                    onClick={() => {
                      if (!isSearchingData) {
                        getProducts()
                      }
                    }}
                  >
                    {isSearchingData ? (
                      <Flex alignItems="center" justifyContent="center" width="100%">
                        <ClipLoader size="24" color={colors.primary} />
                      </Flex>
                    ) : (
                      <Image src="/assets/vistasocial/double_arrow_right_blue.svg" width="24px" height="24px" />
                    )}
                  </StyledIconSearching>
                </Flex>

                <StyledProgressWrapper
                  display={isSearchingData ? 'flex' : 'none'}
                  alignItems="center"
                  justifyContent="center"
                >
                  <ClipLoader size="100" color={colors.primary} />
                </StyledProgressWrapper>
                {issue ? (
                  <Flex height="100%" alignItems="center" justifyContent="center" py="l">
                    <NoData
                      showImage={false}
                      showHeaderText
                      message='Access to products is not authorized. <br>Please reconnect this Instagram profile. <br><a target="_blank" href="https://support.vistasocial.com/hc/en-us/articles/9265214845467-Tagging-Instagram-Shop-Products">Learn more about this issue<a>'
                      image="/assets/noResultsIllustration.svg"
                    />
                  </Flex>
                ) : (
                  <Fragment>
                    {availableProducts.length === 0 ? (
                      <Flex height="100%" alignItems="center" justifyContent="center" py="l">
                        <NoData
                          showHeaderText={false}
                          message={`No products found for ${profileName}.`}
                          reload={() => getProducts({ refresh: true })}
                          image="/assets/noResultsIllustration.svg"
                        />
                      </Flex>
                    ) : (
                      <Fragment>
                        {availableProducts.map(
                          ({ catalog_id, catalog_name, products = [] }, availableProductsIndex) => (
                            <Flex flexDirection="column" key={catalog_id} mb="m">
                              {products.length > 0 ? (
                                <Flex flexDirection="column">
                                  <Flex flexDirection="column">
                                    {products.map(
                                      (
                                        {
                                          product_id: product_id_main,
                                          image_url,
                                          product_name: product_name_main,
                                          product_variants = [],
                                          isOpen = false,
                                        },
                                        productsIndex
                                      ) => {
                                        const productsCounter = product_variants.length
                                        const isSelected =
                                          productsCounter > 0
                                            ? false
                                            : product_id_main === Number(productTag.product_id)
                                        return (
                                          <Flex key={product_id_main} flexDirection="column" my="m" width="100%">
                                            <StyledProductWrapper
                                              id={`${catalog_id}_${product_id_main}`}
                                              onClick={() => {
                                                if (productsCounter > 0) {
                                                  availableProducts[availableProductsIndex].products[
                                                    productsIndex
                                                  ].isOpen = !isOpen
                                                  setAvailableProducts([...availableProducts])
                                                } else {
                                                  handleClickSelectProduct({
                                                    product_id: product_id_main,
                                                    product_name: product_name_main,
                                                  })
                                                }
                                              }}
                                              isSelected={isSelected}
                                              p="s"
                                            >
                                              <Flex width="calc(100% - 32px)">
                                                <StyledProductImage
                                                  source={image_url}
                                                  fallbackSource="/assets/avatar.svg"
                                                />
                                                <Flex flexDirection="column" ml="m">
                                                  <Text fontSize="m">{product_name_main}</Text>
                                                  {productsCounter !== 0 ? (
                                                    <Text color="secondaryText">
                                                      {productsCounter} {productsCounter === 1 ? 'variant' : 'variants'}
                                                    </Text>
                                                  ) : (
                                                    <Text color="secondaryText">ID: {product_id_main}</Text>
                                                  )}
                                                </Flex>
                                              </Flex>
                                              {productsCounter !== 0 && (
                                                <StyledArrowWrapper
                                                  width="24px"
                                                  height="24px"
                                                  alignItems="center"
                                                  justifyContent="center"
                                                >
                                                  {isOpen ? (
                                                    <Icon.VistaSocialChevronUp />
                                                  ) : (
                                                    <Icon.VistaSocialChevronDown />
                                                  )}
                                                </StyledArrowWrapper>
                                              )}
                                            </StyledProductWrapper>
                                            {productsCounter !== 0 && isOpen && (
                                              <Flex flexDirection="column" pl="l">
                                                {product_variants.map(({ product_id, variant_name }) => (
                                                  <StyledProductWrapper
                                                    id={`${catalog_id}_${product_id_main}_${product_id}`}
                                                    key={product_id}
                                                    mt="m"
                                                    p="s"
                                                    onClick={() => {
                                                      handleClickSelectProduct({
                                                        product_id,
                                                        product_name: `${product_name_main}`,
                                                      })
                                                    }}
                                                    isSelected={product_id === Number(productTag.product_id)}
                                                  >
                                                    <Flex flexDirection="column">
                                                      <Text fontSize="m">{variant_name}</Text>
                                                      <Text color="secondaryText">ID: {product_id}</Text>
                                                    </Flex>
                                                  </StyledProductWrapper>
                                                ))}
                                              </Flex>
                                            )}
                                          </Flex>
                                        )
                                      }
                                    )}
                                  </Flex>
                                  {products.length === MAX_PRODUCTS && (
                                    <Text mb="m" textAlign="center">
                                      Showing first {MAX_PRODUCTS} products. Please refine your search
                                    </Text>
                                  )}

                                  <StyledLink
                                    textAlign="center"
                                    color="primary"
                                    mb="m"
                                    onClick={() => getProducts({ refresh: true })}
                                  >
                                    Refresh products
                                  </StyledLink>
                                </Flex>
                              ) : (
                                <Flex flexDirection="column">
                                  <Text my="m" textAlign="center">
                                    No products found matching this criteria
                                  </Text>
                                  <StyledLink
                                    textAlign="center"
                                    color="primary"
                                    mb="m"
                                    onClick={() => getProducts({ refresh: true })}
                                  >
                                    Search again
                                  </StyledLink>
                                </Flex>
                              )}
                            </Flex>
                          )
                        )}
                      </Fragment>
                    )}
                  </Fragment>
                )}
              </Flex>
            </Scrollbars>
          </StyledDialogBodyWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleDismiss}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

ProductTaggingModal.defaultProps = {}

ProductTaggingModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickSaveProductTag: PropTypes.func.isRequired,
  product: PropTypes.object.isRequired,
  profileId: PropTypes.number.isRequired,
  profileName: PropTypes.string.isRequired,
}

ProductTaggingModal.displayName = 'ProductTaggingModal'

export default ProductTaggingModal
