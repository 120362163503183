import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SwipeableDrawer } from '@material-ui/core'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment-timezone'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { ERROR_MESSAGE } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import NoData from 'components/molecules/NoData'
import { ROUTE_CALENDAR } from 'routes/Calendar/consts'

const StyledCommentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  padding: ${space.s};
  border-radius: ${radius.l};
  cursor: pointer;
  margin-bottom: ${space.m};
`

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const SliderPostNetworkCommentsPreview = ({ isOpen, handleDismiss, post_gid, post_type }) => {
  const [comments, setComments] = useState([])
  const [isCollectingComments, setIsCollectingComments] = useState(true)
  // const [page, setPage] = useState(0)
  // const [hasMoreComments, setHasMoreComments] = useState(true)

  const collectComments = async () => {
    try {
      // maybe timezone would be needed
      const response = await request({
        path: `${ROUTE_CALENDAR}/network-comments/${post_gid}?type=${post_type || ''}`,
      })

      const { error, data } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        // if (last_page) {
        //   setHasMoreComments(false)
        // }

        setComments([...data])
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: SliderPostNetworkCommentsPreview.displayName,
        functionName: 'collectComments',
      })
    } finally {
      setIsCollectingComments(false)
    }
  }

  useEffect(() => {
    collectComments()
  }, [])

  const handleClickCloseDrawer = () => {
    handleDismiss()
  }

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      style={{ zIndex: 2147483001 }}
      anchor="right"
      open={!!isOpen}
      onClose={() => {
        handleClickCloseDrawer()
      }}
      disableAutoFocus
      disableEnforceFocus
      onOpen={() => {}}
    >
      {isOpen && (
        <Flex minWidth="560px" maxWidth="560px" height="100%" flexDirection="column">
          {isCollectingComments ? (
            <Flex alignItems="center" justifyContent="center" height="100%" width="100%">
              <ClipLoader size="50" color={colors.primary} />
            </Flex>
          ) : (
            <Flex flexDirection="column" height="100%">
              {comments.length > 0 ? (
                <Scrollbars universal>
                  <Flex flexDirection="column" height="100%" p="m">
                    <Text mb="m">Latest comments on network</Text>

                    {comments.map((comment) => {
                      const { id, user, message, received, url } = comment

                      const { picture_url, name } = user || {}

                      return (
                        <StyledCommentWrapper
                          key={id}
                          flexDirection="column"
                          onClick={() => {
                            if (url) {
                              window.open(url, '_blank')
                            }
                          }}
                        >
                          <Flex alignItems="center">
                            <ImageWithFallback
                              source={picture_url}
                              width="32px"
                              height="32px"
                              borderRadius={radius.pill}
                            />
                            <Flex flexDirection="column" ml="s" justifyContent="space-between">
                              <Text>{name}</Text>
                              <Text color="secondaryText" fontSize="xs">
                                {moment(received).format('MMM D, YYYY HH:mm')}
                              </Text>
                            </Flex>
                          </Flex>
                          <Text mt="s">{message}</Text>
                        </StyledCommentWrapper>
                      )
                    })}
                  </Flex>
                </Scrollbars>
              ) : (
                <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
                  <NoData message="No comments found" showHeaderText={false} />
                </Flex>
              )}

              <Flex width="0px" height="0px" onClick={handleClickCloseDrawer} className="modal-close-icon" />
            </Flex>
          )}
        </Flex>
      )}
    </SwipeableDrawer>
  )
}

SliderPostNetworkCommentsPreview.defaultProps = {
  post_type: '',
}

SliderPostNetworkCommentsPreview.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  post_gid: PropTypes.string.isRequired,
  post_type: PropTypes.string,
}

SliderPostNetworkCommentsPreview.displayName = 'SliderPostNetworkCommentsPreview'

export default SliderPostNetworkCommentsPreview
