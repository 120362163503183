import styled from 'styled-components'
import { transparentize } from 'polished'
import { space, radius, fontSizes, fontWeights, colors } from 'theme'
import { Flex } from 'components/atoms/Layout'

const Badge = styled(Flex)`
  align-items: center;
  padding: ${space.xxs} ${space.s};
  font-size: ${fontSizes.xxs};
  font-weight: ${fontWeights.bold};
  border-radius: ${radius.l};
  ${({ color = colors.primary }) => `
    color: ${color};
    background-color: ${transparentize(0.8, color)};
  `}
`

const Status = styled(Badge)`
  display: inline-flex;
  justify-content: center;
  min-width: 60px;
  font-size: ${fontSizes.xs};
  font-weight: ${fontWeights.normal};
  border-radius: ${radius.pill};
  text-align: center;
  white-space: nowrap;
`

Badge.Status = Status

export default Badge
