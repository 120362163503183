import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { radius, colors } from 'theme'
import { DEFAULT_SOCIAL_PROFILE_SOURCE } from 'consts'
import { getSocialNetworkIconByName } from 'utils/socialNetworks'
import { Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const ProfileImage = styled(ImageWithFallback)`
  border-radius: ${({ imageBorderRadius }) => imageBorderRadius};
  vertical-align: inherit;
`

const NetworkImageWrapper = styled(Flex)`
  position: absolute;
  bottom: -4px;
  right: 0px;
  background-color: ${colors.white};
  border-radius: ${radius.pill};
  cursor: pointer;
`

const SocialProfileImage = ({ picture_url, code, width, height, imageBorderRadius, id }) => {
  const networkImage = getSocialNetworkIconByName(code).enabled
  return (
    <StyledFlex width={width} height={height} justifyContent="center" alignItems="center" position="relative">
      <ProfileImage
        width={width}
        height={height}
        source={picture_url || DEFAULT_SOCIAL_PROFILE_SOURCE}
        fallbackSource={DEFAULT_SOCIAL_PROFILE_SOURCE}
        imageBorderRadius={imageBorderRadius}
        id={id}
      />
      <NetworkImageWrapper justifyContent="center" alignItems="center">
        <Image src={networkImage} width="12px" height="12px" />
      </NetworkImageWrapper>
    </StyledFlex>
  )
}

SocialProfileImage.defaultProps = {
  picture_url: '',
  width: '24px',
  height: '24px',
  imageBorderRadius: radius.m,
  code: '',
}

SocialProfileImage.propTypes = {
  picture_url: PropTypes.string,
  code: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  imageBorderRadius: PropTypes.string,
}

export default SocialProfileImage
