import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { SwipeableDrawer } from '@material-ui/core'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { DOCUMENT, ERROR_MESSAGE, IMAGE, IMAGE_DYNAMIC, VIDEO, VIDEO_DYNAMIC } from 'consts'
import { getFileIconByExtension, sleep } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import Icon from 'components/atoms/Icon'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'
import NoData from 'components/molecules/NoData'
import { POST_TYPE_SOCIAL_POST, ROUTE_MEDIA } from 'routes/Calendar/consts'

const StyledPostMediaWrapper = styled(Flex)`
  border-bottom: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const StyledDownloadText = styled(Text)`
  cursor: pointer;
`

const DownloadedWrapper = styled(Flex)`
  border-radius: ${radius.pill};
  width: 24px;
  height: 24px;
  cursor: pointer;
  background-color: ${COLOR_CONSTANTS.WHITE};
  position: absolute;
  top: ${space.m};
  right: 0;

  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const iOS = process.browser && /iPad|iPhone|iPod/.test(navigator.userAgent)

const SliderPostMediasPreview = ({ isOpen, handleDismiss, medias, post_type, profile_display, post_gid }) => {
  const [collectedMedias, setCollectedMedias] = useState([])
  const [isCollectingMedias, setIsCollectingMedias] = useState(true)
  const [isDownloadingMedias, setIsDownloadingMedias] = useState(false)

  const collectMedias = async () => {
    try {
      const response = await request({
        method: 'POST',
        path: `${ROUTE_MEDIA}/transform-medias`,
        body: { media_gids: medias.map(({ id }) => id) },
      })

      const { error, data } = response || {}
      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        setCollectedMedias([...data])
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: SliderPostMediasPreview.displayName,
        functionName: 'collectMedias',
      })
    } finally {
      setIsCollectingMedias(false)
    }
  }

  useEffect(() => {
    collectMedias()
  }, [])

  const handleDownloadOneMedia = async ({ url, type, media_gid }) => {
    if (post_type === POST_TYPE_SOCIAL_POST) {
      let parsedUrl
      let ext

      if (url) {
        try {
          parsedUrl = new URL(url)
        } catch (error) {
          errorHelper({
            error,
            componentName: SliderPostMediasPreview.displayName,
            functionName: 'handleClickDownloadMedia/url',
            showAlert: false,
            sendError: false,
          })
        }
      }

      if (parsedUrl) {
        const { pathname } = parsedUrl
        ext = pathname.substring(pathname.lastIndexOf('.'))
      }

      if (!ext) {
        if (type === VIDEO) {
          ext = '.mp4'
        } else if (type === IMAGE) {
          ext = '.jpg'
        } else if (type === DOCUMENT) {
          ext = '.txt'
        }
      }

      if (url) {
        document.location = `/api/${ROUTE_MEDIA}/download-url?url=${encodeURIComponent(
          url
        )}&name=${profile_display}_attachment${ext}`
      }
    } else {
      document.location = `/api/${ROUTE_MEDIA}/download/${media_gid}?publication_id=${post_gid}`
    }
    await sleep(1000)
  }

  const handleClickDownloadAllMedias = async () => {
    if (isDownloadingMedias) {
      Alert.error(`Please wait a bit longer while your medias finish downloading.`)
    } else {
      setIsDownloadingMedias(true)
      if (post_type === POST_TYPE_SOCIAL_POST) {
        const medias_for_download = collectedMedias.map(({ url, type }) => ({ url, type }))

        for (const { url, type } of medias_for_download) {
          await handleDownloadOneMedia({ url, type })
        }
      } else {
        const medias_for_download = collectedMedias.map(({ id }) => id)

        for (const media_gid of medias_for_download) {
          await handleDownloadOneMedia({ media_gid })
        }
      }
      setIsDownloadingMedias(false)
    }
  }

  const handleClickDownloadOneMedia = async ({ url, type, media_gid }) => {
    if (isDownloadingMedias) {
      Alert.error(`Please wait a bit longer while your medias finish downloading.`)
    } else {
      setIsDownloadingMedias(true)
      await handleDownloadOneMedia({ url, type, media_gid })
      setIsDownloadingMedias(false)
    }
  }

  const handleClickCloseDrawer = () => {
    handleDismiss()
  }

  return (
    <SwipeableDrawer
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
      style={{ zIndex: 2147483001 }}
      anchor="right"
      open={!!isOpen}
      onClose={() => {
        handleClickCloseDrawer()
      }}
      disableAutoFocus
      disableEnforceFocus
      onOpen={() => {}}
    >
      {isOpen && (
        <Flex minWidth="560px" maxWidth="560px" height="100%" flexDirection="column">
          {isCollectingMedias ? (
            <Flex alignItems="center" justifyContent="center" height="100%" width="100%">
              <ClipLoader size="50" color={colors.primary} />
            </Flex>
          ) : (
            <Flex flexDirection="column" height="100%">
              {collectedMedias.length > 0 ? (
                <Scrollbars universal>
                  <Flex flexDirection="column" height="100%" p="m">
                    {collectedMedias.length > 1 && (
                      <StyledPostMediaWrapper pb="m" justifyContent="flex-end">
                        <StyledDownloadText color="primary" onClick={handleClickDownloadAllMedias}>
                          Download all medias
                        </StyledDownloadText>
                      </StyledPostMediaWrapper>
                    )}

                    {collectedMedias.map((media) => {
                      const { id, type, url, thumbnail_url, description, extension, information = [] } = media

                      let mediaComponent = ''

                      if (type === IMAGE) {
                        mediaComponent = (
                          <Fragment>
                            {url ? (
                              <ImageWithFallback
                                source={url}
                                width="100%"
                                height="100%"
                                fallbackSource="/assets/emptyData.svg"
                                borderRadius={radius.l}
                              />
                            ) : (
                              <Image src="/assets/emptyData.svg" width="100%" height="100%" borderRadius={radius.l} />
                            )}
                          </Fragment>
                        )
                      } else if (type === VIDEO) {
                        mediaComponent = (
                          <Fragment>
                            {url ? (
                              <VideoWithFallback
                                url={url}
                                controls
                                width="100%"
                                style={{ position: 'relative', borderRadius: radius.l }}
                                height="100%"
                                fallbackSourceImage={thumbnail_url}
                                config={{
                                  file: {
                                    attributes: {
                                      poster: thumbnail_url || '',
                                    },
                                  },
                                }}
                              />
                            ) : (
                              <Image src="/assets/videocamera.svg" width="100%" height="100%" borderRadius={radius.l} />
                            )}
                          </Fragment>
                        )
                      } else if (type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) {
                        mediaComponent = <DynamicMedia media={media} />
                      } else if (type === DOCUMENT) {
                        mediaComponent = (
                          <Image
                            src={getFileIconByExtension({ extension })}
                            width="100%"
                            height="100%"
                            borderRadius={radius.l}
                          />
                        )
                      }

                      return (
                        <StyledPostMediaWrapper key={id} py="m" position="relative">
                          <Flex
                            width="250px"
                            height="250px"
                            bg={COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL}
                            position="relative"
                            name={id}
                            borderRadius={radius.l}
                          >
                            {mediaComponent}
                          </Flex>

                          <Flex flex="1" px="m" flexDirection="column">
                            <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="m">
                              {information.map(({ title, description }) => (
                                <Flex flexDirection="column" key={title}>
                                  <Text color="secondaryText" fontWeight="light" fontSize="xxs">
                                    {title}
                                  </Text>
                                  <Text fontSize="xs">{description}</Text>
                                </Flex>
                              ))}
                            </Grid>

                            {description && (
                              <Text mt="m" fontSize="xs">
                                {description}
                              </Text>
                            )}
                          </Flex>

                          <DownloadedWrapper
                            alignItems="center"
                            justifyContent="center"
                            onClick={() => {
                              handleClickDownloadOneMedia({ url, type, media_gid: id })
                            }}
                          >
                            <Icon.Download width="16px" height="16px" color={COLOR_CONSTANTS.SAPPHIRE} />
                          </DownloadedWrapper>
                        </StyledPostMediaWrapper>
                      )
                    })}
                  </Flex>
                </Scrollbars>
              ) : (
                <Flex width="100%" height="100%" alignItems="center" justifyContent="center">
                  <NoData message="No medias found for preview" showHeaderText={false} />
                </Flex>
              )}

              <Flex width="0px" height="0px" onClick={handleClickCloseDrawer} className="modal-close-icon" />
            </Flex>
          )}
        </Flex>
      )}
    </SwipeableDrawer>
  )
}

SliderPostMediasPreview.defaultProps = {
  medias: [],
  profile_display: '',
  post_type: '',
}

SliderPostMediasPreview.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  medias: PropTypes.array,
  post_type: PropTypes.string,
  profile_display: PropTypes.string,
  post_gid: PropTypes.string.isRequired,
}

SliderPostMediasPreview.displayName = 'SliderPostMediasPreview'

export default SliderPostMediasPreview
