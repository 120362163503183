import React, { useState, useEffect, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { COLOR_CONSTANTS, radius } from 'theme'
import { Box, Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import DeletePostModal from '../DeletePostModal'
import PreviewComponent from './PreviewComponent'
import { convertPostToEvent, generateTextHeaderForDeletePostModal } from '../../helpers'
import { POST_TYPE_SOCIAL_POST, PUBLISHED } from '../../consts'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    max-width: max-content;
    min-width: 560px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    height: auto;

    ${({ $isSmallPreview }) => $isSmallPreview && `min-width: 100%; max-width: 90wh;`}
  }
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
`

const PreviewModal = ({
  user,
  isOpen,
  handleDismiss,
  handleClickSendToCompose,
  handleDeletePost,
  isPostDeleting,
  timezone,
  handleUpdateCalendarPostLabels,
  handleClickEvent,
  hasComponentControls,
  isSmallPreview,
  sharedCalendarData,
  handleClickAddVistaSocialPostToAdvocacy,
  ...props
}) => {
  const [postForEdit, setPostForEdit] = useState({})
  const [isDeletePostModalOpen, setIsDeletePostModalOpen] = useState(false)

  useEffect(() => {
    if (isOpen) {
      setPostForEdit({ ...props.postForEdit })
    }
  }, [isOpen])

  const handleClickOpenDeletePostModal = () => {
    setIsDeletePostModalOpen(true)
  }

  const handleClickCloseDeletePostModal = () => {
    setIsDeletePostModalOpen(false)
  }

  const handleClickDeletePost = async (body) => {
    body.items = [{ id: postForEdit.id, type: postForEdit.type }]
    await handleDeletePost({ data: body, type: 'preview' })
    handleClickCloseDeletePostModal()
  }

  const handleClickCloseModal = ({ closePublisherWrapper }) => {
    handleDismiss({ closePublisherWrapper })
  }

  const handleClickDuplicatePost = () => {
    const post = { ...postForEdit }
    handleClickSendToCompose({
      post,
      selectedEntitiesForSelectedPost: props.selectedEntities,
      selectedProfilesForSelectedPost: props.selectedProfiles,
    })
    handleClickCloseModal({ closePublisherWrapper: false })
  }

  const handleClickAddToAdvocacyPost = () => {
    const post = { ...postForEdit }

    handleClickAddVistaSocialPostToAdvocacy({ post })

    handleClickCloseModal({ closePublisherWrapper: false })
  }

  const handleClickOpenEvent = () => {
    const eventInfo = convertPostToEvent({ post: postForEdit })

    handleClickEvent({ eventInfo })
    handleClickCloseModal({ closePublisherWrapper: false })
  }

  const handleUpdateCommentsCounter = ({ commentsCounter }) => {
    postForEdit.comments = commentsCounter
    setPostForEdit({ ...postForEdit })
  }

  const handleUpdatePostBoostedData = ({ target_result_boosted }) => {
    postForEdit.result.boosted = target_result_boosted
    setPostForEdit({ ...postForEdit })
  }

  const headerText = generateTextHeaderForDeletePostModal({ post: postForEdit })

  // eslint-disable-next-line prefer-const
  let { profile, result, status, advocacy_groups, type } = postForEdit || {}

  const { display: displayNetwork } = profile || {}

  const { published_link } = result || {}

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent $isSmallPreview={isSmallPreview}>
          <PreviewComponent
            user={user}
            postForEdit={postForEdit}
            hasComponentControls={hasComponentControls}
            timezone={timezone}
            handleClickOpenDeletePostModal={handleClickOpenDeletePostModal}
            handleClickDuplicatePost={handleClickDuplicatePost}
            handleUpdateCalendarPostLabels={handleUpdateCalendarPostLabels}
            handleUpdateCommentsCounter={handleUpdateCommentsCounter}
            showPerformance={!isSmallPreview}
            sharedCalendarData={sharedCalendarData}
            posts={[postForEdit]}
            handleUpdatePostBoostedData={handleUpdatePostBoostedData}
            isSmallPreview={isSmallPreview}
          />
          {handleClickEvent && status !== PUBLISHED && (
            <Flex justifyContent="flex-end" mb="m" px="m">
              <Button.Gray isSmall onClick={handleClickOpenEvent}>
                View post
              </Button.Gray>
            </Flex>
          )}
          {status === PUBLISHED && published_link && (
            <Flex justifyContent="flex-end" mb="m" px="m">
              {!sharedCalendarData && (
                <Fragment>
                  {type !== POST_TYPE_SOCIAL_POST && (
                    <Fragment>
                      {advocacy_groups && advocacy_groups.length !== 0 ? (
                        <Button.Gray
                          isSmall
                          onClick={() => {
                            window.open(`/advocacy/${advocacy_groups[0].advocacy_gid}/content`, '_self')
                          }}
                          mr="m"
                        >
                          View in advocacy
                        </Button.Gray>
                      ) : (
                        <Fragment>
                          {handleClickAddVistaSocialPostToAdvocacy && (
                            <Button.Gray
                              isSmall
                              onClick={() => {
                                handleClickAddToAdvocacyPost({ post: postForEdit })
                              }}
                              mr="m"
                            >
                              Add to advocacy
                            </Button.Gray>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}
                  {hasComponentControls && (
                    <Button.Gray
                      isSmall
                      onClick={() => {
                        handleClickDuplicatePost({ post: postForEdit })
                      }}
                      mr="m"
                    >
                      Duplicate post
                    </Button.Gray>
                  )}
                </Fragment>
              )}
              <Button.Gray
                isSmall
                onClick={() => {
                  window.open(published_link)
                }}
              >
                View on {displayNetwork || 'network'}
              </Button.Gray>
            </Flex>
          )}
          <CloseIconWrapper
            className="modal-close-icon"
            onClick={() => {
              handleClickCloseModal({})
            }}
          >
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
      {isDeletePostModalOpen && (
        <DeletePostModal
          handleDismiss={handleClickCloseDeletePostModal}
          isOpen={isDeletePostModalOpen}
          isPostSubmitting={isPostDeleting}
          handleDelete={handleClickDeletePost}
          headerText={headerText}
        />
      )}
    </StyledDialogOverlay>
  )
}

PreviewModal.defaultProps = {
  postForEdit: {},
  handleClickEvent: null,
  hasComponentControls: true,
  isSmallPreview: false,
  sharedCalendarData: null,
  handleClickAddVistaSocialPostToAdvocacy: null,
}

PreviewModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  postForEdit: PropTypes.object,
  selectedProfiles: PropTypes.array.isRequired,
  selectedEntities: PropTypes.array.isRequired,
  handleClickSendToCompose: PropTypes.func.isRequired,
  handleDeletePost: PropTypes.func.isRequired,
  isPostDeleting: PropTypes.bool.isRequired,
  timezone: PropTypes.string.isRequired,
  handleUpdateCalendarPostLabels: PropTypes.func.isRequired,
  handleClickEvent: PropTypes.func,
  hasComponentControls: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
  sharedCalendarData: PropTypes.object,
  handleClickAddVistaSocialPostToAdvocacy: PropTypes.func,
}

export default PreviewModal
