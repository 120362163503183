import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fontSizes } from 'theme'
import { truncateHTML } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'

const CollapseWrapper = styled(Text)`
  cursor: pointer;
`

const HtmlCollapse = ({ html, maxLength, expandText, collapseText, textColor, ...props }) => {
  const [isOpen, setIsOpen] = useState(false)
  const [message, setMessage] = useState('')
  const truncatedHtml = truncateHTML({ text: html, end: maxLength })
  const messageEnd = html && html.length > maxLength ? html.substr(maxLength) : ''

  const { fontSize = fontSizes.s } = props

  useEffect(() => {
    setMessage(truncatedHtml)
  }, [truncatedHtml])

  const updateMessage = (e) => {
    e.stopPropagation()
    if (!isOpen) {
      setMessage(html)
    } else {
      setMessage(truncatedHtml)
    }
    setIsOpen(!isOpen)
  }

  return (
    <Box height="100%">
      <Flex justifyContent="center" flexDirection="column">
        <Text
          dangerouslySetInnerHTML={{
            __html: message,
          }}
          {...props}
        />
      </Flex>
      {messageEnd && !isOpen && (
        <CollapseWrapper
          onClick={updateMessage}
          color={textColor}
          fontSize={fontSize}
        >{` ${expandText}`}</CollapseWrapper>
      )}
      {isOpen && (
        <CollapseWrapper
          onClick={updateMessage}
          color={textColor}
          fontSize={fontSize}
        >{` ${collapseText}`}</CollapseWrapper>
      )}
    </Box>
  )
}

HtmlCollapse.defaultProps = {
  maxLength: 250,
  expandText: 'Read more',
  collapseText: 'Read less',
  textColor: 'primary',
  fontSize: fontSizes.s,
}

HtmlCollapse.propTypes = {
  html: PropTypes.string.isRequired,
  maxLength: PropTypes.number,
  expandText: PropTypes.string,
  collapseText: PropTypes.string,
  textColor: PropTypes.string,
  fontSize: PropTypes.string,
}

export default HtmlCollapse
