import React, { Fragment, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Paper, Slide } from '@material-ui/core'
import { radius, COLOR_CONSTANTS, space } from 'theme'
import { Flex } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import { Text } from 'components/atoms/Typography'
import Badge from 'components/atoms/Badge'
import DropdownMenu from 'components/molecules/DropdownMenu'
import { CUSTOMIZATION_TRANSITION_TIMEOUT, STATUS_COLORS, STATUS_FILTERS } from 'routes/Calendar/consts'
// eslint-disable-next-line import/no-cycle
import { getSelectedProfileGroupsAndTimezone } from 'routes/Calendar/helpers'
import HistoryPostModal from '../../HistoryPostModal'

const StyledPaper = styled(Paper)`
  position: relative;
  background-color: transparent !important;
  color: inherit !important;
`

const StyledArrowWrapper = styled(Flex)`
  background-color: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.pill};
  cursor: pointer;
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const StyledCustomizationTextWrapper = styled(Flex)`
  border-bottom: 2px solid ${COLOR_CONSTANTS.WHITE};
`

const ThreeDotsWrapper = styled(Flex)`
  margin-right: ${space.s};
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: ${radius.pill};
  box-shadow: 0px 0px 6px rgb(39 40 49 / 10%);
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const ThreeDots = styled(Image)`
  cursor: pointer;
  padding-left: 0;
  padding-right: 0;
  width: 12px;
  height: 12px;
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
`

const DEFAULT_HISTORY_MODAL_OPTIONS = {
  isOpen: false,
  selectedTimezone: null,
}

const CustomizationsSlider = ({
  user,
  isCustomizationsOpen,
  setIsCustomizationsOpen,
  post,
  profile,
  CustomizationsComponent,
}) => {
  const [historyPostModalOptions, setHistoryPostModalOptions] = useState({ ...DEFAULT_HISTORY_MODAL_OPTIONS })

  const { postsData } = post
  const { profileId } = profile

  const { status } = postsData ? postsData[profileId] || {} : {}

  const foundStatus = status ? STATUS_FILTERS.find(({ id }) => id === status) : null

  const handleClickOpenHistoryModal = () => {
    const { selectedTimezone } = getSelectedProfileGroupsAndTimezone({
      user,
    })

    setHistoryPostModalOptions({ ...{ isOpen: true, selectedTimezone } })
  }

  const handleClickCloseHistoryModal = () => {
    setHistoryPostModalOptions({ ...DEFAULT_HISTORY_MODAL_OPTIONS })
  }

  return (
    <Fragment>
      <Slide
        direction="left"
        in={!isCustomizationsOpen}
        timeout={{ enter: CUSTOMIZATION_TRANSITION_TIMEOUT, appear: 0, exit: 0 }}
        unmountOnExit
      >
        <StyledPaper elevation={0}>
          <StyledCustomizationTextWrapper py="m" justifyContent="space-between" alignItems="center">
            <Flex justifyContent="center" width="40px">
              <StyledArrowWrapper
                alignItems="center"
                justifyContent="center"
                onClick={() => {
                  setIsCustomizationsOpen(!isCustomizationsOpen)
                }}
              >
                <Image src="/assets/vistasocial/chevron_left_filled.svg" width="24px" height="24px" />
              </StyledArrowWrapper>
            </Flex>
          </StyledCustomizationTextWrapper>
        </StyledPaper>
      </Slide>
      <Slide
        direction="left"
        in={isCustomizationsOpen}
        timeout={{ enter: CUSTOMIZATION_TRANSITION_TIMEOUT, appear: 0, exit: 0 }}
        unmountOnExit
      >
        <StyledPaper elevation={0}>
          <StyledCustomizationTextWrapper py="m" justifyContent="space-between" alignItems="center" height="45px">
            <Text ml="m" fontSize="s">
              Customize your post
            </Text>

            <Flex justifyContent="flex-end" alignItems="center" flex="1">
              {foundStatus && (
                <Fragment>
                  <Flex mr="s" display={{ mobile: 'none', desktopWide: 'flex' }}>
                    <Badge.Status color={STATUS_COLORS[status]}>
                      <Excerpt fontSize="xs" textAlign="center">
                        {foundStatus.name}
                      </Excerpt>
                    </Badge.Status>
                  </Flex>

                  <Flex
                    display={{ mobile: 'flex', desktopWide: 'none' }}
                    mr="s"
                    key={status}
                    width="8px"
                    height="8px"
                    borderRadius={radius.pill}
                    bg={STATUS_COLORS[status]}
                  />
                </Fragment>
              )}

              {post && (post.id || post.draftId) && (
                <Fragment>
                  <DropdownMenu
                    WrapperComp={
                      <ThreeDotsWrapper>
                        <ThreeDots source="/assets/vistasocial/more.svg" />
                      </ThreeDotsWrapper>
                    }
                    isDismissedOnClickInside
                  >
                    <DropdownMenu.Item
                      onClick={handleClickOpenHistoryModal}
                      isCursorPointer
                      label="View post history"
                    />
                  </DropdownMenu>
                </Fragment>
              )}
            </Flex>

            <StyledArrowWrapper
              alignItems="center"
              justifyContent="center"
              onClick={() => {
                setIsCustomizationsOpen(!isCustomizationsOpen)
              }}
              mr="m"
            >
              <Image src="/assets/vistasocial/chevron_right_filled.svg" width="24px" height="24px" />
            </StyledArrowWrapper>
          </StyledCustomizationTextWrapper>

          {CustomizationsComponent}
        </StyledPaper>
      </Slide>

      {historyPostModalOptions.isOpen && (
        <HistoryPostModal
          handleClickCloseModal={handleClickCloseHistoryModal}
          timezone={historyPostModalOptions.selectedTimezone}
          post={post}
          isOpen={historyPostModalOptions.isOpen}
        />
      )}
    </Fragment>
  )
}

CustomizationsSlider.defaultProps = {}

CustomizationsSlider.propTypes = {
  user: PropTypes.object.isRequired,
  isCustomizationsOpen: PropTypes.bool.isRequired,
  setIsCustomizationsOpen: PropTypes.func.isRequired,
  post: PropTypes.object.isRequired,
  profile: PropTypes.object.isRequired,
  CustomizationsComponent: PropTypes.node.isRequired,
}

export default CustomizationsSlider
