import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, fontSizes } from 'theme'
import { mapArrayToReactSelect, pxToRem } from 'helpers'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import Tooltip from 'components/molecules/Tooltip'
import Icon from 'components/atoms/Icon'
import DropDown from 'shared/DropDown'
import BoostConfigurationModal from 'routes/Settings/PublishingSettings/BoostConfigurationSettings/BoostConfigurationModal'
import {
  OBJECTIVE,
  DELAY,
  BUDGET_IN_DOLLARS,
} from 'routes/Settings/PublishingSettings/BoostConfigurationSettings/BoostConfigurationModal/consts'
import TooltipPromptComponent from './TooltipPromptComponent'

const StyledIconWrapper = styled(Flex)`
  width: 36px;
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  height: auto;
  border-left: none;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: ${COLOR_CONSTANTS.WHITE};
`

const StyledText = styled(Text)`
  cursor: pointer;
  color: ${colors.primary};
  font-size: ${fontSizes.xs};
  &:hover {
    text-decoration: underline;
  }
`

const ActiveText = styled(Text)`
  cursor: pointer;
`

const transformToReactSelectData = (data) => {
  if (data) {
    return { value: data.id, label: data.name }
  } else {
    return null
  }
}

const DEFAULT_BOOST_CONFIGURATION_MODAL_OPTIONS = {
  isOpen: null,
  boostId: null,
  selectedProfile: null,
  publicationId: null,
}

const BoostsDropdownComponent = ({ boosts, handleUpdateCustomization, boostId, user, profile, isBlocked }) => {
  const [boostConfigurationModalOptions, setBoostConfigurationsModalOptions] = useState({
    ...DEFAULT_BOOST_CONFIGURATION_MODAL_OPTIONS,
  })
  const [isGettingBoosts, setIsGettingBoosts] = useState(false)
  const [activeBoost, setActiveBoost] = useState(null)
  const [newBoostId, setNewBoostId] = useState(null)

  useEffect(() => {
    if (isGettingBoosts) {
      setIsGettingBoosts(false)
    }
    if (boosts) {
      if (boostId && !isBlocked) {
        let foundBoost

        if (newBoostId) {
          foundBoost = boosts.find(({ id }) => newBoostId === id)
          setNewBoostId(null)

          if (foundBoost) {
            handleUpdateCustomization({
              type: 'boost_gid',
              value: foundBoost.id,
            })
          }
        } else {
          foundBoost = boosts.find(({ id }) => boostId === id)
        }

        if (foundBoost) {
          const blockedConfigurationKeys = [OBJECTIVE, 'identity_id', 'identity_type', 'kind']

          const { configuration = {} } = foundBoost

          const configuration_array = []

          for (const key in configuration) {
            if (configuration.hasOwnProperty(key) && !blockedConfigurationKeys.includes(key)) {
              const configuration_key_data = configuration[key]

              const temp_array_of_strings = []

              if (key === DELAY) {
                const { value, type } = configuration_key_data

                temp_array_of_strings.push(
                  `${value} ${value === 1 ? type.substring(type, type.length - 1) : type}`.toLowerCase()
                )
              } else if (Array.isArray(configuration_key_data)) {
                configuration_key_data.forEach(({ name, description }) => {
                  temp_array_of_strings.push(name || description)
                })
              } else if (typeof configuration_key_data === 'object') {
                const { name, description } = configuration_key_data
                temp_array_of_strings.push(name || description)
              } else {
                temp_array_of_strings.push(configuration_key_data)
              }

              if (temp_array_of_strings.length !== 0) {
                let key_changed_name = key
                if (key === BUDGET_IN_DOLLARS) {
                  key_changed_name = 'Budget'
                }

                const key_transformed = key_changed_name.split('_').join(' ')
                configuration_array.push(
                  `${key_transformed.charAt(0).toUpperCase()}${key_transformed
                    .substring(1)
                    .toLowerCase()}: ${temp_array_of_strings.join('<br>')}`
                )
              }
            }
          }

          if (configuration_array.length !== 0) {
            configuration_array.sort()
            foundBoost.configuration_string = configuration_array.join('<br>')
          }

          setActiveBoost({ ...foundBoost })
        } else {
          handleUpdateCustomization({
            type: 'boost_gid',
            value: null,
          })
        }
      } else if (activeBoost) {
        setActiveBoost(null)
      }
    }
  }, [boosts, boostId])

  const handleClickReloadBoostsInformation = () => {
    setIsGettingBoosts(true)
    window.dispatchEvent(new CustomEvent('handleUpdateBoosts'))
  }

  const handleClickOpenBoostConfigurationModal = ({ boostId }) => {
    const { profileId, profileName, profileUsername, network, profilePictureUrl, display } = profile

    setBoostConfigurationsModalOptions({
      ...{
        isOpen: true,
        boostId,
        selectedProfile: {
          value: profileId,
          label: profileName || profileUsername,
          network: { code: network, display },
          picture_url: profilePictureUrl,
        },
      },
    })
  }

  const handleClickCloseBoostConfigurationModal = (props) => {
    const { boost, isNew = false } = props || {}

    if (boost) {
      handleClickReloadBoostsInformation()

      if (isNew) {
        setNewBoostId(boost.id)
      }
    }

    setBoostConfigurationsModalOptions({ ...DEFAULT_BOOST_CONFIGURATION_MODAL_OPTIONS })
  }

  return (
    <Fragment>
      <Flex mt="m" flexDirection="column" width="100%">
        <Flex alignItems="center" width="100%">
          <Flex width="100%">
            <Flex flexDirection="column" width="100%">
              <DropDown
                noOptionsMessage={() => {
                  return 'No boosts configured for this profile'
                }}
                placeholder="Select a boost"
                value={transformToReactSelectData(activeBoost)}
                onChange={(option) => {
                  handleUpdateCustomization({
                    type: 'boost_gid',
                    value: option.value,
                  })
                }}
                options={boosts ? mapArrayToReactSelect([{ id: null, name: 'No boosting' }, ...boosts]) : []}
                openMenuOnFocus
                menuPlacement="top"
                stylesControlOverride={{ borderRadius: '6px 0 0 6px' }}
                isDisabled={!!isBlocked}
              />
            </Flex>

            <Tooltip
              wrapperComp={
                <StyledIconWrapper
                  onClick={() => {
                    if (!isBlocked) {
                      handleClickOpenBoostConfigurationModal({})
                    }
                  }}
                >
                  <Icon.Plus width="12px" height="12px" />
                </StyledIconWrapper>
              }
              message={isBlocked || 'Add boost'}
              width="120px"
              right="0px"
              left="unset"
              isTriangleVisible={false}
            />

            <Tooltip
              wrapperComp={
                <Fragment>
                  {isGettingBoosts ? (
                    <StyledIconWrapper>
                      <ClipLoader size="14" color={COLOR_CONSTANTS.COSMIC_ENERGY} />
                    </StyledIconWrapper>
                  ) : (
                    <StyledIconWrapper
                      onClick={() => {
                        if (!isBlocked) {
                          handleClickReloadBoostsInformation()
                        }
                      }}
                    >
                      <Icon.Sync width="14px" height="14px" />
                    </StyledIconWrapper>
                  )}
                </Fragment>
              }
              message={isBlocked || 'Reload boosts'}
              width="120px"
              right="0px"
              left="unset"
              isTriangleVisible={false}
            />

            <Tooltip
              wrapperComp={
                <StyledIconWrapper
                  onClick={() => {
                    if (!isBlocked) {
                      window.open('/settings/publishing-settings/boost-configuration', '_blank')
                    }
                  }}
                  borderRadius="0 6px 6px 0"
                >
                  <Icon.Gear width="12px" height="12px" />
                </StyledIconWrapper>
              }
              message={isBlocked || 'Manage boosts'}
              width="120px"
              right="0px"
              left="unset"
              isTriangleVisible={false}
            />
          </Flex>
          <TooltipPromptComponent prompt_key="boost" />
        </Flex>

        {activeBoost && (
          <Flex mt="xs" ml="s" flexDirection="column" width="100%" pr={pxToRem(18)}>
            {activeBoost.configuration_string && (
              <ActiveText
                mt="s"
                color="secondaryText"
                fontSize="xs"
                onClick={() => {
                  handleClickOpenBoostConfigurationModal({ boostId })
                }}
                dangerouslySetInnerHTML={{
                  __html: activeBoost.configuration_string,
                }}
              />
            )}
            <Flex alignItems="center" justifyContent="space-between">
              <StyledText
                onClick={() => {
                  handleClickOpenBoostConfigurationModal({ boostId })
                }}
              >
                Edit
              </StyledText>
            </Flex>
          </Flex>
        )}
      </Flex>

      {boostConfigurationModalOptions.isOpen && (
        <BoostConfigurationModal
          user={user}
          handleDismiss={handleClickCloseBoostConfigurationModal}
          isOpen={boostConfigurationModalOptions.isOpen}
          boostId={boostConfigurationModalOptions.boostId}
          selectedProfile={boostConfigurationModalOptions.selectedProfile}
        />
      )}
    </Fragment>
  )
}

BoostsDropdownComponent.defaultProps = {
  boosts: [],
  boostId: null,
  isBlocked: '',
}

BoostsDropdownComponent.propTypes = {
  user: PropTypes.object.isRequired,
  boosts: PropTypes.array,
  handleUpdateCustomization: PropTypes.func.isRequired,
  boostId: PropTypes.string,
  profile: PropTypes.object.isRequired,
  isBlocked: PropTypes.string,
}

export default BoostsDropdownComponent
