import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, colors, radius, space } from 'theme'
import { Flex } from 'components/atoms/Layout'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Bar from './Bar'
import TrackInfo from './TrackInfo'
import PauseIcon from './assets/PauseIcon'
import PlayIcon from './assets/PlayIcon'

const StyledControlsWrapper = styled(Flex)`
  justify-content: space-between;
  align-items: center;
  padding: ${space.s} 0;
  background-color: ${COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL};
  border-radius: ${radius.l};
  min-height: 36px;
  height: 36px;
`

const StyledControls = styled(Flex)`
  flex-grow: 1;
  margin: 0 ${space.s};
  justify-content: center;
  align-items: center;
`

const StyledPlayerButton = styled.button`
  background-color: transparent;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  outline: none;

  svg {
    color: ${transparentize(0.8, colors.primaryText)};
    width: ${({ isSmall }) => (isSmall ? '36px' : '24px')};
    height: ${({ isSmall }) => (isSmall ? '36px' : '24px')};
    path {
      fill: ${transparentize(0.8, colors.primaryText)};
    }
  }

  &:hover {
    svg {
      color: ${colors.primaryText};
      path {
        fill: ${colors.primaryText};
      }
    }
  }
`

const StyledAudioCoverWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  width: 100%;
  height: calc(100% - 36px);
  cursor: pointer;
  position: relative;
`

const StyledAudioCover = styled(ImageWithFallback)`
  width: 100%;
  height: 100%;
  object-fit: ${({ hasImage }) => (hasImage ? 'cover' : 'contain')};
  border-radius: ${radius.l} ${radius.l} 0 0;
`

const Player = ({
  source,
  trackId,
  trackName,
  trackArtist,
  trackImage,
  loop,
  preload,
  togglePlay,
  isCurrent,
  duration: propsDuration,
  isSmall,
  showCover,
}) => {
  const audioRef = useRef()

  const [duration, setDuration] = useState(propsDuration)
  const [curTime, setCurTime] = useState()
  const [playing, setPlaying] = useState(false)
  const [clickedTime, setClickedTime] = useState()

  let updatedSource = source

  if (!Array.isArray(source) && String(source) === source) {
    updatedSource = source.split()
  }

  useEffect(() => {
    const audio = audioRef.current

    const setAudioData = () => {
      setDuration(audio.duration)
      setCurTime(audio.currentTime)
    }

    const setAudioTime = () => setCurTime(audio.currentTime)

    audio.addEventListener('loadeddata', setAudioData)
    audio.addEventListener('timeupdate', setAudioTime)

    if (clickedTime && clickedTime !== curTime) {
      audio.currentTime = clickedTime
      setClickedTime(null)
    }

    return () => {
      audio.removeEventListener('loadeddata', setAudioData)
      audio.removeEventListener('timeupdate', setAudioTime)
    }
  }, [clickedTime, curTime])

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      {!isSmall && (
        <StyledAudioCoverWrapper
          onClick={() => {
            setPlaying(!playing)
            togglePlay(trackId)
          }}
        >
          <StyledAudioCover
            source={trackImage || '/assets/vistasocial/musical_note.svg'}
            fallbackSouce="/assets/vistasocial/musical_note.svg"
            hasImage={trackImage}
          />
        </StyledAudioCoverWrapper>
      )}
      <StyledControlsWrapper>
        {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
        <audio
          id={trackId}
          ref={audioRef}
          loop={loop}
          preload={preload}
          className="trending-audio-player"
          onPause={() => {
            if (playing) {
              setPlaying(false)
            }
          }}
        >
          {updatedSource &&
            updatedSource.map((src, i) => {
              // eslint-disable-next-line react/no-array-index-key
              return <source key={i} src={src} />
            })}
          Your browser does not support the <code>audio</code> element.
        </audio>

        <StyledControls>
          {isSmall && (
            <TrackInfo trackName={trackName} trackArtist={trackArtist} trackImage={trackImage} showCover={showCover} />
          )}
          {playing && isCurrent ? (
            <StyledPlayerButton
              isSmall={isSmall}
              onClick={() => {
                setPlaying(false)
                togglePlay(trackId)
              }}
            >
              <PauseIcon />
            </StyledPlayerButton>
          ) : (
            <StyledPlayerButton
              isSmall={isSmall}
              onClick={() => {
                setPlaying(true)
                togglePlay(trackId)
              }}
            >
              <PlayIcon />
            </StyledPlayerButton>
          )}
          <Bar
            curTime={curTime}
            duration={duration}
            onTimeUpdate={(time) => setClickedTime(time)}
            trackId={trackId}
            isSmall={isSmall}
          />
        </StyledControls>
      </StyledControlsWrapper>
    </Flex>
  )
}

Player.defaultProps = {
  trackName: 'Unknown',
  trackArtist: 'Unknown Artist',
  loop: false,
  preload: 'metadata',
  trackImage: '',
  isCurrent: false,
  duration: 0,
}

Player.propTypes = {
  source: PropTypes.string.isRequired,
  trackId: PropTypes.string.isRequired,
  trackName: PropTypes.string,
  trackArtist: PropTypes.string,
  trackImage: PropTypes.string,
  loop: PropTypes.bool,
  preload: PropTypes.string,
  togglePlay: PropTypes.func.isRequired,
  isCurrent: PropTypes.bool,
  duration: PropTypes.number,
  isSmall: PropTypes.bool.isRequired,
  showCover: PropTypes.bool.isRequired,
}

export default Player
