import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import debounce from 'lodash.debounce'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { DialogContent, DialogOverlay } from '@reach/dialog'
import { COLOR_CONSTANTS, radius } from 'theme'
import { TWITTER } from 'consts'
import { H4 } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import Button from 'components/atoms/Button'
import Select from 'shared/DropDown'
// eslint-disable-next-line import/no-cycle
import { formatOptionLabel, getAccounts } from 'routes/Calendar/helpers'
import PreviewPromptComponent from './PreviewPromptComponent'

const StyledDialogOverlay = styled(DialogOverlay)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001;
  }
`

const StyledDialogContent = styled(DialogContent)`
  &&& {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 530px;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0;
    height: auto;
    min-height: 200px;
    display: flex;
    flex-direction: column;
    outline: none;
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  height: 100%;
  overflow: hidden;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const TwitterImageMentionModal = ({ isOpen, handleDismiss, mentions, handleClickSaveUserTags, profileId }) => {
  const [userTags, setUserTags] = useState([])
  const [getSuggestedOptionsErrors, setGetSuggestedOptionsErrors] = useState({})

  useEffect(() => {
    if (isOpen) {
      setGetSuggestedOptionsErrors({})

      setUserTags(mentions.map(({ id, name, username }) => ({ value: id, label: name, username })))
    }
  }, [isOpen])

  const loadSuggestedOptions = React.useCallback(
    debounce((loadData, callback) => {
      getAccounts(loadData).then((options) => {
        callback(options)
      })
    }, 500),
    []
  )

  const handleClickSave = () => {
    handleClickSaveUserTags({
      data: userTags.map(({ value, label, username }) => ({ id: value, name: label, username })),
    })
    handleDismiss()
  }

  return (
    <StyledDialogOverlay isOpen={isOpen} onDismiss={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent tabIndex={0}>
          <StyledDialogEnvironmentWrapper px="m" $isTop>
            <H4 my="m">Tag people</H4>
          </StyledDialogEnvironmentWrapper>
          <StyledDialogBodyWrapper
            flex="1"
            heigth="100%"
            width="100%"
            flexDirection="column"
            justifyContent="center"
            px="m"
            pt="m"
          >
            <Select
              placeholder="Search people"
              value={userTags.length !== 0 ? userTags : null}
              onChange={(options) => {
                if (options) {
                  setUserTags([...options])
                } else {
                  setUserTags([])
                }
              }}
              loadOptions={(value, callback) =>
                loadSuggestedOptions(
                  { value, network: TWITTER, id: profileId, getSuggestedOptionsErrors, setGetSuggestedOptionsErrors },
                  callback
                )
              }
              defaultValue={[]}
              isMulti
              isClearable
              defaultOptions={[]}
              openMenuOnFocus
              dropDownType="AsyncSelect"
              showDropDownArrow={false}
              formatOptionLabel={({ ...props }) => {
                return formatOptionLabel(props)
              }}
              noOptionsMessage={() => {
                return getSuggestedOptionsErrors[TWITTER] ? 'No results found' : null
              }}
            />
            <Flex mt="xs" flexDirection="column" width="100%">
              <PreviewPromptComponent
                text="WARNING: If the user you're tagging doesn't have photo-tagging enabled, their names won't show up in the list of tagged users even though the Tweet is successfully created."
                isShown
                type="warning"
              />
            </Flex>
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <Button.Gray isSmall onClick={handleDismiss}>
              Close
            </Button.Gray>
            <Button.Gradient
              isSmall
              onClick={() => {
                handleClickSave()
              }}
            >
              Save
            </Button.Gradient>
          </StyledDialogEnvironmentWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleDismiss}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>
      </Box>
    </StyledDialogOverlay>
  )
}

TwitterImageMentionModal.defaultProps = {}

TwitterImageMentionModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  handleClickSaveUserTags: PropTypes.func.isRequired,
  mentions: PropTypes.array.isRequired,
  profileId: PropTypes.number.isRequired,
}

export default TwitterImageMentionModal
