import React, { Fragment, useRef } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Scrollbars } from 'react-custom-scrollbars-2'
import moment from 'moment-timezone'
import ClipLoader from 'react-spinners/ClipLoader'
import Alert from 'react-s-alert'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { COLOR_CONSTANTS, colors, fontSizes, radius, space } from 'theme'
import { IMAGE, VIDEO, IMAGE_DYNAMIC, VIDEO_DYNAMIC, FEATURE_QUEUE_LABELS } from 'consts'
import { Text } from 'components/atoms/Typography'
import { Box, Flex } from 'components/atoms/Layout'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Icon from 'components/atoms/Icon'
import Button from 'components/atoms/Button'
import NoData from 'components/molecules/NoData'
import Image from 'components/atoms/Image'
import DynamicMedia from 'components/molecules/DynamicMedia'
import DropdownMenu from 'components/molecules/DropdownMenu'
import Badge from 'components/atoms/Badge'
import {
  PLANNED,
  POST_IMAGE,
  POST_LINK,
  POST_MEDIA_CLASS_NAME,
  POST_MIXED_MEDIA,
  POST_VIDEO,
  PUBLISH_AS_REELS,
  PUBLISH_AS_STORY,
} from 'routes/Calendar/consts'
import { formatTimezoneToGMT, getHeaderTitleAndColor } from 'routes/Calendar/helpers'
import CalendarItemTooltip from 'routes/Calendar/components/CalendarItemTooltip'
import { hoverComponent } from 'routes/Calendar/calendarRenderHelper'
import { TABLE_POSTS_ID_PREFIX, PHONE_POSTS_ID_PREFIX } from '../consts'

const StyledTitleWrapper = styled(Flex)`
  border-bottom: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
`

const StyledPostWrapper = styled(Flex)`
  cursor: pointer;
  border-bottom: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  &:hover {
    background-color: ${COLOR_CONSTANTS.SOLITUDE} !important;
  }
`

const StyledRoundedButton = styled(Button.Gray)`
  min-width: auto;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: ${radius.pill};
  ${({ hasError }) => hasError && `background-color:${colors.error}`};
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const MultipleAttachmentsWrapper = styled(Flex)`
  position: absolute;
  justify-content: center;
  align-items: center;
  min-width: 16px;
  padding: ${space.xs};
  height: 16px;
  background-color: ${colors.primaryText};
  border-radius: ${radius.pill};
  bottom: -6px;
  left: 22px;
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background: ${COLOR_CONSTANTS.WHITE};
`

const StyledFlex = styled(Flex)`
  cursor: pointer;
`

const StyledMediaWrapper = styled(Flex)`
  border-left: 3px solid ${({ borderColor }) => borderColor};
`

const StyledText = styled(Text)`
  text-decoration: ${({ isRemoved }) => (isRemoved ? 'line-through' : 'none')};
`

const StyledIconGearWrapper = styled(Box)`
  user-select: none;
  position: relative;
  cursor: pointer;
`

const Excerpt = styled(Text)`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: inherit;
`

const StyledBadge = styled(Badge.Status)`
  padding: ${space.xxs} ${space.xs};
  font-size: ${fontSizes.xxxs};
  margin-right: 0 ${space.xxs} 0 0;
  min-width: 0;
`

const StyledLink = styled(Text)`
  text-decoration: none;
`

const StyledBadgeStatus = styled(Badge.Status)`
  min-width: auto;
`

const PostsComponent = ({
  postsByProfile,
  setPostsByProfile,
  activeProfile,
  handleClickEditPost,
  handleClickRemovePost,
  selectedQueueLabelByProfile,
  setSelectedQueueLabelByProfile,
  usedQueueLabelsByProfile,
  featuresEnabled,
}) => {
  const tooltipRef = useRef(null)

  const { id: profileId } = activeProfile || {}
  const { [profileId]: { id: profile_gid, timezone, posts = [], isGettingPublications, code } = {} } = postsByProfile

  const { [profileId]: selectedQueueLabel } = selectedQueueLabelByProfile
  const { [profileId]: usedQueueLabels = [] } = usedQueueLabelsByProfile

  const updatedTimeZone = formatTimezoneToGMT(timezone).short

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list)
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
  }

  const onDragEnd = (result) => {
    if (!result.destination) {
      return
    }

    postsByProfile[activeProfile.id].posts = reorder(posts, result.source.index, result.destination.index)
    setPostsByProfile({ ...postsByProfile })
  }

  return (
    <Flex flexDirection="column" mt="xs" width="100%" height="100%">
      <Scrollbars universal>
        <Flex flexDirection="column" pr="m" height="100%">
          <StyledTitleWrapper pb="s" flexDirection="column" position="relative">
            <Flex alignItems="center" justifyContent="space-between" width="100%">
              <Flex alignItems="center" width="calc(100% - 20px)">
                <Excerpt fontSize="l">Post schedule</Excerpt>
              </Flex>

              {usedQueueLabels && usedQueueLabels.length > 1 && (
                <Flex alignItems="center">
                  <DropdownMenu
                    isTriangleVisible={false}
                    WrapperComp={
                      <StyledIconGearWrapper>
                        <Icon.Gear width="16px" height="16px" />
                      </StyledIconGearWrapper>
                    }
                    isDismissedOnClickInside
                  >
                    {usedQueueLabels.map(({ value, label }) => (
                      <DropdownMenu.Item
                        key={value}
                        labelMaxWidth="170px"
                        label={label}
                        onClick={() => {
                          if (featuresEnabled[FEATURE_QUEUE_LABELS].enabled) {
                            selectedQueueLabelByProfile[activeProfile.id] = { value, label }
                            setSelectedQueueLabelByProfile({ ...selectedQueueLabelByProfile })
                          } else {
                            Alert.error(featuresEnabled[FEATURE_QUEUE_LABELS].description, {
                              timeout: 5000,
                            })
                          }
                        }}
                        iconNameEnding="Checkmark"
                        iconWidthEnding={
                          (selectedQueueLabel && selectedQueueLabel.value === value) || (!selectedQueueLabel && !value)
                            ? '10px'
                            : '0px'
                        }
                        iconColorEnding={colors.primaryText}
                        isCursorPointer
                      />
                    ))}
                  </DropdownMenu>
                </Flex>
              )}
            </Flex>

            <Text color="secondaryText" mt="s" as="span">
              Drag media to the phone preview grid to schedule new posts{' '}
              {selectedQueueLabel && selectedQueueLabel.value ? (
                <Text as="span" color="secondaryText">
                  based on{' '}
                  <StyledLink
                    color="secondaryText"
                    as="a"
                    href={`/settings/profiles/${activeProfile.id}#queue`}
                    target="_blank"
                  >
                    <StyledBadgeStatus mr="xxs">
                      <Text as="span" fontSize="xs" textAlign="center">
                        {selectedQueueLabel.value}
                      </Text>
                    </StyledBadgeStatus>
                    label.
                  </StyledLink>
                </Text>
              ) : (
                <Fragment>
                  Times are selected based{' '}
                  <Text
                    color="secondaryText"
                    as="a"
                    href={`/settings/profiles/${activeProfile.id}#queue`}
                    target="_blank"
                  >
                    queue setup
                  </Text>{' '}
                  for this profile
                </Fragment>
              )}
            </Text>
          </StyledTitleWrapper>

          <Flex flexDirection="column" height="100%" position="relative">
            <StyledProgressWrapper
              width="100%"
              height="100%"
              display={isGettingPublications ? 'flex' : 'none'}
              alignItems="center"
              justifyContent="center"
            >
              <ClipLoader size="100" color={colors.primary} />
            </StyledProgressWrapper>
            {posts.length === 0 ? (
              <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
                <NoData message="No scheduled posts" showHeaderText={false} image="/assets/noPostsIllustration.svg" />
              </Flex>
            ) : (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable droppableId="droppable" direction="vertical">
                  {(provided) => (
                    <Flex flexDirection="column" width="100%" {...provided.droppableProps} ref={provided.innerRef}>
                      {posts.map((post, index) => {
                        const {
                          id: post_gid,
                          isNew,
                          postImages,
                          postVideos,
                          postMixedMedias,
                          link,
                          postComponent,
                          publish_at,
                          hasError,
                          customizations,
                          status,
                          hasChanged,
                          isRemoved,
                          selectedQueueLabel: postSelectedQueueLabel,
                        } = post

                        const { [code]: networkCustomizations } = customizations || {}
                        const { [profile_gid]: profileCustomizations } = networkCustomizations || {}
                        const { media_type } = profileCustomizations || {}

                        let medias = []

                        if (postComponent === POST_IMAGE) {
                          medias = postImages
                        } else if (postComponent === POST_VIDEO) {
                          medias = postVideos
                        } else if (postComponent === POST_MIXED_MEDIA) {
                          medias = postMixedMedias
                        }

                        const { 0: { url, thumbnail_url, type, thumbnail_url_small, id: media_gid } = {} } = medias

                        const { picture_url, media_gid: linkPictureMediaId } = link || {}

                        const mediaLength = medias.length

                        let showPublishAtTime = publish_at

                        if (showPublishAtTime) {
                          if (
                            showPublishAtTime.toLowerCase().includes('queue') ||
                            showPublishAtTime.toLowerCase().includes('now') ||
                            showPublishAtTime.toLowerCase().includes('draft')
                          ) {
                            showPublishAtTime = `${showPublishAtTime.charAt(0).toUpperCase()}${showPublishAtTime
                              .slice(1)
                              .replace(/_/g, ' ')
                              .toLowerCase()}`
                          } else {
                            showPublishAtTime = moment(showPublishAtTime).format('MMM D, YYYY h:mm a')
                          }
                        }

                        let postComponentTypeTemplate = ''

                        if (media_type === PUBLISH_AS_REELS) {
                          postComponentTypeTemplate = (
                            <Image src="/assets/vistasocial/instagram_reel.svg" height="15px" width="15px" />
                          )
                        } else if (media_type === PUBLISH_AS_STORY) {
                          postComponentTypeTemplate = (
                            <Image src="/assets/vistasocial/instagram_story.svg" height="15px" width="15px" />
                          )
                        } else {
                          postComponentTypeTemplate = (
                            <Image src="/assets/vistasocial/instagram_grid.svg" height="15px" width="15px" />
                          )
                        }

                        let { bgColor } = getHeaderTitleAndColor({ status, network: code, post })

                        if (isNew) {
                          bgColor = 'transparent'
                        }

                        let queueLabel = postSelectedQueueLabel

                        if (isNew && !queueLabel && selectedQueueLabel) {
                          queueLabel = selectedQueueLabel
                        }

                        return (
                          <Draggable key={post_gid} draggableId={`draggable-${post_gid}`} index={index}>
                            {(provided) => (
                              <StyledPostWrapper
                                p="s"
                                alignItems="center"
                                justifyContent="space-between"
                                id={`${TABLE_POSTS_ID_PREFIX}-${post_gid}`}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                              >
                                <Flex alignItems="center" width="100%">
                                  <Flex
                                    alignItems="center"
                                    width="100%"
                                    onMouseEnter={(e) => {
                                      const el = document.getElementById(`${PHONE_POSTS_ID_PREFIX}-${post_gid}`)
                                      if (el) {
                                        el.style.boxShadow = '0px 0px 5px 5px rgb(39 40 49 / 30%)'
                                      }

                                      tooltipRef.current.handleShowTooltip({
                                        contentComp: hoverComponent({
                                          post: {
                                            ...post,
                                            profile: {
                                              profileName: activeProfile.name,
                                              profilePictureUrl: activeProfile.picture_url,
                                              network: activeProfile.network.code,
                                              display: activeProfile.network.display,
                                              profileUrl: activeProfile.profile_url,
                                            },
                                            profileNetwork: activeProfile.network.code,
                                            status: isNew ? PLANNED : status,
                                          },
                                          profile: activeProfile,
                                        }),
                                        wrapperComp: e.currentTarget,
                                      })
                                    }}
                                    onMouseLeave={() => {
                                      const el = document.getElementById(`${PHONE_POSTS_ID_PREFIX}-${post_gid}`)
                                      if (el) {
                                        el.style.boxShadow = 'none'
                                      }
                                      tooltipRef.current.handleHideTooltip()
                                    }}
                                  >
                                    <StyledMediaWrapper alignItems="center" width="100%" borderColor={bgColor}>
                                      <Flex width="32px" height="32px" position="relative" ml="s">
                                        {mediaLength > 1 && (
                                          <MultipleAttachmentsWrapper>
                                            <Text as="span" color={colors.white} fontSize="6px">
                                              +{mediaLength - 1}
                                            </Text>
                                          </MultipleAttachmentsWrapper>
                                        )}

                                        {postComponent === POST_LINK ? (
                                          <ImageWithFallback
                                            id={`${POST_MEDIA_CLASS_NAME}-${linkPictureMediaId}`}
                                            width="100%"
                                            height="100%"
                                            source={picture_url}
                                            fallbackSource="/assets/landscape.svg"
                                            fallbackSourceWidth="50%"
                                            fallbackSourceHeight="50%"
                                            borderRadius={radius.l}
                                          />
                                        ) : (
                                          <Fragment>
                                            {type === IMAGE && (
                                              <ImageWithFallback
                                                id={`${POST_MEDIA_CLASS_NAME}-${media_gid}`}
                                                width="100%"
                                                height="100%"
                                                source={thumbnail_url_small || thumbnail_url || url}
                                                fallbackSource="/assets/landscape.svg"
                                                fallbackSourceWidth="50%"
                                                fallbackSourceHeight="50%"
                                                borderRadius={radius.l}
                                              />
                                            )}

                                            {(type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) && (
                                              <DynamicMedia
                                                media={medias[0]}
                                                post={post}
                                                profiles={[activeProfile]}
                                                isSmall
                                              />
                                            )}

                                            {type === VIDEO && (
                                              <VideoWithFallback
                                                id={`${POST_MEDIA_CLASS_NAME}-${media_gid}`}
                                                url={thumbnail_url || thumbnail_url_small ? null : url}
                                                controls
                                                width="100%"
                                                style={{ position: 'relative', borderRadius: radius.l }}
                                                height="100%"
                                                config={{
                                                  file: {
                                                    attributes: {
                                                      poster: thumbnail_url_small || thumbnail_url || '',
                                                    },
                                                  },
                                                }}
                                                fallbackSourceImage={thumbnail_url_small || thumbnail_url}
                                              />
                                            )}
                                          </Fragment>
                                        )}
                                      </Flex>
                                      <Flex alignItems="center" mx="s" width="16px">
                                        {postComponentTypeTemplate}
                                      </Flex>
                                      {showPublishAtTime && (
                                        <StyledFlex
                                          alignItems="flex-start"
                                          onClick={() => {
                                            handleClickEditPost({ profile_gid, post, timezone })
                                          }}
                                          flexDirection="column"
                                        >
                                          <StyledText isRemoved={isRemoved}>
                                            <Text
                                              as="span"
                                              fontSize="xs"
                                              color={hasError ? 'error' : 'primaryText'}
                                              fontStyle={hasChanged ? 'italic' : 'normal'}
                                            >
                                              {showPublishAtTime}
                                            </Text>{' '}
                                            &nbsp;
                                            <Text
                                              as="span"
                                              fontSize="xs"
                                              color={hasError ? 'error' : 'primaryText'}
                                              fontStyle={hasChanged ? 'italic' : 'normal'}
                                            >
                                              {updatedTimeZone}
                                            </Text>
                                          </StyledText>
                                          {queueLabel && queueLabel.value && (
                                            <StyledBadge>{queueLabel.value}</StyledBadge>
                                          )}
                                        </StyledFlex>
                                      )}
                                    </StyledMediaWrapper>
                                    <Flex justifyContent="flex-end">
                                      {!isRemoved && (
                                        <Fragment>
                                          <StyledRoundedButton ml="s">
                                            <Icon.Drag
                                              fill={COLOR_CONSTANTS.COSMIC_ENERGY}
                                              width="13px"
                                              height="13px"
                                            />
                                          </StyledRoundedButton>
                                          <StyledRoundedButton
                                            mx="s"
                                            onClick={() => {
                                              handleClickEditPost({ profile_gid, post, timezone })
                                            }}
                                            hasError={hasError}
                                          >
                                            <Icon.Edit
                                              fill={hasError ? COLOR_CONSTANTS.WHITE : COLOR_CONSTANTS.COSMIC_ENERGY}
                                            />
                                          </StyledRoundedButton>
                                        </Fragment>
                                      )}

                                      <StyledRoundedButton
                                        onClick={() => {
                                          handleClickRemovePost({ profile_gid, post_gid })
                                        }}
                                      >
                                        {isRemoved ? <Icon.Sync /> : <Icon.Trash />}
                                      </StyledRoundedButton>
                                    </Flex>
                                  </Flex>
                                </Flex>
                              </StyledPostWrapper>
                            )}
                          </Draggable>
                        )
                      })}
                      {provided.placeholder}
                    </Flex>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </Flex>
        </Flex>
      </Scrollbars>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

PostsComponent.defaultProps = {
  activeProfile: null,
}

PostsComponent.propTypes = {
  postsByProfile: PropTypes.object.isRequired,
  setPostsByProfile: PropTypes.func.isRequired,
  handleClickEditPost: PropTypes.func.isRequired,
  handleClickRemovePost: PropTypes.func.isRequired,
  activeProfile: PropTypes.object,
  selectedQueueLabelByProfile: PropTypes.object.isRequired,
  setSelectedQueueLabelByProfile: PropTypes.func.isRequired,
  usedQueueLabelsByProfile: PropTypes.object.isRequired,
  featuresEnabled: PropTypes.object.isRequired,
}

export default PostsComponent
