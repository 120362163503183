import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { COLOR_CONSTANTS, radius, space, colors } from 'theme'
import { DEFAULT_SOCIAL_PROFILE_SOURCE } from 'consts'
import { pxToRem } from 'helpers'
import { Box, Flex } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
import { Text } from 'components/atoms/Typography'
import Checkbox from 'components/atoms/Checkbox'
import Icon from 'components/atoms/Icon'
import DropdownMenu from 'components/molecules/DropdownMenu'
import { convertPostToEvent, generateTextHeaderForDeletePostModal, setPostComponent } from 'routes/Calendar/helpers'
import {
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  STATUS_COLORS,
  REVIEW,
  REJECTED,
  APPROVED,
  SPECIAL_UI_STATUS_REVIEW_REJECTED,
  SPECIAL_UI_STATUS_PUBLISHING,
  POST_TYPE_SOCIAL_POST,
} from 'routes/Calendar/consts'
import { TABS } from './consts'
import PreviewComponent from '../PreviewModal/PreviewComponent'
import DeletePostModal from '../DeletePostModal'
import TabsComponent from './TabsComponent'

const PostWrapper = styled(Flex)`
  background-color: ${COLOR_CONSTANTS.WHITE};
  border-radius: ${radius.l};
`

const StyledStatusIndicator = styled(Box)`
  width: 4px;
  height: 44px;
  border-radius: ${radius.m} 0px 0px ${radius.m};
  position: absolute;
  right: 0px;
  top: ${space.m};
  z-index: 1;
`

const CheckBoxWrapper = styled(Box)`
  cursor: pointer;
`

const StyledApproveWrapper = styled(Flex)`
  &:hover {
    .approve-button {
      border: 1px solid ${colors.primary};
    }

    .approve-button-divider {
      border-top: 1px solid ${colors.primary};
      border-bottom: 1px solid ${colors.primary};
    }
  }
`

const StyledApproveButton = styled(ButtonWithLoading)`
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-right: none !important;
  background: linear-gradient(90deg, #2482fd 0%, #0b60da 100%);
  &:hover {
    border: none;
  }
`

const StyledApproveDropDownButton = styled(Button.Gradient)`
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-left: none !important;
  min-width: 32px;
  &:hover {
    border: none;
  }
`

const StyledIconArrowDropdown = styled(Icon.ArrowDropdown)`
  fill: ${({ fill }) => fill || COLOR_CONSTANTS.WHITE};
  transform: rotate(180deg);
`

const PostItem = ({
  user,
  posts,
  networks,
  publicationGroupId,
  handleClickEditPost,
  handleClickRejectPost,
  handleClickApprovePost,
  isPostBeingSubmitted,
  hasComponentControls,
  hasControls,
  isChecked,
  tooltipRef,
  handleClickSelectPost,
  calendarPageViewOptionsType,
  handleDeletePost,
  handleUpdatePostsAfterDelete,
  handleUpdateCalendarPostLabels,
  can_approve,
  handleUpdateCommentsCounter,
  sharedCalendarData,
  handleUpdatePostBoostedData,
  handleClickAddVistaSocialPostToAdvocacy,
  isSmallPreview,
}) => {
  const [activeTab, setActiveTab] = useState({
    network: '',
    displayNetwork: '',
    activePosts: [],
    notIncludedPosts: [],
    notIncludedPostsLength: 0,
    post: null,
  })
  const [isDeletePostModalOpen, setIsDeletePostModalOpen] = useState(false)
  const [postForDeleting, setPostForDeleting] = useState(null)
  const [isPostDeleting, setIsPostDeleting] = useState(false)

  const { post } = activeTab
  const { timezone, status, publish_at_day: { month_name = '', day_name = '', day = '' } = {}, type, advocacy_groups } =
    post || {}

  const maxProfilesForPreviewCustomizations = isSmallPreview ? 8 : MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  useEffect(() => {
    let network
    // eslint-disable-next-line prefer-destructuring
    for (let i = 0; i < TABS.length; i++) {
      const foundPost = posts.find(({ profile: { network } }) => network === TABS[i])
      if (foundPost) {
        network = TABS[i]
        break
      }
    }

    activeTab.network = network
    activeTab.activePosts = []
    activeTab.notIncludedPosts = []

    posts.forEach((post) => {
      const { profile: { network, display: displayNetwork = '' } = {}, title, link, start } = post

      post.postText = title
      post.profile.fallbackLogoImage = DEFAULT_SOCIAL_PROFILE_SOURCE
      post.publish_at = start

      if (link && link.active) {
        link.picture_url = link.picture_url || post.linkPicture
        if (link.title || link.description || link.picture_url) {
          link.hasLinkUrlInformation = true
          link.isLinkUrlCorrect = true
          link.isLinkUrlParsed = true
        }
      }

      setPostComponent({ data: post })

      if (network === activeTab.network) {
        if (activeTab.activePosts.length + 1 < maxProfilesForPreviewCustomizations) {
          activeTab.activePosts.push(post)
        } else {
          activeTab.notIncludedPosts.push(post)
        }
      }
    })
    activeTab.notIncludedPostsLength = activeTab.notIncludedPosts.length
    // eslint-disable-next-line prefer-destructuring
    activeTab.post = activeTab.activePosts[0]

    activeTab.displayNetwork = activeTab.post?.profile?.display || ''

    setActiveTab({ ...activeTab })
  }, [posts.length])

  const handleClickChangeNetwork = ({ network }) => {
    const filtered = posts.filter(({ profile }) => profile.network === network)

    let activePosts = []
    let notIncludedPosts = []

    if (filtered.length > maxProfilesForPreviewCustomizations) {
      activePosts = filtered.slice(0, maxProfilesForPreviewCustomizations)
      notIncludedPosts = filtered.slice(maxProfilesForPreviewCustomizations)
    } else {
      activePosts = filtered
    }

    setActiveTab({
      ...{
        network,
        displayNetwork: activePosts[0]?.profile?.display || '',
        post: activePosts[0],
        activePosts,
        notIncludedPosts,
        notIncludedPostsLength: notIncludedPosts.length,
      },
    })
  }

  const handleClickChangePost = ({ post }) => {
    activeTab.post = post
    setActiveTab({ ...activeTab })
  }

  const handleClickOpenDeletePostModal = ({ post }) => {
    if (!isPostDeleting) {
      setPostForDeleting({ ...post })
      setIsDeletePostModalOpen(true)
    }
  }

  const handleClickCloseDeletePostModal = () => {
    setIsDeletePostModalOpen(false)
    setPostForDeleting(null)
  }

  const handleClickDeletePost = async (body) => {
    setIsPostDeleting(true)
    const { id: postId, type } = postForDeleting

    body.items = [{ id: postId, type }]
    const { success } = await handleDeletePost({ data: body, type: 'calendar_posts_view' })
    if (success) {
      setIsPostDeleting(false)
      handleUpdatePostsAfterDelete({ postId })
    }

    handleClickCloseDeletePostModal()
  }

  const handleClickDuplicatePost = ({ post }) => {
    if (handleClickEditPost) {
      const eventInfo = convertPostToEvent({ post })
      handleClickEditPost({ eventInfo, sendToCompose: true })
    }
  }

  const handleClickAddToAdvocacyPost = ({ post }) => {
    if (handleClickAddVistaSocialPostToAdvocacy) {
      handleClickAddVistaSocialPostToAdvocacy({ post })
    }
  }

  const handleClickOpenEvent = ({ post, useStatus }) => {
    if (handleClickEditPost) {
      const eventInfo = convertPostToEvent({ post })
      handleClickEditPost({ eventInfo, useStatus })
    }
  }

  return (
    <Flex maxWidth="900px" width="100%" justifyContent="center">
      {(handleClickSelectPost || month_name || day || !isSmallPreview) && (
        <Flex flexDirection="column" width="70px" alignItems="center" mt="l">
          <Text fontSize="l" fontWeight="bold" color="secondaryText">
            {month_name}
          </Text>
          <Text fontSize="xxl" fontWeight="bold" color="secondaryText">
            {day}
          </Text>
          {handleClickSelectPost && (
            <Flex width="15px" height="15px" mt="m">
              <CheckBoxWrapper mt="s">
                <Checkbox
                  isChecked={isChecked}
                  onClick={() => {
                    handleClickSelectPost({ publicationGroupId })
                  }}
                />
              </CheckBoxWrapper>
            </Flex>
          )}
        </Flex>
      )}
      <PostWrapper px="m" m="m" flexDirection="column" maxWidth="542px" width="100%" position="relative">
        {isSmallPreview && (
          <Flex flexDirection="column" alignItems="center" justifyContent="center" mt="m" width="100%">
            <TabsComponent
              handleClickChangePost={handleClickChangePost}
              isSmallPreview={isSmallPreview}
              networks={networks}
              handleClickChangeNetwork={handleClickChangeNetwork}
              activeTab={activeTab}
              tooltipRef={tooltipRef}
            />
          </Flex>
        )}

        <StyledStatusIndicator bg={status ? STATUS_COLORS[status] : 'transparent'} />
        {post && (
          <Flex mt="s" flexDirection="column" width="100%" name={post.id}>
            <PreviewComponent
              user={user}
              postForEdit={post}
              timezone={timezone}
              handleClickOpenDeletePostModal={handleClickOpenDeletePostModal}
              handleClickDuplicatePost={handleClickEditPost ? handleClickDuplicatePost : null}
              handleClickOpenEvent={handleClickEditPost ? handleClickOpenEvent : null}
              handleUpdateCalendarPostLabels={handleUpdateCalendarPostLabels}
              showHeader={false}
              hasComponentControls={hasComponentControls}
              handleUpdateCommentsCounter={handleUpdateCommentsCounter}
              sharedCalendarData={sharedCalendarData}
              posts={posts}
              handleUpdatePostBoostedData={handleUpdatePostBoostedData}
              isSmallPreview={isSmallPreview}
            />
            {hasControls &&
              (handleClickEditPost ||
                (calendarPageViewOptionsType === REVIEW && can_approve) ||
                post?.result?.published_link) && (
                <Flex justifyContent="flex-end" mb="m">
                  {type !== POST_TYPE_SOCIAL_POST && !sharedCalendarData && (
                    <Fragment>
                      {advocacy_groups && advocacy_groups.length !== 0 ? (
                        <Button.Gray
                          isSmall
                          onClick={() => {
                            window.open(`/advocacy/${advocacy_groups[0].advocacy_gid}/content`, '_self')
                          }}
                          mr="m"
                        >
                          View in advocacy
                        </Button.Gray>
                      ) : (
                        <Fragment>
                          {handleClickAddVistaSocialPostToAdvocacy && (
                            <Button.Gray
                              isSmall
                              onClick={() => {
                                handleClickAddToAdvocacyPost({ post })
                              }}
                              mr="m"
                            >
                              Add to advocacy
                            </Button.Gray>
                          )}
                        </Fragment>
                      )}
                    </Fragment>
                  )}

                  {handleClickEditPost && (
                    <Fragment>
                      {calendarPageViewOptionsType === APPROVED && posts.length !== 1 && (
                        <Button.Gray
                          mr="m"
                          isSmall
                          onClick={() => {
                            handleClickOpenEvent({ post, useStatus: SPECIAL_UI_STATUS_PUBLISHING })
                          }}
                        >
                          View posts group
                        </Button.Gray>
                      )}

                      <Button.Gray
                        isSmall
                        onClick={() => {
                          handleClickOpenEvent({
                            post,
                            useStatus:
                              calendarPageViewOptionsType === REVIEW || calendarPageViewOptionsType === REJECTED
                                ? SPECIAL_UI_STATUS_REVIEW_REJECTED
                                : null,
                          })
                        }}
                      >
                        View post
                      </Button.Gray>
                    </Fragment>
                  )}

                  {post?.result?.published_link && (
                    <Button.Gray
                      ml="m"
                      isSmall
                      onClick={() => {
                        window.open(post?.result?.published_link)
                      }}
                    >
                      View on {activeTab.displayNetwork || 'network'}
                    </Button.Gray>
                  )}

                  {calendarPageViewOptionsType === REVIEW && can_approve && (
                    <Fragment>
                      <ButtonWithLoading
                        buttonComp={Button.Error}
                        loaderColor={COLOR_CONSTANTS.SAPPHIRE}
                        ml="m"
                        isSmall={!isSmallPreview}
                        onClick={(e) => {
                          handleClickRejectPost({ publication_group_id: publicationGroupId, addComment: true })
                          e.currentTarget.blur()
                        }}
                        isLoading={isPostBeingSubmitted && !isPostBeingSubmitted.isApprove}
                      >
                        {isPostBeingSubmitted && !isPostBeingSubmitted.isApprove ? 'Rejecting' : 'Reject'}
                      </ButtonWithLoading>

                      <StyledApproveWrapper ml="m">
                        <StyledApproveButton
                          isSmall={!isSmallPreview}
                          onClick={(e) => {
                            handleClickApprovePost({ publication_group_id: publicationGroupId })
                            e.currentTarget.blur()
                          }}
                          className="approve-button"
                          isLoading={isPostBeingSubmitted && isPostBeingSubmitted.isApprove}
                        >
                          {isPostBeingSubmitted && isPostBeingSubmitted.isApprove ? 'Approving' : 'Approve'}
                        </StyledApproveButton>

                        <Flex
                          height={!isSmallPreview ? pxToRem(30) : pxToRem(40)}
                          width="2px"
                          bg={COLOR_CONSTANTS.WHITE}
                          className="approve-button-divider"
                        />
                        <DropdownMenu
                          zIndex={16}
                          top="-105px"
                          WrapperComp={
                            <StyledApproveDropDownButton isSmall className="approve-button">
                              <Flex alignItems="center">
                                <StyledIconArrowDropdown />
                              </Flex>
                            </StyledApproveDropDownButton>
                          }
                          isDismissedOnClickInside
                          isTriangleVisible={false}
                        >
                          <DropdownMenu.Item
                            labelMaxWidth="230px"
                            label="Approve"
                            labelFontWeight="medium"
                            onClick={(e) => {
                              handleClickApprovePost({ publication_group_id: publicationGroupId })
                              e.currentTarget.blur()
                            }}
                            labelDescription=""
                            isCursorPointer
                          />
                          <DropdownMenu.Item
                            labelMaxWidth="230px"
                            label="Approve with comments"
                            labelFontWeight="medium"
                            onClick={(e) => {
                              handleClickApprovePost({ publication_group_id: publicationGroupId, addComment: true })
                              e.currentTarget.blur()
                            }}
                            labelDescription=""
                            isCursorPointer
                          />
                        </DropdownMenu>
                      </StyledApproveWrapper>
                    </Fragment>
                  )}
                </Flex>
              )}
          </Flex>
        )}
      </PostWrapper>
      {!isSmallPreview && (
        <Flex flexDirection="column" mt="l" width="300px">
          <TabsComponent
            handleClickChangePost={handleClickChangePost}
            isSmallPreview={isSmallPreview}
            networks={networks}
            handleClickChangeNetwork={handleClickChangeNetwork}
            activeTab={activeTab}
            tooltipRef={tooltipRef}
          />
        </Flex>
      )}

      {isDeletePostModalOpen && (
        <DeletePostModal
          handleDismiss={handleClickCloseDeletePostModal}
          isOpen={isDeletePostModalOpen}
          isPostSubmitting={isPostDeleting}
          handleDelete={handleClickDeletePost}
          headerText={generateTextHeaderForDeletePostModal({ post })}
        />
      )}
    </Flex>
  )
}

PostItem.defaultProps = {
  posts: [],
  networks: [],
  hasControls: true,
  hasComponentControls: true,
  handleClickSelectPost: null,
  calendarPageViewOptionsType: '',
  handleClickEditPost: null,
  handleDeletePost: () => {},
  handleUpdatePostsAfterDelete: () => {},
  handleUpdateCalendarPostLabels: () => {},
  isChecked: false,
  can_approve: false,
  handleUpdateCommentsCounter: () => {},
  sharedCalendarData: null,
  isPostBeingSubmitted: null,
  handleUpdatePostBoostedData: () => {},
  handleClickAddVistaSocialPostToAdvocacy: null,
  isSmallPreview: false,
}

PostItem.propTypes = {
  user: PropTypes.object.isRequired,
  posts: PropTypes.array,
  networks: PropTypes.array,
  handleClickEditPost: PropTypes.func,
  handleClickRejectPost: PropTypes.func.isRequired,
  handleClickApprovePost: PropTypes.func.isRequired,
  publicationGroupId: PropTypes.string.isRequired,
  isPostBeingSubmitted: PropTypes.object,
  hasControls: PropTypes.bool,
  hasComponentControls: PropTypes.bool,
  tooltipRef: PropTypes.oneOfType([PropTypes.func, PropTypes.shape({ current: PropTypes.any })]).isRequired,
  isChecked: PropTypes.bool,
  handleClickSelectPost: PropTypes.func,
  calendarPageViewOptionsType: PropTypes.string,
  handleDeletePost: PropTypes.func,
  handleUpdatePostsAfterDelete: PropTypes.func,
  handleUpdateCalendarPostLabels: PropTypes.func,
  can_approve: PropTypes.bool,
  handleUpdateCommentsCounter: PropTypes.func,
  sharedCalendarData: PropTypes.object,
  handleUpdatePostBoostedData: PropTypes.func,
  handleClickAddVistaSocialPostToAdvocacy: PropTypes.func,
  isSmallPreview: PropTypes.bool,
}

export default PostItem
