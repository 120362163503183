import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { Flex } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'

const MessageWrapper = styled(Flex)`
  background-color: ${({ color }) => transparentize(0.9, color || COLOR_CONSTANTS.FLUORESCENT_RED)};
  border-radius: ${radius.l};
  a {
    color: ${colors.primary};
    text-decoration: none;
  }
`

const PreviewPromptComponent = ({ text, isShown, type, specialColor }) => {
  let color = colors.error

  if (type === 'info') {
    color = colors.primary
  } else if (type === 'warning') {
    color = colors.warning
  } else if (specialColor) {
    color = specialColor
  }

  return (
    <Fragment>
      {isShown && text && (
        <MessageWrapper alignItems="center" px="m" py="s" mb="m" color={color}>
          <Text color={color} dangerouslySetInnerHTML={{ __html: text }} />
        </MessageWrapper>
      )}
    </Fragment>
  )
}

PreviewPromptComponent.defaultProps = {
  text: '',
  type: 'error',
  specialColor: '',
}

PreviewPromptComponent.propTypes = {
  text: PropTypes.string,
  isShown: PropTypes.bool.isRequired,
  type: PropTypes.string,
  specialColor: PropTypes.string,
}

export default PreviewPromptComponent
