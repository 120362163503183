import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import Alert from 'react-s-alert'
import { DEFAULT_TIME_ZONE, ERROR_MESSAGE, INSTAGRAM, TIKTOK } from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
// eslint-disable-next-line import/no-cycle
import {
  getEntities,
  handleSubmitPostsForCalendar,
  saveSelectedEntitiesToStorage,
  saveSelectedProfilesToStorage,
  getDataForCustomizations,
  getEvents,
  removePublicationDataFromLocalStorage,
  handleSendToCompose,
} from '../helpers'
import {
  CALENDAR_POST_PREVIEW_DETAILED_LARGE_PREVIEW,
  LOCAL_STORAGE_BULK_POST_MODAL,
  LOCAL_STORAGE_EDIT_POST_MODAL,
  LOCAL_STORAGE_PUBLISH_POST_MODAL,
  ROUTE_PUBLISH,
} from '../consts'
// eslint-disable-next-line import/no-cycle
import { getCalendarStartDay } from '../calendarRenderHelper'
// eslint-disable-next-line import/no-cycle
import FindContentModal from './FindContentModal'
// eslint-disable-next-line import/no-cycle
import PreviewModal from './PreviewModal'
// eslint-disable-next-line import/no-cycle
import PublishModal from './PublishModal'
// eslint-disable-next-line import/no-cycle
import BulkModal from './BulkModal'
// eslint-disable-next-line import/no-cycle
import NetworkPlannerModal from './NetworkPlannerModal'
// eslint-disable-next-line import/no-cycle
import EditPostModal from './EditPostModal'
// eslint-disable-next-line import/no-cycle
import SliderPostsPreview from './SliderPostsPreview'
// eslint-disable-next-line import/no-cycle
import NoteModal from './NoteModal'
// eslint-disable-next-line import/no-cycle
import TwitterThreadMakerModal from './TwitterThreadMakerModal'
import AddVistaSocialPostToAdvocacy from './AddVistaSocialPostToAdvocacy'

const DEFAULT_PUBLISH_MODAL_OPTIONS = {
  isOpen: false,
  isPostSubmitting: false,
  selectedCalendarDate: null,
  selectedEntitiesForSelectedPost: null,
  selectedProfilesForSelectedPost: null,
  selectedPost: null,
}

const DEFAULT_BULK_MODAL_OPTIONS = {
  isOpen: false,
}

const DEFAULT_FIND_CONTENT_MODAL_OPTIONS = {
  isOpen: false,
  menuItemType: null,
  mediaType: null,
}

const DEFAULT_NETWORK_PLANNER_MODAL_OPTIONS = {
  isOpen: false,
  network: '',
}

const DEFAULT_PREVIEW_MODAL_OPTIONS = {
  isOpen: false,
  selectedEntitiesForSelectedPost: null,
  selectedProfilesForSelectedPost: null,
  selectedPost: {},
  isPostDeleting: false,
}

const DEFAULT_EDIT_MODAL_OPTIONS = {
  isOpen: false,
  isPostUpdating: false,
  selectedCalendarDate: null,
  selectedPost: {},
}

const DEFAULT_NOTE_MODAL_OPTIONS = {
  isOpen: false,
  data: null,
}

const DEFAULT_TWITTER_THREAD_MAKER_MODAL_OPTIONS = {
  isOpen: false,
  data: null,
}

const DEFAULT_ADD_VISTA_SOCIAL_POST_TO_ADVOCACY_MODAL_OPTIONS = {
  isOpen: false,
  post: null,
}

const PublisherWrapper = ({
  isPublisherWrapperOpen,
  handleClickClosePublisherWrapper,
  user,
  type,
  post,
  findContentMenuItemType,
  findContentMediaType,
  handleClickEvent,
  selectedEntitiesForSelectedPost,
  selectedProfilesForSelectedPost,
  selectedTimezone,
  handleModalSuccessCallback,
  setEntitySelectorKey,
  folderPath,
  ...props
}) => {
  const { settings: { post_preview, week_starts } = {} } = user

  const CALENDAR_POST_PREVIEW = post_preview || CALENDAR_POST_PREVIEW_DETAILED_LARGE_PREVIEW

  const CALENDAR_WEEK_DAY_START = getCalendarStartDay({ week_starts })

  const [publishModalOptions, setPublishModalOptions] = useState({ ...DEFAULT_PUBLISH_MODAL_OPTIONS })
  const [bulkModalOptions, setBulkModalOptions] = useState({ ...DEFAULT_BULK_MODAL_OPTIONS })
  const [findContentModalOptions, setFindContentModalOptions] = useState({ ...DEFAULT_FIND_CONTENT_MODAL_OPTIONS })
  const [networkPlannerModalOptions, setNetworkPlannerModalOptions] = useState({
    ...DEFAULT_NETWORK_PLANNER_MODAL_OPTIONS,
  })
  const [previewModalOptions, setPreviewModalOptions] = useState({ ...DEFAULT_PREVIEW_MODAL_OPTIONS })
  const [editModalOptions, setEditModalOptions] = useState({ ...DEFAULT_EDIT_MODAL_OPTIONS })
  const [sliderPostsPreviewData, setSliderPostsPreviewData] = useState(null)
  const [noteModalOptions, setNoteModalOptions] = useState({ ...DEFAULT_NOTE_MODAL_OPTIONS })
  const [twitterThreadMakerModalOptions, setTwitterThreadMakerModalOptions] = useState({
    ...DEFAULT_TWITTER_THREAD_MAKER_MODAL_OPTIONS,
  })
  const [addVistaSocialPostToAdvocacyModalOptions, setAddVistaSocialPostToAdvocacyModalOptions] = useState({
    ...DEFAULT_ADD_VISTA_SOCIAL_POST_TO_ADVOCACY_MODAL_OPTIONS,
  })

  const [entities, setEntities] = useState([])
  const [selectedEntities, setSelectedEntities] = useState([])
  const [selectedProfiles, setSelectedProfiles] = useState([])
  const [dataForCustomizations, setDataForCustomizations] = useState({})

  const handleSaveSelectedEntities = async ({ data }) => {
    if (props.handleSaveSelectedEntities) {
      props.handleSaveSelectedEntities({ data })
    } else {
      const { entitiesTemp } = saveSelectedEntitiesToStorage({ data, user })

      setSelectedEntities(entitiesTemp)
    }
  }

  const handleSaveSelectedProfiles = async ({ data }) => {
    if (props.handleSaveSelectedProfiles) {
      props.handleSaveSelectedProfiles({ data })
    } else {
      saveSelectedProfilesToStorage({ data, user })

      setSelectedProfiles(data)
    }
  }

  const getEntitiesData = async () => {
    if (!props.entities || !props.selectedProfiles || !props.selectedEntities) {
      const { entities } = await getEntities({
        user,
        setEntities,
        setEntitySelectorKey: () => {},
        setSelectedProfiles,
        setSelectedEntities,
        handleSaveSelectedEntities,
      })
      setEntities(entities)
    } else {
      setEntities(props.entities)
      setSelectedEntities(props.selectedEntities)
      setSelectedProfiles(props.selectedProfiles)
    }
  }

  const getAllData = async () => {
    await getEntitiesData()
    await getDataForCustomizations({ setDataForCustomizations })
    // eslint-disable-next-line no-use-before-define
    handleClickOpenModal()
  }

  useEffect(() => {
    if (isPublisherWrapperOpen) {
      getAllData()
    }
  }, [isPublisherWrapperOpen])

  const handleClickOpenModal = () => {
    if (sliderPostsPreviewData) {
      setSliderPostsPreviewData(null)
    }

    if (type === 'publish') {
      publishModalOptions.isOpen = true
      if (post) {
        publishModalOptions.selectedPost = post
        publishModalOptions.selectedProfilesForSelectedPost = selectedProfilesForSelectedPost
        publishModalOptions.selectedEntitiesForSelectedPost = selectedEntitiesForSelectedPost
      }

      if (props.entities) {
        publishModalOptions.entities = props.entities
      }

      setPublishModalOptions({ ...publishModalOptions })
    } else if (type === 'bulk') {
      bulkModalOptions.isOpen = true
      setBulkModalOptions({ ...bulkModalOptions })
    } else if (type === 'findContent') {
      findContentModalOptions.isOpen = true
      findContentModalOptions.menuItemType = findContentMenuItemType
      findContentModalOptions.mediaType = findContentMediaType
      setFindContentModalOptions({ ...findContentModalOptions })
    } else if (type === 'instagramPlanner') {
      setNetworkPlannerModalOptions({ ...{ isOpen: true, network: INSTAGRAM } })
    } else if (type === 'tiktokPlanner') {
      setNetworkPlannerModalOptions({ ...{ isOpen: true, network: TIKTOK } })
    } else if (type === 'preview') {
      previewModalOptions.isOpen = true
      previewModalOptions.selectedProfilesForSelectedPost = selectedProfilesForSelectedPost
      previewModalOptions.selectedEntitiesForSelectedPost = selectedEntitiesForSelectedPost
      previewModalOptions.selectedPost = post
      setPreviewModalOptions({ ...previewModalOptions })
    } else if (type === 'edit') {
      editModalOptions.isOpen = true
      editModalOptions.selectedPost = post
      setEditModalOptions({ ...editModalOptions })
    } else if (type === 'slider') {
      const data = {
        eventIds: post,
        specialEvents: [],
      }
      setSliderPostsPreviewData(data)
    } else if (type === 'note') {
      setNoteModalOptions({ ...{ isOpen: true, data: null } })
    } else if (type === 'twitterThreadMaker') {
      setTwitterThreadMakerModalOptions({ ...{ isOpen: true, data: null } })
    }
  }

  const handleClickClosePublishModal = (props) => {
    const { scheduleTypeData, getData = false } = props || {}

    setPublishModalOptions({ ...DEFAULT_PUBLISH_MODAL_OPTIONS })
    removePublicationDataFromLocalStorage({ key: LOCAL_STORAGE_PUBLISH_POST_MODAL })
    if (!scheduleTypeData) {
      handleClickClosePublisherWrapper({ getData })
    } else {
      setPublishModalOptions({ ...{ isOpen: true }, ...scheduleTypeData })
    }
  }

  const handleClickCloseBulkModal = (props) => {
    const { getData = false } = props || {}

    setBulkModalOptions({ ...DEFAULT_BULK_MODAL_OPTIONS })
    removePublicationDataFromLocalStorage({ key: LOCAL_STORAGE_BULK_POST_MODAL })
    handleClickClosePublisherWrapper({ getData })
  }

  const handleClickCloseFindContentModal = () => {
    setFindContentModalOptions({ ...DEFAULT_FIND_CONTENT_MODAL_OPTIONS })
    handleClickClosePublisherWrapper()
  }

  const handleClickCloseNetworkPlannerModal = () => {
    setNetworkPlannerModalOptions({ ...DEFAULT_NETWORK_PLANNER_MODAL_OPTIONS })
    handleClickClosePublisherWrapper()
  }

  const handleClickClosePreviewModal = (props) => {
    setPreviewModalOptions({ ...DEFAULT_PREVIEW_MODAL_OPTIONS })

    const { closePublisherWrapper = true } = props || {}
    if (closePublisherWrapper) {
      handleClickClosePublisherWrapper({ getData: true })
    }
  }

  const handleClickCloseEditPostModal = (props) => {
    setEditModalOptions({ ...DEFAULT_EDIT_MODAL_OPTIONS })
    removePublicationDataFromLocalStorage({ key: LOCAL_STORAGE_EDIT_POST_MODAL })

    const { closePublisherWrapper = true, getData = false } = props || {}
    if (closePublisherWrapper) {
      handleClickClosePublisherWrapper({ getData })
    }
  }

  const handleClickCloseNoteModal = () => {
    setNoteModalOptions({ ...DEFAULT_NOTE_MODAL_OPTIONS })
    handleClickClosePublisherWrapper()
  }

  const handleClickCloseTwitterThreadMakerModal = (props) => {
    setTwitterThreadMakerModalOptions({ ...DEFAULT_TWITTER_THREAD_MAKER_MODAL_OPTIONS })

    const { closePublisherWrapper = true, getData = false } = props || {}
    if (closePublisherWrapper) {
      handleClickClosePublisherWrapper({ getData })
    }
  }

  const handleClickAddVistaSocialPostToAdvocacy = ({ post }) => {
    setAddVistaSocialPostToAdvocacyModalOptions({ ...{ isOpen: true, post } })
  }

  const handleClickCloseAddVistaSocialPostToAdvocacy = (data) => {
    if (data) {
      handleModalSuccessCallback()
    }

    setAddVistaSocialPostToAdvocacyModalOptions({ ...DEFAULT_ADD_VISTA_SOCIAL_POST_TO_ADVOCACY_MODAL_OPTIONS })
  }

  const handleClickSendToCompose = ({ post, selectedEntitiesForSelectedPost, selectedProfilesForSelectedPost }) => {
    handleSendToCompose({
      publishModalOptions,
      setPublishModalOptions,
      post,
      selectedEntitiesForSelectedPost,
      selectedProfilesForSelectedPost,
    })
  }

  const handleSubmitPosts = async ({
    body,
    type,
    scheduleTypeData,
    loadingButton,
    cleaningGarbageModalFunction,
    ...selectedData
  }) => {
    let modalOptions
    let setModalOptions
    let handleClickCloseModal

    if (type === 'publish') {
      modalOptions = publishModalOptions
      setModalOptions = setPublishModalOptions
      handleClickCloseModal = ({ ...props }) => {
        cleaningGarbageModalFunction()
        handleClickClosePublishModal({ ...props, getData: true })
      }
    } else if (type === 'bulkPublish') {
      modalOptions = bulkModalOptions
      setModalOptions = setBulkModalOptions
      handleClickCloseModal = () => {
        handleClickCloseBulkModal({ getData: true })
      }
    }

    await handleSubmitPostsForCalendar({
      body,
      type,
      modalOptions,
      setModalOptions,
      handleClickCloseModal,
      selectedEntities,
      selectedProfiles,
      handleSaveSelectedEntities,
      handleSaveSelectedProfiles,
      setEntitySelectorKey,
      getPendingApproval: () => {},
      selectedData,
      scheduleTypeData,
      handleModalSuccessCallback,
      loadingButton,
    })
  }

  const getCalendarEvents = async ({
    profiles,
    calendarRef,
    setCalendarEvents,
    centralDate = '',
    selectedFilters = [],
    selectedTimezone = '',
    shouldCalendarShowUploadingCircle = true,
  }) => {
    await getEvents({
      user,
      profiles,
      calendarRef,
      setCalendarEvents,
      centralDate,
      selectedFilters,
      selectedTimezone,
      shouldCalendarShowUploadingCircle,
      setIsCalendarGettingEvents: () => {},
      calendarOptions: {},
      setCalendarOptions: () => {},
    })
  }

  const handleDeletePost = async ({ data, type }) => {
    let status = false
    try {
      if (type === 'edit') {
        editModalOptions.isPostUpdating = true
        setEditModalOptions({ ...editModalOptions })
      } else if (type === 'preview' || type === 'slider') {
        previewModalOptions.isPostDeleting = true
        setPreviewModalOptions({ ...previewModalOptions })
      } else if (type === 'publish') {
        publishModalOptions.isPostSubmitting = true
        setPublishModalOptions({ ...publishModalOptions })
      }

      const { message, items } = data

      const response = await request({
        method: 'DELETE',
        body: { message, items },
        path: ROUTE_PUBLISH,
      })

      const { error = '', errors = [] } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        if (type === 'edit') {
          editModalOptions.isPostUpdating = false
          setEditModalOptions({ ...editModalOptions })
        } else if (type === 'preview' || type === 'slider') {
          previewModalOptions.isPostDeleting = false
          setPreviewModalOptions({ ...previewModalOptions })
        } else if (type === 'publish') {
          publishModalOptions.isPostSubmitting = false
          setPublishModalOptions({ ...publishModalOptions })
        }
      } else if (errors.length !== 0) {
        Alert.error(`Can't remove the post. Please contact support.`, { timeout: 5000 })
      } else {
        Alert.success('The post has been removed.', { timeout: 5000 })
        status = true

        if (type === 'edit') {
          handleClickCloseEditPostModal({ getData: true })
        } else if (type === 'preview' || type === 'slider') {
          handleClickClosePreviewModal({ getData: true, closePublisherWrapper: true })
        } else if (type === 'publish') {
          handleClickClosePublishModal({ getData: true })
        }
      }
    } catch (error) {
      errorHelper({ error, componentName: PublisherWrapper.displayName, functionName: 'handleDeletePost' })
      if (type === 'edit') {
        editModalOptions.isPostUpdating = false
        setEditModalOptions({ ...editModalOptions })
      } else if (type === 'preview' || type === 'slider') {
        previewModalOptions.isPostDeleting = false
        setPreviewModalOptions({ ...previewModalOptions })
      }
    }
    return { success: status }
  }

  const handleUpdatePost = async ({ data }) => {
    try {
      editModalOptions.isPostUpdating = true
      setEditModalOptions({ ...editModalOptions })
      const response = await request({
        method: 'PUT',
        body: data,
        path: `${ROUTE_PUBLISH}/${data.id}`,
      })

      const { error = '' } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        editModalOptions.isPostUpdating = false
        setEditModalOptions({ ...editModalOptions })
      } else {
        Alert.success('The post has been updated', { timeout: 5000 })
        handleClickCloseEditPostModal({ getData: true })
      }
    } catch (error) {
      errorHelper({ error, componentName: PublisherWrapper.displayName, functionName: 'handleUpdatePost' })
      editModalOptions.isPostUpdating = false
      setEditModalOptions({ ...editModalOptions })
    }
  }

  const handleSetPostToDraft = async ({ id }) => {
    try {
      editModalOptions.isPostUpdating = true
      setEditModalOptions({ ...editModalOptions })
      const response = await request({
        method: 'PATCH',
        path: `${ROUTE_PUBLISH}/${id}/draft`,
      })

      const { error = '' } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        editModalOptions.isPostUpdating = false
        setEditModalOptions({ ...editModalOptions })
      } else {
        Alert.success('Post has been saved as draft.', { timeout: 5000 })
        handleClickCloseEditPostModal({ getData: true })
      }
    } catch (error) {
      errorHelper({ error, componentName: PublisherWrapper.displayName, functionName: 'handleSetPostToDraft' })
      editModalOptions.isPostUpdating = false
      setEditModalOptions({ ...editModalOptions })
    }
  }

  return (
    <Fragment>
      {publishModalOptions.isOpen && (
        <PublishModal
          user={user}
          isOpen={publishModalOptions.isOpen}
          handleDismiss={handleClickClosePublishModal}
          entities={publishModalOptions.entities || entities}
          handleSubmitPost={handleSubmitPosts}
          isPostSubmitting={publishModalOptions.isPostSubmitting}
          loadingButton={publishModalOptions.loadingButton}
          selectedCalendarDate={publishModalOptions.selectedCalendarDate}
          selectedEntities={publishModalOptions.selectedEntitiesForSelectedPost || selectedEntities}
          selectedProfiles={publishModalOptions.selectedProfilesForSelectedPost || selectedProfiles}
          getCalendarEvents={getCalendarEvents}
          dataForCustomizations={dataForCustomizations}
          postForEdit={publishModalOptions.selectedPost}
          handleUpdateEntities={() => {}}
          calendarPostPreview={CALENDAR_POST_PREVIEW}
          calendarFirstDay={CALENDAR_WEEK_DAY_START}
          handleDeletePost={() => {}}
        />
      )}

      {bulkModalOptions.isOpen && (
        <BulkModal
          user={user}
          isOpen={bulkModalOptions.isOpen}
          handleDismiss={handleClickCloseBulkModal}
          entities={entities}
          handleSubmitBulkPosts={handleSubmitPosts}
          selectedEntities={selectedEntities}
          selectedProfiles={selectedProfiles}
          dataForCustomizations={dataForCustomizations}
          handleUpdateEntities={() => {}}
        />
      )}

      {findContentModalOptions.isOpen && (
        <FindContentModal
          user={user}
          handleDismiss={handleClickCloseFindContentModal}
          isOpen={findContentModalOptions.isOpen}
          handleClickSendToCompose={handleClickSendToCompose}
          menuItemType={findContentModalOptions.menuItemType}
          mediaType={findContentModalOptions.mediaType}
          folderPath={folderPath}
        />
      )}

      {networkPlannerModalOptions.isOpen && (
        <NetworkPlannerModal
          user={user}
          isOpen={networkPlannerModalOptions.isOpen}
          handleDismiss={handleClickCloseNetworkPlannerModal}
          selectedProfiles={selectedProfiles}
          selectedEntities={selectedEntities}
          network={networkPlannerModalOptions.network}
        />
      )}

      {previewModalOptions.isOpen && (
        <PreviewModal
          user={user}
          postForEdit={previewModalOptions.selectedPost}
          selectedEntities={previewModalOptions.selectedEntitiesForSelectedPost || selectedEntities}
          selectedProfiles={previewModalOptions.selectedProfilesForSelectedPost || selectedProfiles}
          handleDismiss={handleClickClosePreviewModal}
          isOpen={previewModalOptions.isOpen}
          handleClickSendToCompose={handleClickSendToCompose}
          handleDeletePost={handleDeletePost}
          isPostDeleting={previewModalOptions.isPostDeleting}
          timezone={selectedTimezone}
          handleUpdateCalendarPostLabels={() => {}}
          handleClickEvent={handleClickEvent}
          handleClickAddVistaSocialPostToAdvocacy={handleClickAddVistaSocialPostToAdvocacy}
        />
      )}

      {editModalOptions.isOpen && (
        <EditPostModal
          user={user}
          isOpen={editModalOptions.isOpen}
          handleDismiss={handleClickCloseEditPostModal}
          handleUpdatePost={handleUpdatePost}
          selectedProfiles={editModalOptions.selectedPost.selectedProfilesForSelectedPost || selectedProfiles}
          postForEdit={editModalOptions.selectedPost}
          isPostSubmitting={editModalOptions.isPostUpdating}
          handleDeletePost={handleDeletePost}
          handleSetPostToDraft={handleSetPostToDraft}
          dataForCustomizations={dataForCustomizations}
          handleClickSendToCompose={handleClickSendToCompose}
          handleClickOpenPublishModal={({ post, selectedEntitiesForSelectedPost, selectedProfilesForSelectedPost }) => {
            if (post.publication_group_data) {
              post.postText = post.publication_group_data.postText
              post.customizations = post.publication_group_data.customizations
            }
            publishModalOptions.selectedPost = post
            publishModalOptions.isOpen = true
            publishModalOptions.selectedEntitiesForSelectedPost = selectedEntitiesForSelectedPost
            publishModalOptions.selectedProfilesForSelectedPost = selectedProfilesForSelectedPost
            setPublishModalOptions({ ...publishModalOptions })
            handleClickCloseEditPostModal()
          }}
          handleClickEvent={handleClickEvent}
        />
      )}

      {sliderPostsPreviewData && (
        <SliderPostsPreview
          user={user}
          isOpen={!!sliderPostsPreviewData}
          data={sliderPostsPreviewData || {}}
          setSliderPostsPreviewData={(data) => {
            setSliderPostsPreviewData(data)
            handleClickClosePublisherWrapper()
          }}
          handleClickEvent={handleClickEvent}
          timezone={selectedTimezone}
          handleDeletePost={handleDeletePost}
          isPostDeleting={previewModalOptions.isPostDeleting}
          handleUpdateCalendarPostLabels={() => {}}
        />
      )}

      {noteModalOptions.isOpen && (
        <NoteModal
          user={user}
          isOpen={noteModalOptions.isOpen}
          handleDismiss={handleClickCloseNoteModal}
          data={noteModalOptions.data}
        />
      )}

      {twitterThreadMakerModalOptions.isOpen && (
        <TwitterThreadMakerModal
          user={user}
          isOpen={twitterThreadMakerModalOptions.isOpen}
          handleDismiss={handleClickCloseTwitterThreadMakerModal}
          handleClickCreatePost={({ post, selectedEntitiesForSelectedPost, selectedProfilesForSelectedPost }) => {
            publishModalOptions.selectedPost = post
            publishModalOptions.isOpen = true
            publishModalOptions.selectedEntitiesForSelectedPost = selectedEntitiesForSelectedPost
            publishModalOptions.selectedProfilesForSelectedPost = selectedProfilesForSelectedPost
            setPublishModalOptions({ ...publishModalOptions })
            handleClickCloseTwitterThreadMakerModal({ closePublisherWrapper: false })
          }}
        />
      )}

      {addVistaSocialPostToAdvocacyModalOptions.isOpen && (
        <AddVistaSocialPostToAdvocacy
          isOpen={addVistaSocialPostToAdvocacyModalOptions.isOpen}
          handleDismiss={handleClickCloseAddVistaSocialPostToAdvocacy}
          postForEdit={addVistaSocialPostToAdvocacyModalOptions.post}
        />
      )}
    </Fragment>
  )
}

PublisherWrapper.defaultProps = {
  type: 'publish',
  post: null,
  findContentMenuItemType: null,
  findContentMediaType: null,
  handleClickEvent: () => {},
  selectedTimezone: DEFAULT_TIME_ZONE,
  selectedEntitiesForSelectedPost: null,
  selectedProfilesForSelectedPost: null,
  handleModalSuccessCallback: () => {},
  setEntitySelectorKey: () => {},
  handleSaveSelectedProfiles: null,
  handleSaveSelectedEntities: null,
  entities: null,
  selectedProfiles: null,
  selectedEntities: null,
  folderPath: null,
}

PublisherWrapper.propTypes = {
  isPublisherWrapperOpen: PropTypes.bool.isRequired,
  handleClickClosePublisherWrapper: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  type: PropTypes.string,
  post: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  findContentMenuItemType: PropTypes.string,
  findContentMediaType: PropTypes.string,
  handleClickEvent: PropTypes.func,
  selectedEntitiesForSelectedPost: PropTypes.array,
  selectedProfilesForSelectedPost: PropTypes.array,
  selectedTimezone: PropTypes.string,
  handleModalSuccessCallback: PropTypes.func,
  setEntitySelectorKey: PropTypes.func,
  handleSaveSelectedProfiles: PropTypes.func,
  handleSaveSelectedEntities: PropTypes.func,
  entities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  selectedEntities: PropTypes.array,
  folderPath: PropTypes.array,
}

PublisherWrapper.displayName = 'PublisherWrapper'

export default PublisherWrapper
