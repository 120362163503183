import React, { Fragment, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { formatToNumberWithCommas, getFileIconByExtension, linkTransformer } from 'helpers'
import { radius, COLOR_CONSTANTS, space, fontWeights, colors } from 'theme'
import {
  DEFAULT_VIDEO_THUMBNAIL,
  ERROR_MESSAGE,
  LINKEDIN,
  IMAGE,
  IMAGE_DYNAMIC,
  VIDEO_DYNAMIC,
  VIDEO,
  FEATURE_POST_ENGAGEMENT,
  FEATURE_POST_TARGETING,
} from 'consts'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Image from 'components/atoms/Image'
import Switch from 'components/atoms/Switch'
import Counter from 'components/molecules/Counter'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import DynamicMedia from 'components/molecules/DynamicMedia'
import DropDown from 'shared/DropDown'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import {
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_LINK,
  POST_VIDEO,
  POST_DOCUMENT,
  POST_MIXED_MEDIA,
  LINKEDIN_COLORS,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  SAVED_SEARCH_TYPE_LINKEDIN_LOCATION,
  PREVIEW_MEDIA_CLASS_NAME,
  PUBLISHED,
} from '../../consts'
// eslint-disable-next-line import/no-cycle
import {
  fitMediaProportionsToBox,
  getNetworkSavedSearches,
  mentionsTransformer,
  setSavedSearches,
  transformTextVariantsToUnicodeVariant,
  variableTransformer,
} from '../../helpers'
import ImagesGridHorizontal from './components/ImagesGridHorizontal'
import SocialProfileImage from '../SocialProfileImage'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
// eslint-disable-next-line import/no-cycle
import CommentsComponent from './components/CommentsComponent'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import PublicationOptionComponent from './components/PublicationOptionComponent'
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'
import BoostsDropdownComponent from './components/BoostsDropdownComponent'
import ImagesGridVertical from './components/ImagesGridVertical'

const StyledFlex = styled(Flex)`
  border-radius: ${radius.m};
  border: 1px solid ${COLOR_CONSTANTS.WAYWARD_WIND};
  box-shadow: 0 0 0 1px rgb(0 0 0 / 10%), 0 2px 3px rgb(0 0 0 / 20%);
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.l};
  object-fit: contain;
`

const SourceWrapper = styled(Flex)`
  min-height: ${({ minimalHeight }) => minimalHeight || '15.625em'};
  border-color: rgb(204, 214, 221);
  position: relative;
  overflow: hidden;
  align-items: center;
  justify-content: center;
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  a {
    color: ${LINKEDIN_COLORS.LAST_LIGHT_BLUE};
    font-weight: 500;
  }
`

const StyledVideoThumbnailImage = styled(ImageWithFallback)`
  object-fit: scale-down;
`

const StyledFlexStatistics = styled(Flex)`
  border-bottom: 1px solid #e6e9ec;
`

const StyledCommentWrapper = styled(Flex)`
  padding: ${space.s} ${space.xl} ${space.s} 12px;
  background-color: #f2f2f2;
  border-radius: 0 0 ${space.s} ${space.s};
  width: 100%;
  white-space: pre-wrap;
`

const StyledLabel = styled(Flex)`
  font-weight: ${fontWeights.bold};
  font-size: 0.75em;
  background: #56687a;
  border-radius: ${space.xxs};
  box-sizing: border-box;
  cursor: default;
  display: inline-block;
  padding: 0 0.25em;
  position: relative;
  color: ${COLOR_CONSTANTS.WHITE};
`

const StyledBox = styled(Flex)`
  cursor: pointer;
  ${({ hasError }) => hasError && `border: 3px solid ${colors.error};`}
`

const StyledPlayImage = styled(Image)`
  filter: drop-shadow(2px 2px 2px rgb(0 0 0 / 0.2));
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: rgb(145 158 171 / 30%) 0px 0px 4px;
  z-index: 2;
`

const StyledVideoSideLines = styled(Flex)`
  filter: blur(20px);
  opacity: 0.6;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transform: scale(1.1);
  position: absolute;
  z-index: 0;
  background-image: ${({ backgroundImage }) => (backgroundImage ? `url("${backgroundImage}")` : COLOR_CONSTANTS.BLACK)};
  width: 100%;
  height: 100%;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  background: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  cursor: pointer;
`

const StyledVideoTitleText = styled(Text)`
  display: block;
  line-height: 1.25em;
  max-height: 2.5em;
  overflow: hidden;
  margin-bottom: 0.125em;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
  flex-grow: 1;
  font-weight: ${fontWeights.medium};
  color: ${LINKEDIN_COLORS.BLACK};
`

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  commentTextMaxLimit: MAX_COMMENT_LENGTH,
  maxImages: MAX_IMAGES,
  minVideoDuration: MIN_VIDEO_DURATION,
  maxVideoDuration: MAX_VIDEO_DURATION,
  minImageWidth: MIN_IMAGE_WIDTH,
  minImageHeight: MIN_IMAGE_HEIGHT,
  maxImageWidth: MAX_IMAGE_WIDTH,
  maxImageHeight: MAX_IMAGE_HEIGHT,
  maxImageSizeString: MAX_IMAGE_SIZE_TEXT,
  videoExtensions: VIDEO_EXTENSIONS,
  maxVideoSizeString: MAX_VIDEO_SIZE_TEXT,
  maxVideos: MAX_VIDEOS,
  maxDocuments: MAX_DOCUMENTS,
  maxDocumentSizeString: MAX_DOCUMENT_SIZE_TEXT,
  documentExtensions: DOCUMENT_EXTENSIONS,
  hasMobileNotificationOption,
} = NETWORK_LIMITS.find(({ code }) => code === LINKEDIN)

const videoExtensionText = `${VIDEO_EXTENSIONS.slice(0, VIDEO_EXTENSIONS.length - 1).join(', ')} and ${
  VIDEO_EXTENSIONS[VIDEO_EXTENSIONS.length - 1]
}`

const documentExtensionText = `${DOCUMENT_EXTENSIONS.slice(0, DOCUMENT_EXTENSIONS.length - 1).join(', ')} and ${
  DOCUMENT_EXTENSIONS[DOCUMENT_EXTENSIONS.length - 1]
}`

const mapArrayToReactSelect = (arr) => arr.map(({ code, name }) => ({ value: code || name, label: name }))

const LinkedinPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  setDataForCustomizations,
  isPostFormDirty,
  postErrors,
  isEditable,
  noVideoAttachment,
  hasPreview,
  featuresEnabled,
  previewProfileNumber,
  selectedEntities,
  selectedProfiles,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  boosts,
  blockCommentsMediaAttachment,
  isSmallPreview,
  isAdvocacy,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_PREVIEW_WIDTH = smallPreview ? 250 : 510
  const MAX_PREVIEW_HEIGHT = smallPreview ? 250 : 510

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  const { customizations = {}, insights = {} } = post
  const { network, profileId } = profile
  let geoTargeting = false
  let languageTargeting = false

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    const { geoLocations = [], languages = [] } = profileCustomizations
    if (geoLocations.length !== 0) {
      geoTargeting = true
    }
    if (languages.length !== 0) {
      languageTargeting = true
    }
  }

  const [agencyMentions, setAgencyMentions] = useState([])
  const [customizationsSchema, setCustomizationsSchema] = useState({ geoTargeting, languageTargeting })
  const [textVariantOffsets, setTextVariantOffsets] = useState([])

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
    if (isEditable) {
      getNetworkSavedSearches({
        id: profileId,
        dataForCustomizations,
        setDataForCustomizations,
        type: SAVED_SEARCH_TYPE_LINKEDIN_LOCATION,
      })
    }
  }, [props.profile])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  const {
    publish_at,
    postText,
    postComponent,
    postImages = [],
    postVideos = [],
    postDocuments = [],
    postMixedMedias = [],
    link,
    result,
    zoneShort,
    status,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  let updatedPostText = postText
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostDocuments = postDocuments
  let customizedPostMixedMedias = postMixedMedias
  let geoLocations = []
  let languages = []
  let comments = []
  let like = false
  let publish_as_image = false
  let device_gid = null
  let publishing_option = null
  let boost_gid = null

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText
    if (!isEditable && updatedPostText) {
      const regex = /(@\[[A-Za-z\- 0-9]+\]\(urn:li:[:a-zA-Z0-9]+\))/g
      let found = updatedPostText.match(regex)

      while (found && found.length > 0) {
        for (const item of found) {
          const mentioned = `<span style="color: rgb(0, 115, 177); cursor: pointer;">${item.substring(
            2,
            item.indexOf(']')
          )}</span>`
          updatedPostText = updatedPostText.replace(item, mentioned)
        }
        found = updatedPostText.match(regex)
      }
    }
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostDocuments = profileCustomizations.postDocuments || postDocuments
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      geoLocations = [],
      languages = [],
      comments = [],
      like = false,
      publish_as_image = false,
      device_gid = null,
      publishing_option = null,
      boost_gid = null,
    } = profileCustomizations)
  }

  const {
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    profileUrl,
    profileUsername,
    display,
    profile_id,
    type: profile_type,
  } = profile

  const { url, title, picture_url, id: linkId } = link || {}
  const { published_link } = result || {}
  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      postImagesLengthLimit,
      postVideosLengthLimit,
      postMixedMediasLengthLimit,
      noContent,
      postLinkPictureUrlLengthLimit,
      commentTextMaxLimit,
      postTextMaxLimit,
      postImageResolutionLimit,
      postImageSizeLimit,
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postVideoAspectRatioLimit,
      notificationDeviceLengthLimit,
      notFoundVariables,
      postDocumentSizeLimit,
      postDocumentExtensionError,
      postDocumentDescriptionLengthLimit,
      postDocumentTitleLengthLimit,
    } = {},
  } = profilesWithErrors[profileId] || {}

  const postImagesUpdated =
    customizedPostComponent === POST_IMAGE ? customizedPostImages.filter(({ tempId }) => !tempId) : []

  const { 0: { url: videoUrl, thumbnail_url, type: videoType } = {} } = customizedPostVideos

  const { 0: { isNew: isDocumentNew, id: documentId, extension: documentExtension } = {} } = customizedPostDocuments

  let postMixedMediasUpdated = []

  if (customizedPostComponent === POST_MIXED_MEDIA) {
    const filtered_medias = customizedPostMixedMedias.filter(({ tempId }) => !tempId)

    if (filtered_medias.length !== 0) {
      if (filtered_medias[0].type === IMAGE || filtered_medias[0].type === IMAGE_DYNAMIC) {
        postMixedMediasUpdated = filtered_medias.filter(({ type }) => type === IMAGE || type === IMAGE_DYNAMIC)
      } else if (filtered_medias[0].type === VIDEO || filtered_medias[0].type === VIDEO_DYNAMIC) {
        postMixedMediasUpdated = filtered_medias.filter(({ type }) => type === VIDEO || type === VIDEO_DYNAMIC)
        //  postMixedMediasUpdated = [filtered_medias[0]]
      }
    }
  }

  let previewPromptMessage = {
    text: '',
    isHidden: true,
    type: 'error',
  }

  if (hasErrors) {
    if (postImagesLengthLimit || postVideosLengthLimit || postMixedMediasLengthLimit || noContent) {
      previewPromptMessage.text = `Post must have a message or at least one attached media.`
    } else if (postLinkPictureUrlLengthLimit) {
      previewPromptMessage.text = `LinkedIn requires an image when posting a link`
    } else if (postTextMaxLimit) {
      previewPromptMessage.text =
        'Your post text is too long. You can customize it for this network by clicking on the post preview'
    } else if (commentTextMaxLimit) {
      previewPromptMessage.text = `Your post comment is too long`
    } else if (postImageResolutionLimit) {
      previewPromptMessage.text = `Images must be at least ${MIN_IMAGE_WIDTH} x ${MIN_IMAGE_HEIGHT} and no larger than ${MAX_IMAGE_WIDTH} x ${MAX_IMAGE_HEIGHT} pixels`
    } else if (postVideoDurationLimit) {
      previewPromptMessage.text = `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds`
    } else if (postVideoExtensionError) {
      let extension = ''
      const foundVideo = customizedPostVideos.find(({ id }) => id === postVideoExtensionError)
      if (foundVideo) {
        ;({ extension } = foundVideo)
      }
      previewPromptMessage.text = `${
        extension ? `Current video is in .${extension} format.` : ''
      } LinkedIn only supports ${videoExtensionText} format.`
    } else if (postVideoSizeLimit) {
      previewPromptMessage.text = `The size of the uploaded video file must be less than or equal to ${MAX_VIDEO_SIZE_TEXT}.`
    } else if (postImageSizeLimit) {
      previewPromptMessage.text = `The size of the uploaded image file must be less than or equal to ${MAX_IMAGE_SIZE_TEXT}.`
    } else if (postDocumentExtensionError) {
      let extension = ''
      const foundDocument = customizedPostDocuments.find(({ id }) => id === postDocumentExtensionError)
      if (foundDocument) {
        ;({ extension } = foundDocument)
      }
      previewPromptMessage.text = `${
        extension ? `Current document is in .${extension} format.` : ''
      } LinkedIn only supports ${documentExtensionText} format.`
    } else if (postDocumentSizeLimit) {
      previewPromptMessage.text = `The size of the uploaded document file must be less than or equal to ${MAX_DOCUMENT_SIZE_TEXT}.`
      // } else if (postDocumentTitleLengthLimit) {
      //   previewPromptMessage.text = `LinkedIn requires documents to have a title. Please update it in document settings.`
      // } else if (postDocumentDescriptionLengthLimit) {
      //   previewPromptMessage.text = `LinkedIn requires documents to have a description. Please update it in document settings.`
      // } else if (notificationDeviceLengthLimit) {
      previewPromptMessage.text = `LinkedIn requires a mobile device for notifications when posting. Please download Vista Social mobile app, get logged in and try again.`
    } else if (notFoundVariables) {
      previewPromptMessage.text = `Some variables are not defined for this profile.`
    }
  } else if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES && isEditable) {
    previewPromptMessage = {
      text: `Due to limitations set by LinkedIn, only the first ${MAX_IMAGES} images will be published.`,
      type: 'warning',
    }
  } else if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length > MAX_VIDEOS && isEditable) {
    previewPromptMessage = {
      text: `Due to limitations set by LinkedIn, only the first video will be published`,
      type: 'warning',
    }
  } else if (
    customizedPostComponent === POST_DOCUMENT &&
    customizedPostDocuments.length > MAX_DOCUMENTS &&
    isEditable
  ) {
    previewPromptMessage = {
      text: `Due to limitations set by LinkedIn, only the first document will be published`,
      type: 'warning',
    }
  } else if (customizedPostComponent === POST_MIXED_MEDIA && isEditable) {
    if (postMixedMediasUpdated[0]) {
      if (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC) {
        if (postMixedMediasUpdated.length > MAX_IMAGES) {
          previewPromptMessage = {
            text: `Due to limitations set by LinkedIn, only the first ${MAX_IMAGES} images will be published.`,
            type: 'warning',
          }
        }
      } else if (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC) {
        if (postMixedMediasUpdated.length > MAX_VIDEOS) {
          previewPromptMessage = {
            text: `Due to limitations set by LinkedIn, only the first video will be published`,
            type: 'warning',
          }
        }
      }
    }
  }

  const { languages: formDataLanguages = [] } = dataForCustomizations
  const { [profileId]: networkDataForCustomizations } = dataForCustomizations.networkDataForCustomizations || {}
  const { typeData = {} } = networkDataForCustomizations || {}
  const { [SAVED_SEARCH_TYPE_LINKEDIN_LOCATION]: SAVED_LOCATION_OPTIONS = [] } = typeData || {}

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value
    onChangeUpdatePost(post)
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  const getGeoTargets = async ({ value }) => {
    if (value) {
      try {
        const response = await request({
          method: 'GET',
          path: `linkedin/geoSearch?query=${value}&profiles=${profileId}`,
        })

        const { error, results = [] } = response

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
          return []
        } else {
          return results.map(({ id, name, ...props }) => ({ value: id, label: name, ...props }))
        }
      } catch (error) {
        errorHelper({ error, componentName: LinkedinPost.displayName, functionName: 'getGeoTargets' })
        return []
      }
    }
    return []
  }

  const loadSuggestedOptions = useCallback(
    debounce(({ value }, callback) => {
      getGeoTargets({ value }).then((options) => callback([...options]))
    }, 500),
    []
  )

  const { updatedPostTextWithMentions = '', transformed_text_to_html_for_textarea } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const { updatedTextTransformedMentions: updatedPostTextWithMentions = '', updatedText } = mentionsTransformer({
      body: variableTransformedText,
      color: LINKEDIN_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea = linkTransformer({
      body: updatedText,
      color: LINKEDIN_COLORS.LAST_LIGHT_BLUE,
      transformHashstag: true,
      hashtagColor: LINKEDIN_COLORS.LAST_LIGHT_BLUE,
    })

    return { updatedPostTextWithMentions, transformed_text_to_html_for_textarea }
  }, [updatedPostText, savedVariables, agencyMentions])

  if (isEditable && comments.length === 0) {
    handleUpdateCustomization({ type: 'comments', value: [{ id: new Date().getTime().toString(), message: '' }] })
  }

  let c_likes = 0
  let c_comments = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({ likes: c_likes = 0, comments: c_comments = 0 } = profileInsights)
  }

  c_likes = status !== PUBLISHED && like ? c_likes + 1 : c_likes
  c_comments =
    c_comments ||
    comments.filter(({ message, postImages }) => message || (postImages && postImages.length !== 0)).length

  let urlHost
  if (url) {
    try {
      if (url.indexOf('http') === 0) {
        urlHost = new URL(url).host
      } else {
        urlHost = new URL(`https://${url}`).host
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: LinkedinPost.displayName,
        functionName: 'url',
        sendError: false,
        showAlert: false,
      })
      urlHost = url
    }
  }

  const postDocumentError =
    postDocumentExtensionError ||
    postDocumentSizeLimit ||
    postDocumentTitleLengthLimit ||
    postDocumentDescriptionLengthLimit

  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  const generateVideoSourceTypeTemplate = ({ data }) => {
    const { new_width, new_height } = fitMediaProportionsToBox({
      width: data[0].width,
      height: data[0].height,
      maxWidth: MAX_PREVIEW_WIDTH,
      maxHeight: MAX_PREVIEW_HEIGHT,
      type: data[0].type,
      media_gid: data[0].id,
      post,
    })

    const { thumbnail_url, id: videoId, url: videoUrl, type, isNew, title: videoTitle, filename: videoFileName } =
      data[0] || {}

    let videoThumbnailUrl = ''
    if (typeof isNew === 'undefined' && thumbnail_url) {
      videoThumbnailUrl = thumbnail_url
    }

    return (
      <Flex flexDirection="column" width={`${MAX_PREVIEW_WIDTH}px`}>
        <SourceWrapper bg={COLOR_CONSTANTS.BLACK} width={`${MAX_PREVIEW_WIDTH}px`} height={`${MAX_PREVIEW_HEIGHT}px`}>
          <StyledVideoSideLines backgroundImage={thumbnail_url || ''} />
          {noVideoAttachment ? (
            <Flex
              justifyContent="center"
              alignItems="center"
              width={`${new_width || MAX_PREVIEW_WIDTH}px`}
              height={`${new_height || MAX_PREVIEW_HEIGHT}px`}
              position="relative"
              zIndex="1"
            >
              {type === VIDEO && (
                <Fragment>
                  <Box position="absolute" top={space.xs} right={space.xs}>
                    <StyledPlayImage src="/assets/vistasocial/instagram_play.svg" />
                  </Box>
                  <StyledVideoThumbnailImage
                    height="100%"
                    width="100%"
                    source={videoThumbnailUrl || DEFAULT_VIDEO_THUMBNAIL}
                    fallbackSource={DEFAULT_VIDEO_THUMBNAIL}
                    name={videoId}
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                  />
                </Fragment>
              )}

              {type === VIDEO_DYNAMIC && (
                <DynamicMedia media={data[0]} post={post} profiles={[profile]} verticalPreview isSmall={smallPreview} />
              )}
            </Flex>
          ) : (
            <StyledBox
              position="relative"
              justifyContent="center"
              hasError={postVideoError}
              width={`${new_width || MAX_PREVIEW_WIDTH}px`}
              height={`${new_height || MAX_PREVIEW_HEIGHT}px`}
              mx="auto"
              zIndex="1"
            >
              {isNew ? (
                <ClipLoader size="50" color={colors.primary} />
              ) : (
                <Fragment>
                  {type === VIDEO && (
                    <VideoWithFallback
                      id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${videoId}`}
                      url={videoUrl}
                      controls
                      width="100%"
                      height="100%"
                      style={{
                        maxHeight: `${new_height || MAX_PREVIEW_HEIGHT}px`,
                        height: '100%',
                        background: COLOR_CONSTANTS.BLACK,
                      }}
                      config={{
                        file: {
                          attributes: {
                            poster: videoThumbnailUrl,
                          },
                        },
                      }}
                      name={videoId}
                    />
                  )}
                  {type === VIDEO_DYNAMIC && (
                    <Flex
                      width={`${new_width || MAX_PREVIEW_WIDTH}px`}
                      height={`${new_height || MAX_PREVIEW_HEIGHT}px`}
                    >
                      <DynamicMedia
                        media={data[0]}
                        post={post}
                        profiles={[profile]}
                        verticalPreview
                        isSmall={smallPreview}
                      />
                    </Flex>
                  )}
                </Fragment>
              )}
            </StyledBox>
          )}
        </SourceWrapper>

        <Flex bg="#eef3f8" py="0.5em" px="0.75em">
          <StyledVideoTitleText fontSize="0.875em">{videoTitle || videoFileName || 'Document'}</StyledVideoTitleText>
        </Flex>
      </Flex>
    )
  }

  let sourceTypeTemplate = ''

  switch (customizedPostComponent) {
    case POST_IMAGE:
      if (postImagesUpdated.length !== 0) {
        sourceTypeTemplate = (
          <SourceWrapper>
            <ImagesGridHorizontal
              images={postImagesUpdated}
              gridHeight={MAX_PREVIEW_HEIGHT}
              network={network}
              post={post}
              profiles={[profile]}
            />
          </SourceWrapper>
        )
      }
      break
    case POST_VIDEO:
      if (videoUrl || thumbnail_url || videoType === VIDEO_DYNAMIC) {
        sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: customizedPostVideos })
      }
      break
    case POST_LINK:
      if (picture_url || title || urlHost) {
        sourceTypeTemplate = (
          <SourceWrapper minimalHeight={picture_url ? null : 'auto'}>
            <Flex flexDirection="column" width="100%">
              {picture_url && (
                <ImageWithFallback
                  source={picture_url}
                  width="100%"
                  fallbackSourceWidth="0px"
                  fallbackSourceHeight="0px"
                  fallbackSource={null}
                  name={linkId}
                  id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${linkId}`}
                />
              )}

              {!publish_as_image && (
                <Flex flexDirection="column" bg="#eef3f8" py="0.5em" px="0.75em">
                  <StyledVideoTitleText fontSize="0.75em">{title}</StyledVideoTitleText>
                  {urlHost && (
                    <Text fontSize="0.75em" mt="0.25em" color={LINKEDIN_COLORS.GLUON_GREY}>
                      {urlHost}
                    </Text>
                  )}
                </Flex>
              )}
            </Flex>
          </SourceWrapper>
        )
      }
      break
    case POST_DOCUMENT:
      if (documentId) {
        sourceTypeTemplate = (
          <SourceWrapper>
            <StyledBox position="relative" width="100%" justifyContent="center" hasError={postDocumentError}>
              {isDocumentNew ? (
                <ClipLoader size="50" color={colors.primary} />
              ) : (
                <Flex flexDirection="column">
                  <ImageWithFallback
                    source={getFileIconByExtension({ extension: documentExtension })}
                    width="100%"
                    maxHeight="200px"
                    fallbackSource="/assets/file.svg"
                    name={documentId}
                    id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${documentId}`}
                  />
                  <Text fontSize="xs" mt="m" color="secondaryText" px="m" fontStyle="italic">
                    * Document preview is not available within Vista Social at this time. You can still publish it and
                    LinkedIn will generate preview image.
                  </Text>
                </Flex>
              )}
            </StyledBox>
          </SourceWrapper>
        )
      }
      break
    case POST_MIXED_MEDIA:
      if (postMixedMediasUpdated.length !== 0) {
        if (postMixedMediasUpdated[0].type === IMAGE || postMixedMediasUpdated[0].type === IMAGE_DYNAMIC) {
          sourceTypeTemplate = (
            <SourceWrapper>
              <ImagesGridHorizontal
                images={postMixedMediasUpdated}
                gridHeight={MAX_PREVIEW_HEIGHT}
                network={network}
                post={post}
                profiles={[profile]}
              />
            </SourceWrapper>
          )
        } else if (postMixedMediasUpdated[0].type === VIDEO || postMixedMediasUpdated[0].type === VIDEO_DYNAMIC) {
          sourceTypeTemplate = generateVideoSourceTypeTemplate({ data: postMixedMediasUpdated })
        }
      }
      break
    default:
      break
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `250px auto`
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  }

  return (
    <Flex flexDirection="column" height="100%">
      <PreviewPromptComponent
        text={previewPromptMessage.text}
        isShown={isEditable || !previewPromptMessage.isHidden}
        type={previewPromptMessage.type}
      />
      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex flexDirection="column" width="100%" height="100%" position="relative">
            <StyledFlex
              minHeight="max-content"
              flexDirection="column"
              isEditable={smallPreview}
              isPostFormDirty={isPostFormDirty}
            >
              {isPostFormDirty ? (
                <Fragment>
                  <Box px="0.5em" mt="0.5em">
                    <Flex>
                      {isEditable ? (
                        <StyledImg
                          source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                          fallbackSource={fallbackLogoImage}
                          width="3em"
                          height="3em"
                        />
                      ) : (
                        <SocialProfileImage
                          code={network}
                          picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                          width="3em"
                          height="3em"
                        />
                      )}
                      <Flex ml="1em" flexDirection="column" justifyContent="space-between">
                        <Flex
                          alignItems="center"
                          onMouseEnter={(e) => {
                            if (!isMultipleProfilesPreview) {
                              tooltipRef.current.handleShowTooltip({
                                contentComp: (
                                  <ProfileItemTooltip
                                    profile={{
                                      code: network,
                                      picture_url: profilePictureUrl,
                                      profile_url: profileUrl,
                                      name: profileName,
                                      username: profileUsername,
                                      display,
                                      profile_id,
                                      type: profile_type,
                                    }}
                                    message=""
                                  />
                                ),
                                wrapperComp: e.currentTarget,
                              })
                            }
                          }}
                          onMouseLeave={() => {
                            tooltipRef.current.handleHideTooltip()
                          }}
                        >
                          <StyledLink
                            as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                            href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                            target="_blank"
                            color={LINKEDIN_COLORS.BLACK}
                            fontWeight="medium"
                            fontSize="1em"
                          >
                            {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                          </StyledLink>
                        </Flex>
                        <StyledLink
                          fontSize="0.9em"
                          color={LINKEDIN_COLORS.GLUON_GREY}
                          as={published_link ? 'a' : 'p'}
                          href={published_link || ''}
                          target="_blank"
                        >
                          {publish_at
                            ? `${moment(publish_at).format('MMMM D, YYYY h:mm A')} ${zoneShort || ''} • `
                            : 'Now • '}
                          <Text as="span" opacity="0.6">
                            <Image height="1em" src="/assets/linkedin_globe.svg" alt="Public" mt="-0.125em" />
                          </Text>
                        </StyledLink>
                      </Flex>
                    </Flex>
                  </Box>

                  <Flex
                    flexDirection="column"
                    width="100%"
                    onClick={() => {
                      if (published_link) {
                        window.open(published_link)
                      }
                    }}
                  >
                    <Box px="0.5em">
                      <Text
                        dangerouslySetInnerHTML={{
                          __html: transformed_text_to_html_for_textarea,
                        }}
                        mt="0.5em"
                        fontSize="1em"
                        fontWeight="semiLight"
                        whiteSpace="pre-line"
                        lineHeight="1.42857"
                        color={LINKEDIN_COLORS.BLACK}
                      />
                    </Box>
                    {customizedPostComponent ? <Box mt="s">{sourceTypeTemplate}</Box> : <Box />}
                    {(c_likes > 0 || c_comments > 0) && (
                      <Box pb="0.5em">
                        <StyledFlexStatistics mx="1em">
                          {c_likes > 0 && (
                            <Flex alignItems="center" pb="0.5em" mt="1em">
                              <Image height="1em" width="1em" src="/assets/linkedin_like.svg" alt="Likes" mr="0.25em" />
                              <Text fontWeight="semiLight" fontSize="0.75em" color={LINKEDIN_COLORS.GLUON_GREY}>
                                {formatToNumberWithCommas(c_likes)}
                              </Text>
                            </Flex>
                          )}
                          &nbsp;
                          {c_comments > 0 && (
                            <Flex alignItems="center" pb="0.5em" mt="1em">
                              <Text fontWeight="semiLight" fontSize="0.75em" color={LINKEDIN_COLORS.GLUON_GREY}>
                                {`${c_likes > 0 ? '· ' : ''}${formatToNumberWithCommas(c_comments)} ${
                                  c_comments === 1 ? 'comment' : 'comments'
                                }`}
                              </Text>
                            </Flex>
                          )}
                        </StyledFlexStatistics>
                      </Box>
                    )}
                    {comments.map((comment) => {
                      const { id, message, postComponent, postImages } = comment

                      if (!message && (!postImages || postImages.length === 0)) {
                        return null
                      }

                      let sourceTypeTemplate = ''

                      switch (postComponent) {
                        case POST_IMAGE:
                          if (postImages && postImages.length !== 0) {
                            sourceTypeTemplate = (
                              <Flex
                                flexDirection="column"
                                position="relative"
                                maxWidth={smallPreview ? '100px' : '200px'}
                                mt="0.5em"
                              >
                                <ImagesGridVertical
                                  images={[postImages[0]]}
                                  gridHeight="12.5em"
                                  network={network}
                                  socialPreviewProfiles={[profile]}
                                  post={{ id, postText: message }}
                                  dynamicMediaIsSmall={smallPreview}
                                />
                              </Flex>
                            )
                          }
                          break
                        default:
                          break
                      }

                      const { variableTransformedText } = variableTransformer({
                        str: message,
                        savedVariables,
                        post,
                        profileId,
                      })
                      return (
                        <Flex px="0.5em" pb="0.5em" key={id}>
                          {isEditable ? (
                            <StyledImg
                              source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                              fallbackSource={fallbackLogoImage}
                              width="2.5em"
                              height="2.5em"
                            />
                          ) : (
                            <SocialProfileImage
                              code={network}
                              picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                              width="2.5em"
                              height="2.5em"
                            />
                          )}
                          <StyledCommentWrapper ml="0.25em" flexDirection="column" width="100%">
                            <Flex height="2.5em" alignItems="baseline">
                              <StyledLink
                                as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'p'}
                                href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                                target="_blank"
                                color={LINKEDIN_COLORS.BLACK}
                                fontWeight="medium"
                                fontSize="0.875em"
                                mr="0.25em"
                              >
                                {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                              </StyledLink>
                              <StyledLabel>Author</StyledLabel>
                            </Flex>

                            <Text
                              fontSize="0.875em"
                              color={LINKEDIN_COLORS.BLACK}
                              dangerouslySetInnerHTML={{
                                __html: variableTransformedText,
                              }}
                            />

                            {sourceTypeTemplate}
                          </StyledCommentWrapper>
                        </Flex>
                      )
                    })}
                  </Flex>
                </Fragment>
              ) : (
                <Box mt="m" px="s" mb="s" height="100%">
                  <NoPost />
                </Box>
              )}
            </StyledFlex>
          </Flex>
        )}

        {isEditable && (
          <Flex
            minHeight="max-content"
            flexDirection="column"
            height="100%"
            bg={COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL}
            borderRadius={radius.l}
          >
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex
                  flexDirection="column"
                  bg={COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL}
                  borderRadius={radius.l}
                  px="m"
                  py="m"
                >
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper>
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={updatedPostTextWithMentions.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              setIsPostTextEditOpen(true)
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                whiteSpace="pre-line"
                                color={
                                  transformed_text_to_html_for_textarea
                                    ? COLOR_CONSTANTS.DENIM
                                    : COLOR_CONSTANTS.COSMIC_ENERGY
                                }
                              />

                              <Counter
                                counter={updatedPostTextWithMentions.length}
                                max={MAX_TEXT_LENGTH}
                                fontSize="xxs"
                              />
                            </BodyWrapper>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={LINKEDIN} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                              postDocuments: customizedPostDocuments,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_IMAGE &&
                                customizedPostImages &&
                                customizedPostImages.length !== 0) ||
                              (customizedPostComponent === POST_VIDEO &&
                                customizedPostVideos &&
                                customizedPostVideos.length !== 0) ||
                              (customizedPostComponent === POST_DOCUMENT &&
                                customizedPostDocuments &&
                                customizedPostDocuments.length !== 0) ||
                              (customizedPostComponent === POST_MIXED_MEDIA &&
                                customizedPostMixedMedias &&
                                customizedPostMixedMedias.length !== 0)
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  {hasMobileNotificationOption && (
                    <PublicationOptionComponent
                      handleUpdateCustomization={handleUpdateCustomization}
                      publishing_option={publishing_option}
                      device_gid={device_gid}
                      devices={devices}
                      notificationDeviceLengthLimit={notificationDeviceLengthLimit}
                    />
                  )}

                  <Flex mt="m" minHeight="max-content" flexDirection="column">
                    <Flex flexDirection="column" position="relative">
                      {featuresEnabled[FEATURE_POST_ENGAGEMENT] &&
                        !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                          <CustomizationsBlockComponent
                            showUpgradeMessage={false}
                            showUpgradePlan={false}
                            message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                          />
                        )}
                      <Flex
                        flexDirection="column"
                        p={
                          featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                            ? 's'
                            : '0'
                        }
                      >
                        <Flex alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <CommentsComponent
                              user={user}
                              network={LINKEDIN}
                              profileId={profileId}
                              handleUpdateCustomization={handleUpdateCustomization}
                              handleUpdateCustomizationDebounce={handleUpdateCustomizationDebounce}
                              commentTextMaxLimitNumber={MAX_COMMENT_LENGTH}
                              comments={comments}
                              commentTextMaxLimit={commentTextMaxLimit}
                              selectedEntities={selectedEntities}
                              selectedProfiles={selectedProfiles}
                              textVariantOffsets={textVariantOffsets}
                              showMediaPicker={!blockCommentsMediaAttachment}
                              showImageComponent
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="comments" />
                        </Flex>

                        {customizedPostComponent === POST_LINK && picture_url && (
                          <Flex mt="m" alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={!!publish_as_image}
                                onClick={() => {
                                  handleUpdateCustomization({
                                    type: 'publish_as_image',
                                    value: !publish_as_image,
                                  })
                                }}
                                text="Publish as image"
                                color={COLOR_CONSTANTS.DENIM}
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="publish_as_image" network={LINKEDIN} />
                          </Flex>
                        )}

                        <Flex mt="m" alignItems="center" width="100%">
                          <Flex flexDirection="column" width="100%">
                            <Switch
                              isOn={like}
                              onClick={() => {
                                handleUpdateCustomization({
                                  type: 'like',
                                  value: !like,
                                })
                              }}
                              text="Create first like"
                              color={COLOR_CONSTANTS.DENIM}
                              fontWeight="normal"
                            />
                          </Flex>
                          <TooltipPromptComponent prompt_key="like" network={LINKEDIN} />
                        </Flex>
                      </Flex>
                    </Flex>

                    <Flex mt="m" flexDirection="column" position="relative">
                      {featuresEnabled[FEATURE_POST_TARGETING] && !featuresEnabled[FEATURE_POST_TARGETING].enabled && (
                        <CustomizationsBlockComponent
                          showUpgradeMessage={false}
                          showUpgradePlan={false}
                          message={featuresEnabled[FEATURE_POST_TARGETING].description}
                        />
                      )}

                      <Flex
                        flexDirection="column"
                        p={
                          featuresEnabled[FEATURE_POST_TARGETING] && !featuresEnabled[FEATURE_POST_TARGETING].enabled
                            ? 's'
                            : '0'
                        }
                      >
                        <Flex flexDirection="column">
                          <Flex alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={customizationsSchema.geoTargeting}
                                onClick={() => {
                                  const updatedValue = !customizationsSchema.geoTargeting
                                  customizationsSchema.geoTargeting = updatedValue
                                  setCustomizationsSchema({ ...customizationsSchema })

                                  if (!updatedValue) {
                                    handleUpdateCustomization({
                                      type: 'geoLocations',
                                      value: [],
                                    })
                                  }
                                }}
                                text="Geo targeting"
                                color={COLOR_CONSTANTS.DENIM}
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="location" network={LINKEDIN} />
                          </Flex>
                          {customizationsSchema.geoTargeting && (
                            <Flex mt="m" flexDirection="column" width="100%">
                              <DropDown
                                placeholder="Search for location"
                                value={
                                  geoLocations.length !== 0
                                    ? geoLocations.map(({ id, name }) => ({ value: id, label: name }))
                                    : null
                                }
                                onChange={(options) => {
                                  handleUpdateCustomization({
                                    type: 'geoLocations',
                                    value: options
                                      ? options.map(({ value, label }) => ({ id: value, name: label }))
                                      : [],
                                  })

                                  if (options && options.length !== 0) {
                                    options.forEach((option) => {
                                      setSavedSearches({
                                        type: SAVED_SEARCH_TYPE_LINKEDIN_LOCATION,
                                        id: profileId,
                                        result: option,
                                        dataForCustomizations,
                                        setDataForCustomizations,
                                      })
                                    })
                                  }
                                }}
                                loadOptions={(value, callback) => loadSuggestedOptions({ value }, callback)}
                                defaultValue={[]}
                                isMulti
                                isClearable
                                defaultOptions={SAVED_LOCATION_OPTIONS}
                                openMenuOnFocus
                                dropDownType="AsyncSelect"
                                showDropDownArrow={false}
                                noOptionsMessage={() => 'No results found'}
                              />
                            </Flex>
                          )}
                        </Flex>

                        <Flex mt="m" flexDirection="column">
                          <Flex alignItems="center" width="100%">
                            <Flex flexDirection="column" width="100%">
                              <Switch
                                isOn={customizationsSchema.languageTargeting}
                                onClick={() => {
                                  customizationsSchema.languageTargeting = !customizationsSchema.languageTargeting
                                  setCustomizationsSchema({ ...customizationsSchema })
                                }}
                                text="Language targeting"
                                color={COLOR_CONSTANTS.DENIM}
                                fontWeight="normal"
                              />
                            </Flex>
                            <TooltipPromptComponent prompt_key="language" network={LINKEDIN} />
                          </Flex>

                          {customizationsSchema.languageTargeting && (
                            <Flex mt="m" flexDirection="column" width="100%">
                              <DropDown
                                isMulti
                                placeholder="Select a target"
                                value={
                                  languages.length !== 0
                                    ? mapArrayToReactSelect(
                                        formDataLanguages.filter(({ name }) => languages.includes(name))
                                      )
                                    : null
                                }
                                onChange={(options) => {
                                  handleUpdateCustomization({
                                    type: 'languages',
                                    value: options ? options.map(({ value }) => value) : [],
                                  })
                                }}
                                options={mapArrayToReactSelect(formDataLanguages)}
                                openMenuOnFocus
                              />
                            </Flex>
                          )}
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>

                  {!isAdvocacy && (
                    <BoostsDropdownComponent
                      boosts={boosts && boosts.length !== 0 ? boosts : null}
                      handleUpdateCustomization={handleUpdateCustomization}
                      boostId={boost_gid}
                      user={user}
                      profile={profile}
                    />
                  )}

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

LinkedinPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  boosts: [],
  blockCommentsMediaAttachment: false,
  isSmallPreview: false,
  isAdvocacy: false,
}

LinkedinPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  boosts: PropTypes.array,
  blockCommentsMediaAttachment: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
  isAdvocacy: PropTypes.bool,
}

LinkedinPost.displayName = 'LinkedInPost'

export default LinkedinPost
