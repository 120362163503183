import React, { Fragment, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import moment from 'moment-timezone'
import debounce from 'lodash.debounce'
import { radius, COLOR_CONSTANTS, colors, space } from 'theme'
import { THREADS, FEATURE_POST_ENGAGEMENT } from 'consts'
import { formatToNumberWithCommas, linkTransformer } from 'helpers'
import errorHelper from 'utils/errorHelper'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import { Text } from 'components/atoms/Typography'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Switch from 'components/atoms/Switch'
import Image from 'components/atoms/Image'
import Link from 'components/atoms/Link'
import Counter from 'components/molecules/Counter'
import DropDown from 'shared/DropDown'
import NoPost from './NoPost'
import PreviewPromptComponent from './components/PreviewPromptComponent'
import CustomizationsBlockComponent from './components/CustomizationsBlockComponent'
import {
  NETWORK_LIMITS,
  POST_IMAGE,
  POST_VIDEO,
  POST_LINK,
  POST_MIXED_MEDIA,
  MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS,
  PREVIEW_MEDIA_CLASS_NAME,
  THREADS_COLORS,
} from '../../consts'
import SocialProfileImage from '../SocialProfileImage'
import CalendarItemTooltip from '../CalendarItemTooltip'
import ProfileItemTooltip from '../ProfileItemTooltip'
// eslint-disable-next-line import/no-cycle
import { mentionsTransformer, transformTextVariantsToUnicodeVariant, variableTransformer } from '../../helpers'
// eslint-disable-next-line import/no-cycle
import TextAreaComponentWithControls from './components/CommentsComponent/TextAreaComponentWithControls'
// eslint-disable-next-line import/no-cycle
import CustomizationsSlider from './components/CustomizationsSlider'
import TooltipPromptComponent from './components/TooltipPromptComponent'
// eslint-disable-next-line import/no-cycle
import CustomizationsMediaComponent from './components/CustomizationsMediaComponent'
import PublicationOptionComponent from './components/PublicationOptionComponent'
import ThreadsMediaPreview from './components/ThreadsMediaPreview'
import CommentsComponent from './components/CommentsComponent'

const PROFILE_IMAGE_SIZE = '2.25em'

const URL_SHORTENER_REGEX = /^(?:https?:\/\/)?(www\.)?([^/]+\.[^/]+)\/([^\s]{4})/

const StyledFlex = styled(Flex)`
  border-bottom: ${({ isPostFormDirty }) => (isPostFormDirty ? 'none' : 'inset')};
  font-size: ${({ isEditable }) => (isEditable ? '10.666666px' : '16px')};
  height: ${({ isPostFormDirty }) => (isPostFormDirty ? 'fit-content' : '100%')};
`

const StyledImg = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  object-fit: contain;
  width: ${PROFILE_IMAGE_SIZE};
  height: ${PROFILE_IMAGE_SIZE};
`

const BodyWrapper = styled.div`
  cursor: pointer;
  position: relative;
  a {
    color: ${colors.primary};
  }
`

const StyledLink = styled(Text)`
  text-decoration: none;
  cursor: pointer;
`

const StyledWrapper = styled(Flex)`
  border-bottom-width: 1px;
  border-bottom-color: rgba(0, 0, 0, 0.15);
  border-bottom-style: solid;
  flex-direction: column;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: 6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  box-shadow: rgb(145 158 171 / 30%) 0px 0px 4px;
  z-index: 2;
`

const StyledCaptionWrapper = styled(Box)`
  padding: ${space.s};
  border-radius: ${radius.l};
  background: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  cursor: pointer;
`

const StyledImageActionWrapper = styled(Flex)`
  min-width: ${({ $hasWidth }) => ($hasWidth ? 'auto' : '2.25em')};
  height: 2.25em;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  cursor: pointer;
  &:hover {
    background-color: rgb(245, 245, 245);
  }
`

const StyledLinkWrapper = styled(Flex)`
  border-radius: 1em;
  border: 1px solid rgba(0, 0, 0, 0.15);
`

const {
  postTextMaxLimit: MAX_TEXT_LENGTH,
  commentTextMaxLimit: MAX_COMMENT_LENGTH,
  maxImages: MAX_IMAGES,
  maxVideos: MAX_VIDEOS,
  minMixedMediasInCarousel: MIN_MIXED_MEDIAS_IN_CAROUSEL,
  minVideoDuration: MIN_VIDEO_DURATION,
  maxVideoDuration: MAX_VIDEO_DURATION,
  videoExtensions: VIDEO_EXTENSIONS,
  maxVideoSizeString: MAX_VIDEO_SIZE_TEXT,
  minImageAspectRatio: MIN_IMAGE_ASPECT_RATIO,
  maxImageAspectRatio: MAX_IMAGE_ASPECT_RATIO,
  minVideoAspectRatio: MIN_VIDEO_ASPECT_RATIO,
  maxVideoAspectRatio: MAX_VIDEO_ASPECT_RATIO,
  maxImageSizeString: MAX_IMAGE_SIZE_TEXT,
  hasMobileNotificationOption,
  maxTags: MAX_TAGS,
} = NETWORK_LIMITS.find(({ code }) => code === THREADS)

const videoExtensionText = `${VIDEO_EXTENSIONS.slice(0, VIDEO_EXTENSIONS.length - 1).join(', ')} and ${
  VIDEO_EXTENSIONS[VIDEO_EXTENSIONS.length - 1]
}`

const REPLY_CONTROL_DEFAULT_VALUES = [
  { value: 'everyone', label: 'Everyone can reply' },
  { value: 'accounts_you_follow', label: 'Accounts you follow can reply' },
  { value: 'mentioned_only', label: 'Mentioned only accounts can reply' },
]

const ThreadsPost = ({
  user,
  onChangeUpdatePost,
  dataForCustomizations,
  isPostFormDirty,
  isEditable,
  isSmallPreview,
  noVideoAttachment,
  hasPreview,
  postErrors,
  featuresEnabled,
  selectedEntities,
  selectedProfiles,
  previewProfileNumber,
  devices,
  savedVariables,
  applyCustomizationsToAllNetworksTemplate,
  hasCustomCaption,
  hasCustomMedias,
  forceCloseNetworkPreviewCaption,
  blockCommentsMediaAttachment,
  ...props
}) => {
  const tooltipRef = useRef(null)
  const uploadMediaComponentRef = useRef(null)

  const [isPostTextEditOpen, setIsPostTextEditOpen] = useState(false)
  const [profile, setProfile] = useState({ ...props.profile })
  const [post, setPost] = useState({ ...props.post })
  const [agencyMentions, setAgencyMentions] = useState([])

  // leave this const [currentMediaIndex, setCurrentMediaIndex] = useState(0)
  const [textVariantOffsets, setTextVariantOffsets] = useState([])
  const [isCustomizationsOpen, setIsCustomizationsOpen] = useState(true)

  const smallPreview = (isEditable && isCustomizationsOpen) || isSmallPreview

  const MAX_MEDIA_HEIGHT = smallPreview ? '10.485em' : '26.875em'
  const MAX_CAROUSEL_MEDIA_WIDTH = smallPreview ? 220.66 : 466 // 20.6875014648 : 33 em

  const isMultipleProfilesPreview = previewProfileNumber > MAX_PROFILES_FOR_PREVIEW_CUSTOMIZATIONS

  const {
    profileId,
    profileName,
    profilePictureUrl,
    fallbackLogoImage,
    network,
    display,
    profileUrl,
    profileUsername,
    profile_id,
    type: profile_type,
  } = profile

  const {
    publish_at,
    postText,
    postImages = [],
    postVideos = [],
    postMixedMedias = [],
    link,
    customizations = {},
    result,
    insights = {},
    postComponent,
  } = post

  useEffect(() => {
    if (isEditable && isPostTextEditOpen) {
      setIsPostTextEditOpen(false)
    }
  }, [postText, forceCloseNetworkPreviewCaption])

  let updatedPostText = postText || ''
  let customizedPostComponent = postComponent
  let customizedPostImages = postImages
  let customizedPostVideos = postVideos
  let customizedPostMixedMedias = postMixedMedias
  let comments = []
  let device_gid = null
  let publishing_option = null
  let publish_as_image = false
  let reply_control = 'everyone'

  if (customizations[network]) {
    const profileCustomizations = customizations[network][profileId] || {}

    updatedPostText = profileCustomizations.message || postText || ''
    customizedPostComponent = profileCustomizations.postComponent

    if (typeof customizedPostComponent === 'undefined') {
      customizedPostComponent = postComponent
    }

    customizedPostImages = profileCustomizations.postImages || postImages
    customizedPostVideos = profileCustomizations.postVideos || postVideos
    customizedPostMixedMedias = profileCustomizations.postMixedMedias || postMixedMedias
    ;({
      comments = [],
      device_gid = null,
      publishing_option = null,
      publish_as_image = false,
      reply_control = 'everyone',
    } = profileCustomizations)
  }

  let video_processing = true

  if (customizations) {
    ;({ video_processing } = customizations)
  }

  useEffect(() => {
    setTextVariantOffsets([...transformTextVariantsToUnicodeVariant()])
  }, [])

  useEffect(() => {
    setPost({ ...props.post })
  }, [props.post])

  useEffect(() => {
    setProfile({ ...props.profile })
  }, [props.profile])

  useEffect(() => {
    setAgencyMentions([...props.agencyMentions])
  }, [props.agencyMentions])

  const { profiles: profilesWithErrors = {} } = postErrors.networksErrors[network] || {}
  const {
    hasErrors,
    flags: {
      postTextMaxLimit,
      postImagesLengthLimit,
      postVideosLengthLimit,
      postLinkPictureUrlLengthLimit,
      noContent,
      commentTextMaxLimit,
      postImageAspectRatioLimit,
      postVideoDurationLimit,
      postVideoExtensionError,
      postVideoSizeLimit,
      postImageSizeLimit,
      deviceLengthLimit,
      notificationDeviceLengthLimit,
      notFoundVariables,
      documentsNotSupported,
      postMixedMediasLengthLimit,
      postVideoAspectRatioLimit,
    } = {},
    warning_flags: { postVideoAspectRatioLimit: postVideoAspectRatioLimitWarning } = {},
  } = profilesWithErrors[profileId] || {}

  const postImagesUpdated =
    customizedPostComponent === POST_IMAGE ? customizedPostImages.filter(({ tempId }) => !tempId) : []

  const postVideosUpdated =
    customizedPostComponent === POST_VIDEO ? customizedPostVideos.filter(({ tempId }) => !tempId) : []

  const postMixedMediasUpdated =
    customizedPostComponent === POST_MIXED_MEDIA ? customizedPostMixedMedias.filter(({ tempId }) => !tempId) : []

  const { published_link } = result || {}

  const { transformed_text_to_html_for_textarea, updatedPostTextWithMentions = '' } = useMemo(() => {
    const { variableTransformedText } = variableTransformer({ str: updatedPostText, savedVariables, post, profileId })

    const { updatedTextTransformedMentions: updatedPostTextWithMentions = '', updatedText } = mentionsTransformer({
      body: variableTransformedText,
      color: THREADS_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      agencyMentions,
      network,
    })

    const transformed_text_to_html_for_textarea = linkTransformer({
      body: updatedText,
      color: THREADS_COLORS.LAST_LIGHT_BLUE,
      fontWeight: 400,
      transformHashstag: true,
      hashtagColor: THREADS_COLORS.MAIN,
      hashtagFontWeight: 400,
      linkShorteningFunction: ({ word }) => {
        const match = word.match(URL_SHORTENER_REGEX)
        if (match) {
          return `${match[2] || match[1] + match[2]}/${match[3]}...`
        } else {
          return word
        }
      },
    })

    return {
      transformed_text_to_html_for_textarea,
      updatedPostTextWithMentions,
    }
  }, [updatedPostText, savedVariables, agencyMentions])

  let previewPromptMessageText_postVideoAspectRatioLimit = ''

  if (postVideoAspectRatioLimit || postVideoAspectRatioLimitWarning) {
    previewPromptMessageText_postVideoAspectRatioLimit = `Video is outside of Threads required ${MIN_VIDEO_ASPECT_RATIO} to ${MAX_VIDEO_ASPECT_RATIO} aspect ratio for this post.`

    if (postVideoAspectRatioLimitWarning && video_processing) {
      previewPromptMessageText_postVideoAspectRatioLimit += ` You can still publish it but it will be padded.`
    }
  }

  let previewPromptMessage = {
    text: '',
    isHidden: true,
    type: 'error',
  }

  if (hasErrors) {
    if (postImagesLengthLimit || postVideosLengthLimit || postMixedMediasLengthLimit || noContent) {
      previewPromptMessage.text = `Post must have a message or at least one attached media.`
    } else if (postLinkPictureUrlLengthLimit) {
      previewPromptMessage.text = `Threads require an image or a video.`
    } else if (postImageAspectRatioLimit) {
      previewPromptMessage.text = `Threads require the image aspect ratio to be within ${MIN_IMAGE_ASPECT_RATIO} to ${MAX_IMAGE_ASPECT_RATIO}. Please edit or crop the image and re-upload it again.`
    } else if (postTextMaxLimit) {
      previewPromptMessage.text = `Your post text is too long. You can customize it for this network by clicking on the post preview.`
    } else if (commentTextMaxLimit) {
      previewPromptMessage.text = `Your post comment is too long.`
    } else if (postVideoDurationLimit) {
      if (MAX_VIDEO_DURATION) {
        previewPromptMessage.text = `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds and shorter than or equal to ${MAX_VIDEO_DURATION} seconds.`
      } else {
        previewPromptMessage.text = `The duration of the video file must be at least ${MIN_VIDEO_DURATION} seconds.`
      }
    } else if (postVideoExtensionError) {
      let extension = ''
      const foundVideo = customizedPostVideos.find(({ id }) => id === postVideoExtensionError)
      if (foundVideo) {
        ;({ extension } = foundVideo)
      }
      previewPromptMessage.text = `${
        extension ? `Current video is in .${extension} format.` : ''
      } Threads only support ${videoExtensionText} format.`
    } else if (postVideoSizeLimit) {
      previewPromptMessage.text = `The size of the uploaded video file must be less than or equal to ${MAX_VIDEO_SIZE_TEXT}.`
    } else if (postImageSizeLimit) {
      previewPromptMessage.text = `The size of the uploaded image file must be less than or equal to ${MAX_IMAGE_SIZE_TEXT}.`
    } else if (deviceLengthLimit) {
      previewPromptMessage.text = `Threads require a mobile device when posting via reminder notification. Please download Vista Social mobile app, get logged in and try again.`
    } else if (notificationDeviceLengthLimit) {
      previewPromptMessage.text = `Threads require a mobile device for notifications when posting. Please download Vista Social mobile app, get logged in and try again.`
    } else if (notFoundVariables) {
      previewPromptMessage.text = `Some variables are not defined for this profile.`
    } else if (documentsNotSupported) {
      previewPromptMessage.text = `Threads doesn't support document posting.`
    } else if (postMixedMediasLengthLimit) {
      previewPromptMessage.text = `Threads require at least ${MIN_MIXED_MEDIAS_IN_CAROUSEL} medias for carousel post.`
    } else if (postVideoAspectRatioLimit && !video_processing) {
      previewPromptMessage = {
        text: previewPromptMessageText_postVideoAspectRatioLimit,
      }
    }
  } else if (customizedPostComponent === POST_IMAGE && customizedPostImages.length > MAX_IMAGES && isEditable) {
    previewPromptMessage = {
      text: `Due to limitations set by Threads, only the first ${MAX_IMAGES} images will be published`,
      type: 'warning',
    }
  } else if ((customizedPostComponent === POST_VIDEO || customizedPostComponent === POST_MIXED_MEDIA) && isEditable) {
    if (customizedPostVideos.length > MAX_VIDEOS) {
      previewPromptMessage = {
        text: `Due to limitations set by Threads, only the first ${MAX_VIDEOS} videos will be published`,
        type: 'warning',
      }
    } else if (postVideoAspectRatioLimitWarning) {
      previewPromptMessage = {
        text: previewPromptMessageText_postVideoAspectRatioLimit,
        type: video_processing ? 'warning' : 'error', // video_processing flag is just for information that it would be a warning
      }
    }
  }

  const handleUpdateCustomization = ({ type, value }) => {
    if (!post.customizations) {
      post.customizations = {}
    }
    if (!post.customizations[network]) {
      post.customizations[network] = {}
    }
    if (!post.customizations[network][profileId]) {
      post.customizations[network][profileId] = {}
    }

    post.customizations[network][profileId][type] = value
    onChangeUpdatePost(post)
  }

  if (isEditable && comments.length === 0) {
    handleUpdateCustomization({ type: 'comments', value: [{ id: new Date().getTime().toString(), message: '' }] })
  }

  const handleUpdateCustomizationDebounce = debounce(({ type, value }) => {
    handleUpdateCustomization({ type, value })
  }, 300)

  let c_likes = 0
  let c_shares = 0
  let c_comments = 0
  let c_reposts = 0

  if (insights[network]) {
    const profileInsights = insights[network][profileId] || {}

    ;({ likes: c_likes = 0, shares: c_shares = 0, comments: c_comments = 0, reposts: c_reposts = 0 } = profileInsights)
  }

  c_comments =
    c_comments ||
    comments.filter(
      ({ message, postImages, postVideos, postMixedMedias }) =>
        message ||
        (postImages && postImages.length !== 0) ||
        (postVideos && postVideos.length !== 0) ||
        (postMixedMedias && postMixedMedias.length !== 0)
    ).length

  let slideToIndex

  const postVideoError =
    postVideoAspectRatioLimit || postVideoDurationLimit || postVideoExtensionError || postVideoSizeLimit

  const postImageError = postImageSizeLimit || postImageAspectRatioLimit

  if (postImageError || postVideoError) {
    if (postComponent === POST_MIXED_MEDIA) {
      slideToIndex = postMixedMediasUpdated.findIndex(({ id }) => id === (postImageError || postVideoError))
    } else if (postImageError) {
      slideToIndex = postImagesUpdated.findIndex(({ id }) => id === postImageError)
    } else if (postVideoError) {
      slideToIndex = postVideosUpdated.findIndex(({ id }) => id === postVideoError)
    }
  }

  if (slideToIndex) {
    if (slideToIndex === -1) {
      slideToIndex = 0
    } else {
      slideToIndex = Math.ceil(slideToIndex / 2)
    }
  }

  const generateSourceTypeTemplate = ({
    customizedPostComponent,
    post,
    slideToIndex,
    postImagesUpdated,
    postVideosUpdated,
    postMixedMediasUpdated,
    link,
  }) => {
    let sourceTypeTemplate = ''

    switch (customizedPostComponent) {
      case POST_IMAGE:
        if (postImagesUpdated.length > 0) {
          sourceTypeTemplate = (
            <Flex flexDirection="column">
              <ThreadsMediaPreview
                maxHeight={MAX_MEDIA_HEIGHT}
                maxCarouselWidth={MAX_CAROUSEL_MEDIA_WIDTH}
                medias={postImagesUpdated}
                post={post}
                socialPreviewProfiles={[profile]}
                network={THREADS}
                noVideoAttachment={noVideoAttachment}
                smallPreview={smallPreview}
                slideToIndex={slideToIndex}
              />
            </Flex>
          )
        }
        break
      case POST_VIDEO:
        if (postVideosUpdated.length > 0) {
          sourceTypeTemplate = (
            <Flex flexDirection="column">
              <ThreadsMediaPreview
                maxHeight={MAX_MEDIA_HEIGHT}
                maxCarouselWidth={MAX_CAROUSEL_MEDIA_WIDTH}
                medias={postVideosUpdated}
                post={post}
                socialPreviewProfiles={[profile]}
                network={THREADS}
                noVideoAttachment={noVideoAttachment}
                smallPreview={smallPreview}
                slideToIndex={slideToIndex}
              />
            </Flex>
          )
        }
        break
      case POST_MIXED_MEDIA:
        if (postMixedMediasUpdated.length > 0) {
          sourceTypeTemplate = (
            <Flex flexDirection="column">
              <ThreadsMediaPreview
                maxHeight={MAX_MEDIA_HEIGHT}
                maxCarouselWidth={MAX_CAROUSEL_MEDIA_WIDTH}
                medias={postMixedMediasUpdated}
                post={post}
                socialPreviewProfiles={[profile]}
                network={THREADS}
                noVideoAttachment={noVideoAttachment}
                smallPreview={smallPreview}
                slideToIndex={slideToIndex}
              />
            </Flex>
          )
        }
        break
      case POST_LINK:
        // eslint-disable-next-line no-case-declarations
        let urlHost

        if (link && link.url) {
          try {
            if (link.url.indexOf('http') === 0) {
              urlHost = new URL(link.url).host
            } else {
              urlHost = new URL(`https://${link.url}`).host
            }
          } catch (error) {
            errorHelper({
              error,
              componentName: ThreadsPost.displayName,
              functionName: 'url',
              showAlert: false,
              sendError: false,
            })
            urlHost = link.url
          }
        }

        if (link && (link.picture_url || urlHost || link.title || link.description)) {
          sourceTypeTemplate = (
            <StyledLinkWrapper flexDirection="column" mt="0.5em" maxWidth="25em">
              <ImageWithFallback
                id={`${PREVIEW_MEDIA_CLASS_NAME}${post && post.id ? `-${post.id}` : ''}-${link.id}`}
                source={link.picture_url || '/assets/landscape.svg'}
                width="100%"
                maxWidth="25em"
                fallbackSource="/assets/landscape.svg"
                name={link.id}
                borderRadius="1em 1em 0 0"
                objectFit="cover"
              />

              <Flex flexDirection="column" height="6.25em" p="1em">
                <Link fontSize="0.75em" color={THREADS_COLORS.TARNISHED_SILVER} as="a" href={link.url} target="_blank">
                  {urlHost}
                </Link>
                <Text mt="0.25em" fontSize="0.9375em" color={THREADS_COLORS.MAIN}>
                  {link.title || link.description}
                </Text>
              </Flex>
            </StyledLinkWrapper>
          )
        }
        break
      default:
        break
    }

    return sourceTypeTemplate
  }

  const sourceTypeTemplate = generateSourceTypeTemplate({
    customizedPostComponent,
    post,
    slideToIndex,
    postImagesUpdated,
    postVideosUpdated,
    postMixedMediasUpdated,
    link,
  })

  const generateThreadControlsTemplate = ({ c_likes, c_comments, c_reposts, c_shares }) => {
    return (
      <Flex width="100%" mt="0.375em" ml="-0.5em">
        <Flex pr="0.25em">
          <StyledImageActionWrapper $hasWidth={c_likes > 0}>
            <Flex alignItems="center">
              <Image mx="0.25em" width="1.25em" height="1.25em" src="/assets/threads_heart.svg" alt="Like" />
              {c_likes > 0 && (
                <Text mt="0.1875em" fontSize="0.8125em" width="3ch">
                  &nbsp;
                  {formatToNumberWithCommas(c_likes)}
                </Text>
              )}
            </Flex>
          </StyledImageActionWrapper>
        </Flex>

        <Flex px="0.25em">
          <StyledImageActionWrapper $hasWidth={c_comments > 0}>
            <Flex alignItems="center">
              <Image mx="0.25em" width="1.25em" height="1.25em" src="/assets/threads_comment.svg" alt="Comment" />
              {c_comments > 0 && (
                <Text mt="0.1875em" fontSize="0.8125em" width="3ch">
                  &nbsp;
                  {formatToNumberWithCommas(c_comments)}
                </Text>
              )}
            </Flex>
          </StyledImageActionWrapper>
        </Flex>

        <Flex px="0.25em">
          <StyledImageActionWrapper $hasWidth={c_reposts > 0}>
            <Flex alignItems="center">
              <Image mx="0.25em" width="1.25em" height="1.25em" src="/assets/threads_repost.svg" alt="Repost" />
              {c_reposts > 0 && (
                <Text mt="0.1875em" fontSize="0.8125em" width="3ch">
                  &nbsp;
                  {formatToNumberWithCommas(c_reposts)}
                </Text>
              )}
            </Flex>
          </StyledImageActionWrapper>
        </Flex>

        <Flex px="0.25em">
          <StyledImageActionWrapper $hasWidth={c_shares > 0}>
            <Flex alignItems="center">
              <Image mx="0.25em" width="1.25em" height="1.25em" src="/assets/threads_share.svg" alt="Like" />
              {c_shares > 0 && (
                <Text mt="0.1875em" fontSize="0.8125em" width="3ch">
                  &nbsp;
                  {formatToNumberWithCommas(c_shares)}
                </Text>
              )}
            </Flex>
          </StyledImageActionWrapper>
        </Flex>
      </Flex>
    )
  }

  let gridTemplateColumns = 'auto'

  if (hasPreview && isEditable) {
    if (isCustomizationsOpen) {
      gridTemplateColumns = `250px auto`
    } else {
      gridTemplateColumns = `auto 40px`
    }
  } else if (!isEditable && smallPreview) {
    gridTemplateColumns = `minmax(250px, auto)`
  } else {
    gridTemplateColumns = '1fr'
  }

  return (
    <Flex flexDirection="column" height="100%">
      <PreviewPromptComponent
        text={previewPromptMessage.text}
        isShown={isEditable || !previewPromptMessage.isHidden}
        type={previewPromptMessage.type}
      />
      <Grid gridGap="m" gridTemplateColumns={gridTemplateColumns} height="100%">
        {hasPreview && (
          <Flex flexDirection="column" width="100%" height="100%" position="relative">
            <StyledFlex
              minHeight="max-content"
              flexDirection="column"
              isEditable={smallPreview}
              isPostFormDirty={isPostFormDirty}
            >
              {isPostFormDirty ? (
                <StyledWrapper>
                  <Flex width="100%" pt="1em" mb="0.25em">
                    <Box width={`${PROFILE_IMAGE_SIZE}`} pt="0.25em">
                      {isEditable ? (
                        <StyledImg
                          source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                          fallbackSource={fallbackLogoImage}
                        />
                      ) : (
                        <SocialProfileImage
                          code={network}
                          picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                          width={`${PROFILE_IMAGE_SIZE}`}
                          height={`${PROFILE_IMAGE_SIZE}`}
                          imageBorderRadius={radius.pill}
                        />
                      )}
                    </Box>
                    <Flex pl="0.5em" flexDirection="column" width={`calc(100% - ${PROFILE_IMAGE_SIZE})`}>
                      <Flex flexDirection="column" justifyContent="center">
                        <Flex alignItems="center" flexWrap="wrap">
                          <Flex
                            alignItems="center"
                            onMouseEnter={(e) => {
                              if (!isMultipleProfilesPreview && isEditable) {
                                tooltipRef.current.handleShowTooltip({
                                  contentComp: (
                                    <ProfileItemTooltip
                                      profile={{
                                        code: network,
                                        picture_url: profilePictureUrl,
                                        profile_url: profileUrl,
                                        name: profileName,
                                        username: profileUsername,
                                        display,
                                        profile_id,
                                        type: profile_type,
                                      }}
                                      message=""
                                    />
                                  ),
                                  wrapperComp: e.currentTarget,
                                })
                              }
                            }}
                            onMouseLeave={() => {
                              tooltipRef.current.handleHideTooltip()
                            }}
                          >
                            <StyledLink
                              color={THREADS_COLORS.MAIN}
                              fontWeight="bold"
                              as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                              href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                              target="_blank"
                              fontSize="0.9375em"
                            >
                              {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                            </StyledLink>
                          </Flex>
                          &nbsp;
                          <StyledLink
                            as={published_link ? 'a' : 'span'}
                            href={published_link || ''}
                            target="_blank"
                            color={THREADS_COLORS.TARNISHED_SILVER}
                            fontSize="0.9375em"
                          >
                            {`${publish_at ? `${moment(publish_at).fromNow()}` : 'Now'}`}
                          </StyledLink>
                        </Flex>
                      </Flex>

                      <Flex width="100%" flexDirection="column">
                        <Flex
                          flexDirection="column"
                          width="100%"
                          onClick={() => {
                            if (published_link) {
                              window.open(published_link)
                            }
                          }}
                        >
                          <Text
                            dangerouslySetInnerHTML={{
                              __html: transformed_text_to_html_for_textarea,
                            }}
                            mt="0.1875em"
                            color={THREADS_COLORS.MAIN}
                            whiteSpace="pre-line"
                            fontSize="0.9375em"
                          />
                          {customizedPostComponent && sourceTypeTemplate ? (
                            <Box mt="0.5em" mb="0.5em">
                              {sourceTypeTemplate}
                            </Box>
                          ) : (
                            <Box mt="0.5em" mb="0.5em" />
                          )}
                        </Flex>
                      </Flex>

                      {generateThreadControlsTemplate({ c_likes, c_comments, c_reposts, c_shares })}
                    </Flex>
                  </Flex>

                  {comments.map((comment) => {
                    const { id, message, postComponent, postImages, postVideos, postMixedMedias } = comment

                    if (
                      !message &&
                      (!postImages || postImages.length === 0) &&
                      (!postVideos || postVideos.length === 0) &&
                      (!postMixedMedias || postMixedMedias.length === 0)
                    ) {
                      return null
                    }

                    const { variableTransformedText } = variableTransformer({
                      str: message,
                      savedVariables,
                      post,
                      profileId,
                    })

                    const sourceTypeTemplate = generateSourceTypeTemplate({
                      customizedPostComponent: postComponent,
                      post: { id, postText: message },
                      slideToIndex: 0,
                      postImagesUpdated: postImages || [],
                      postVideosUpdated: postVideos || [],
                      postMixedMediasUpdated: postMixedMedias || [],
                      link: null,
                    })

                    return (
                      <Flex key={id} width="100%" pt="1em" mb="0.25em">
                        <Box width={`${PROFILE_IMAGE_SIZE}`} pt="0.25em">
                          {isEditable ? (
                            <StyledImg
                              source={isMultipleProfilesPreview ? fallbackLogoImage : profilePictureUrl}
                              fallbackSource={fallbackLogoImage}
                            />
                          ) : (
                            <SocialProfileImage
                              code={network}
                              picture_url={isMultipleProfilesPreview ? null : profilePictureUrl}
                              width={`${PROFILE_IMAGE_SIZE}`}
                              height={`${PROFILE_IMAGE_SIZE}`}
                              imageBorderRadius={radius.pill}
                            />
                          )}
                        </Box>
                        <Flex pl="0.5em" flexDirection="column" width={`calc(100% - ${PROFILE_IMAGE_SIZE})`}>
                          <Flex alignItems="center" flexWrap="wrap" width="100%">
                            <StyledLink
                              color={THREADS_COLORS.MAIN}
                              fontWeight="bold"
                              as={!isMultipleProfilesPreview && profileUrl ? 'a' : 'span'}
                              href={!isMultipleProfilesPreview && profileUrl ? profileUrl : ''}
                              target="_blank"
                              fontSize="0.9375em"
                            >
                              {isMultipleProfilesPreview ? `Multiple ${display} Profiles` : profileName}
                            </StyledLink>
                            &nbsp;
                            <StyledLink as="span" color={THREADS_COLORS.TARNISHED_SILVER} fontSize="0.9375em">
                              {`${publish_at ? `${moment(publish_at).fromNow()}` : 'Now'}`}
                            </StyledLink>
                          </Flex>

                          <Flex width="100%" flexDirection="column">
                            <Flex flexDirection="column" width="100%">
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: variableTransformedText,
                                }}
                                mt="0.1875em"
                                color={THREADS_COLORS.MAIN}
                                whiteSpace="pre-line"
                                fontSize="0.9375em"
                              />
                              {postComponent && sourceTypeTemplate ? (
                                <Box mt="0.5em" mb="0.5em">
                                  {sourceTypeTemplate}
                                </Box>
                              ) : (
                                <Box mt="0.5em" mb="0.5em" />
                              )}
                            </Flex>
                          </Flex>

                          {generateThreadControlsTemplate({ c_likes: 0, c_comments: 0, c_reposts: 0, c_shares: 0 })}
                        </Flex>
                      </Flex>
                    )
                  })}
                </StyledWrapper>
              ) : (
                <Box mt="m" px="s" mb="s" height="100%">
                  <NoPost />
                </Box>
              )}
            </StyledFlex>
          </Flex>
        )}

        {isEditable && (
          <Flex
            minHeight="max-content"
            flexDirection="column"
            height="100%"
            bg={COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL}
            borderRadius={radius.l}
          >
            <CustomizationsSlider
              user={user}
              isCustomizationsOpen={isCustomizationsOpen}
              setIsCustomizationsOpen={setIsCustomizationsOpen}
              post={post}
              profile={profile}
              CustomizationsComponent={
                <Flex
                  flexDirection="column"
                  bg={COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL}
                  borderRadius={radius.l}
                  px="m"
                  py="m"
                >
                  {hasCustomCaption && (
                    <Flex alignItems="center" width="100%">
                      <Flex flexDirection="column" width="100%">
                        {isPostTextEditOpen ? (
                          <BodyWrapper mr="s">
                            <CloseIconWrapper onClick={() => setIsPostTextEditOpen(false)}>
                              <Image width="8px" height="8px" src="/assets/clear.svg" />
                            </CloseIconWrapper>
                            <Flex flexDirection="column">
                              <TextAreaComponentWithControls
                                user={user}
                                handleUpdateText={(text) => {
                                  if (text === postText) {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: '' })
                                  } else {
                                    handleUpdateCustomizationDebounce({ type: 'message', value: text })
                                  }
                                }}
                                text={updatedPostText}
                                textMaxLimitNumber={MAX_TEXT_LENGTH}
                                textVariantOffsets={textVariantOffsets}
                                textareaPlaceholder="Write your content ..."
                                errorText=""
                                selectedEntities={selectedEntities}
                                selectedProfiles={selectedProfiles}
                                counterComp={
                                  <Counter
                                    counter={updatedPostTextWithMentions.length}
                                    max={MAX_TEXT_LENGTH}
                                    fontSize="xxs"
                                  />
                                }
                              />
                            </Flex>
                          </BodyWrapper>
                        ) : (
                          <StyledCaptionWrapper
                            onClick={() => {
                              if (isEditable) {
                                setIsPostTextEditOpen(true)
                              }
                            }}
                          >
                            <BodyWrapper>
                              <Text
                                dangerouslySetInnerHTML={{
                                  __html: transformed_text_to_html_for_textarea || 'Click to edit caption',
                                }}
                                fontSize="xs"
                                whiteSpace="pre-line"
                                color={
                                  transformed_text_to_html_for_textarea
                                    ? COLOR_CONSTANTS.DENIM
                                    : COLOR_CONSTANTS.COSMIC_ENERGY
                                }
                              />
                              <Box>
                                <Counter counter={updatedPostText.length} max={MAX_TEXT_LENGTH} fontSize="xxs" />
                              </Box>
                            </BodyWrapper>
                          </StyledCaptionWrapper>
                        )}
                      </Flex>
                      <TooltipPromptComponent prompt_key="caption" network={THREADS} />
                    </Flex>
                  )}

                  {hasCustomMedias && (
                    <Fragment>
                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <CustomizationsMediaComponent
                            uploadMediaComponentRef={uploadMediaComponentRef}
                            user={user}
                            network={network}
                            profileId={profileId}
                            selectedProfiles={selectedProfiles}
                            selectedEntities={selectedEntities}
                            customized_post_data={{
                              postComponent: customizedPostComponent,
                              postImages: customizedPostImages,
                              postVideos: customizedPostVideos,
                              postMixedMedias: customizedPostMixedMedias,
                            }}
                            showLinkComponent={false}
                            showDocumentComponent={false}
                            mediasPreviewDisplay={
                              (customizedPostComponent === POST_IMAGE &&
                                customizedPostImages &&
                                customizedPostImages.length !== 0) ||
                              (customizedPostComponent === POST_VIDEO &&
                                customizedPostVideos &&
                                customizedPostVideos.length !== 0) ||
                              (customizedPostComponent === POST_MIXED_MEDIA &&
                                customizedPostMixedMedias &&
                                customizedPostMixedMedias.length !== 0)
                                ? 'block'
                                : 'none'
                            }
                            mediasPreviewSizeSmall
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="media" />
                      </Flex>
                    </Fragment>
                  )}

                  {hasMobileNotificationOption && (
                    <PublicationOptionComponent
                      handleUpdateCustomization={handleUpdateCustomization}
                      publishing_option={publishing_option}
                      device_gid={device_gid}
                      devices={devices}
                      notificationDeviceLengthLimit={notificationDeviceLengthLimit}
                    />
                  )}

                  <Flex mt="m" minHeight="max-content" flexDirection="column" position="relative">
                    {featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled && (
                      <CustomizationsBlockComponent
                        showUpgradeMessage={false}
                        showUpgradePlan={false}
                        message={featuresEnabled[FEATURE_POST_ENGAGEMENT].description}
                      />
                    )}
                    <Flex
                      flexDirection="column"
                      px={
                        featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                          ? 's'
                          : '0'
                      }
                      py={
                        featuresEnabled[FEATURE_POST_ENGAGEMENT] && !featuresEnabled[FEATURE_POST_ENGAGEMENT].enabled
                          ? 'm'
                          : '0'
                      }
                    >
                      <Flex alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <CommentsComponent
                            user={user}
                            network={THREADS}
                            profileId={profileId}
                            handleUpdateCustomization={handleUpdateCustomization}
                            handleUpdateCustomizationDebounce={handleUpdateCustomizationDebounce}
                            commentTextMaxLimitNumber={MAX_COMMENT_LENGTH}
                            comments={comments}
                            commentTextMaxLimit={commentTextMaxLimit}
                            selectedEntities={selectedEntities}
                            selectedProfiles={selectedProfiles}
                            textVariantOffsets={textVariantOffsets}
                            showMediaPicker={!blockCommentsMediaAttachment}
                            showImageComponent
                            showVideoComponent
                            showMixedMediaComponent
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="comments" />
                      </Flex>

                      <Flex mt="m" alignItems="center" width="100%">
                        <Flex flexDirection="column" width="100%">
                          <DropDown
                            placeholder="Who can reply?"
                            value={
                              reply_control
                                ? REPLY_CONTROL_DEFAULT_VALUES.find(({ value }) => value === reply_control)
                                : REPLY_CONTROL_DEFAULT_VALUES[0]
                            }
                            onChange={(option) => {
                              handleUpdateCustomization({
                                type: 'reply_control',
                                value: option.value,
                              })
                            }}
                            options={REPLY_CONTROL_DEFAULT_VALUES}
                            openMenuOnFocus
                            isSearchable={false}
                            menuPlacement="top"
                          />
                        </Flex>
                        <TooltipPromptComponent prompt_key="reply_control" network={THREADS} />
                      </Flex>

                      {/*This is for a link image to be published as image, not sure if we already support this*/}
                      {/*{customizedPostComponent === POST_LINK && link && link.picture_url && (*/}
                      {/*  <Flex alignItems="center" width="100%">*/}
                      {/*    <Flex flexDirection="column" width="100%">*/}
                      {/*      <Switch*/}
                      {/*        isOn={!!publish_as_image}*/}
                      {/*        onClick={() => {*/}
                      {/*          handleUpdateCustomization({*/}
                      {/*            type: 'publish_as_image',*/}
                      {/*            value: !publish_as_image,*/}
                      {/*          })*/}
                      {/*        }}*/}
                      {/*        text="Publish as image"*/}
                      {/*        color={COLOR_CONSTANTS.DENIM}*/}
                      {/*        fontWeight="normal"*/}
                      {/*      />*/}
                      {/*    </Flex>*/}
                      {/*    <TooltipPromptComponent prompt_key="publish_as_image" network={THREADS} />*/}
                      {/*  </Flex>*/}
                      {/*)}*/}
                    </Flex>
                  </Flex>

                  {applyCustomizationsToAllNetworksTemplate && (
                    <Flex width="100%" mt="m">
                      {applyCustomizationsToAllNetworksTemplate}
                    </Flex>
                  )}
                </Flex>
              }
            />
          </Flex>
        )}
      </Grid>
      <CalendarItemTooltip ref={tooltipRef} />
    </Flex>
  )
}

ThreadsPost.defaultProps = {
  post: {},
  profile: {},
  onChangeUpdatePost: () => {},
  dataForCustomizations: {},
  setDataForCustomizations: () => {},
  isPostFormDirty: false,
  postErrors: { networksErrors: {} },
  agencyMentions: [],
  isEditable: true,
  noVideoAttachment: false,
  hasPreview: true,
  featuresEnabled: {},
  previewProfileNumber: 1,
  selectedEntities: [],
  devices: [],
  savedVariables: [],
  applyCustomizationsToAllNetworksTemplate: null,
  hasCustomCaption: true,
  hasCustomMedias: true,
  forceCloseNetworkPreviewCaption: null,
  boosts: [],
  blockCommentsMediaAttachment: false,
  isSmallPreview: false,
}

ThreadsPost.propTypes = {
  user: PropTypes.object.isRequired,
  post: PropTypes.object,
  profile: PropTypes.object,
  isPostFormDirty: PropTypes.bool,
  onChangeUpdatePost: PropTypes.func,
  dataForCustomizations: PropTypes.object,
  setDataForCustomizations: PropTypes.func,
  postErrors: PropTypes.object,
  agencyMentions: PropTypes.array,
  isEditable: PropTypes.bool,
  noVideoAttachment: PropTypes.bool,
  hasPreview: PropTypes.bool,
  featuresEnabled: PropTypes.object,
  previewProfileNumber: PropTypes.number,
  selectedEntities: PropTypes.array,
  selectedProfiles: PropTypes.array,
  devices: PropTypes.array,
  savedVariables: PropTypes.array,
  applyCustomizationsToAllNetworksTemplate: PropTypes.node,
  hasCustomCaption: PropTypes.bool,
  hasCustomMedias: PropTypes.bool,
  forceCloseNetworkPreviewCaption: PropTypes.number,
  boosts: PropTypes.array,
  blockCommentsMediaAttachment: PropTypes.bool,
  isSmallPreview: PropTypes.bool,
}

ThreadsPost.displayName = 'ThreadsPost'

export default ThreadsPost
