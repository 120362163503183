import React, { useState, useEffect, useRef, Fragment } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import Alert from 'react-s-alert'
import Modal from '@material-ui/core/Modal'
import ClipLoader from 'react-spinners/ClipLoader'
import moment from 'moment-timezone'
import { COLOR_CONSTANTS, radius, colors, space } from 'theme'
import {
  ERROR_MESSAGE,
  IMAGE,
  VIDEO,
  IMAGE_DYNAMIC,
  VIDEO_DYNAMIC,
  INSTAGRAM,
  TIKTOK,
  PROFILE_STATUSES,
  FEATURE_NETWORK_PLANNER,
  FEATURE_QUEUE_LABELS,
  PROFILE_TYPE_CREATOR,
  PROFILE_TYPE_PROFILE,
  DOCUMENT,
} from 'consts'
import request from 'utils/request'
import withConfirm from 'utils/withConfirm'
import { checkIfPlanHasFeatureEnabled } from 'utils/feature'
import errorHelper from 'utils/errorHelper'
import { H4, Text } from 'components/atoms/Typography'
import { Flex, Box } from 'components/atoms/Layout'
import Button from 'components/atoms/Button'
import Image from 'components/atoms/Image'
import NoData from 'components/molecules/NoData'
import ButtonWithLoading from 'components/molecules/ButtonWithLoading'
// eslint-disable-next-line import/no-cycle
import MediaBodyComponent from 'routes/Media/MediaBodyComponent'
import { getProfileFlavor } from 'routes/Settings/Profiles/helpers'
import PhoneComponent from './components/PhoneComponent'
import SelectedProfilesSlider from './components/SelectedProfilesSlider'
import PostsComponent from './components/PostsComponent'
// eslint-disable-next-line import/no-cycle
import BulkEditPostModal from '../BulkModal/BulkEditPostModal'
import {
  POST_IMAGE,
  POST_VIDEO,
  ROUTE_MENTIONS,
  ROUTE_QUEUE,
  ROUTE_PUBLISH,
  QUEUE_LAST,
  NETWORK_LIMITS,
  POST_MEDIA_CLASS_NAME,
  POST_LINK,
  ROUTE_CALENDAR,
  APPROVED,
  DRAFT,
  PUBLISH_AS_STORY,
  PUBLISH_AS_REELS,
  PUBLISH_AS_VIDEO,
  PUBLISH_AS_IMAGE,
  CUSTOM_TYPE,
  DATE_FORMAT,
  DEFAULT_QUEUE_LABEL,
  PUBLISHING_OPTION_NOTIFICATION,
  POST_MIXED_MEDIA,
  PUBLISH_AS_CAROUSEL,
} from '../../consts'
import {
  deHibernateMedias,
  getEntities,
  mentionsTransformer,
  collectPostAttachments,
  collectAdvocacyDataBeforePublishing,
} from '../../helpers'
import { GRID_TYPE_FEED, GRID_TYPE_REELS } from './consts'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    position: relative;
    max-width: 100%;
    width: 100%;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: 0 auto;
    height: 100%;
    flex-direction: column;
    background-color: ${COLOR_CONSTANTS.WHITE};
  }
`

const StyledDialogEnvironmentWrapper = styled(Flex)`
  border: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  background-color: ${COLOR_CONSTANTS.BOYSENBERRY_SHADOW};
  ${({ $isTop }) => $isTop && `border-radius: ${radius.l} ${radius.l} 0 0;`}
  ${({ $isBottom }) => $isBottom && `border-radius: 0 0 ${radius.l} ${radius.l};`}
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow: hidden;
  height: 100%;
  outline: none;
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 16;
  background-color: ${COLOR_CONSTANTS.DAISY};
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledCenterColumnWrapper = styled(Flex)`
  border-left: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  border-right: 1px solid ${COLOR_CONSTANTS.SOLITUDE};
  flex: 1;
  justify-content: flex-start;
  align-items: center;
  margin: 0 ${space.s};
`

const StyledLearnMore = styled(Text)`
  text-decoration: none;
`

const EDIT_POST_MODAL_OPTIONS = {
  isOpen: false,
  postForEdit: {},
}

const DEFAULT_TYPES = {
  [INSTAGRAM]: [
    { value: IMAGE, label: 'Images' },
    { value: VIDEO, label: 'Videos' },
  ],
  [TIKTOK]: [
    { value: IMAGE, label: 'Images' },
    { value: VIDEO, label: 'Videos' },
  ],
}

const NetworkPlannerModal = ({
  user,
  isOpen,
  handleDismiss,
  selectedProfiles,
  selectedEntities,
  network,
  confirm,
  handleClickReloadCalendar,
}) => {
  let networkName = 'Network'
  if (network === INSTAGRAM) {
    networkName = 'Instagram'
  } else if (network === TIKTOK) {
    networkName = 'TikTok'
  }

  const mediaComponentRef = useRef(null)
  const phoneComponentRef = useRef(null)

  const { video_processing: user_settings_video_processing = true } = user.settings || {}

  const [entities, setEntities] = useState([])
  const [profiles, setProfiles] = useState([])
  const [activeProfile, setActiveProfile] = useState(null)
  const [postsByProfile, setPostsByProfile] = useState({})
  const [isGettingData, setIsGettingData] = useState(true)
  const [agencyMentionsOptions, setAgencyMentionsOptions] = useState({ isLoading: false, data: [] })
  const [editPostModalOptions, setEditPostModalOptions] = useState({ ...EDIT_POST_MODAL_OPTIONS })
  const [isUpdatingPosts, setIsUpdatingPosts] = useState(false)
  const [dragMediaOptions, setDragMediaOptions] = useState({ dragMedia: null, isActive: false })
  const [featuresEnabled, setFeaturesEnabled] = useState({
    [FEATURE_NETWORK_PLANNER]: { enabled: true },
    [FEATURE_QUEUE_LABELS]: { enabled: true },
  })
  const [usedQueueLabelsByProfile, setUsedQueueLabelsByProfile] = useState({})
  const [selectedQueueLabelByProfile, setSelectedQueueLabelByProfile] = useState({})

  const getQueueData = async ({ posts, targets }) => {
    let queue_time = QUEUE_LAST
    let queue_time_time
    let response_posts = []

    const body = { posts, targets, queue_labels: [] }

    const { [activeProfile.id]: selectedQueueLabel } = selectedQueueLabelByProfile

    if (selectedQueueLabel && selectedQueueLabel.value) {
      body.queue_labels.push(selectedQueueLabel.value)
    }

    try {
      const response = await request({
        method: 'POST',
        path: `${ROUTE_QUEUE}/times`,
        body,
      })
      if (response && !response.error && response[0]) {
        response_posts = response[0].posts
        queue_time = response[0].queue_time || QUEUE_LAST
        // eslint-disable-next-line prefer-destructuring
        queue_time_time = response[0].queue_time_time
      }
    } catch (error) {
      errorHelper({ error, componentName: NetworkPlannerModal.displayName, functionName: 'getQueueData' })
    } finally {
      const { profile_gid, postId } = targets[0]
      const foundPostIndex = postsByProfile[profile_gid].posts.findIndex(({ id }) => id === postId)
      if (foundPostIndex > -1) {
        postsByProfile[profile_gid].posts[foundPostIndex].publish_at = queue_time || QUEUE_LAST
        postsByProfile[profile_gid].posts[foundPostIndex].publish_at_time = queue_time_time
      }
      response_posts.forEach(({ publication_gid, publish_at_str, publish_at_time }) => {
        const foundPostIndex = postsByProfile[profile_gid].posts.findIndex(({ id }) => id === publication_gid)
        if (foundPostIndex > -1) {
          if (postsByProfile[profile_gid].posts[foundPostIndex].publish_at_time !== publish_at_time) {
            postsByProfile[profile_gid].posts[foundPostIndex].hasChanged = true
          }
          postsByProfile[profile_gid].posts[foundPostIndex].publish_at = publish_at_str
          postsByProfile[profile_gid].posts[foundPostIndex].publish_at_time = publish_at_time
        }
      })
      postsByProfile[profile_gid].posts.sort((x, y) => y.publish_at_time - x.publish_at_time)
      setPostsByProfile({ ...postsByProfile })
    }
  }

  const getQueueLabels = async () => {
    try {
      const response = await request({
        method: 'POST',
        path: `${ROUTE_QUEUE}/labels`,
        body: {
          targets: [{ profile_gid: activeProfile.id, entityId: activeProfile.entityId }],
        },
      })
      if (response && !response.error) {
        const { labels = [] } = response || {}
        usedQueueLabelsByProfile[activeProfile.id] = [
          DEFAULT_QUEUE_LABEL,
          ...labels.map((label) => ({ value: label, label: `${label} label` })),
        ]
        setUsedQueueLabelsByProfile(usedQueueLabelsByProfile)
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
      errorHelper({
        error,
        componentName: NetworkPlannerModal.displayName,
        functionName: 'getQueueLabels',
        showAlert: false,
      })
    }
  }

  const getEntitiesData = async () => {
    await getEntities({
      user,
      setEntities,
      setEntitySelectorKey: () => {},
      setSelectedProfiles: () => {},
      setSelectedEntities: () => {},
      // eslint-disable-next-line no-use-before-define
      handleSaveSelectedEntities: () => {},
    })

    const networkProfilesTemp = {}
    let selectedProfilesTemp = {}

    selectedEntities.forEach(({ profiles = [], timezone, id: entityId, type: entityType }) => {
      profiles.forEach((profile) => {
        const {
          id,
          network: { code },
          status,
        } = profile
        if (
          code === network &&
          !networkProfilesTemp[id] &&
          !selectedProfilesTemp[id] &&
          status !== PROFILE_STATUSES.BLOCKED
        ) {
          profile.timezone = timezone
          profile.entityId = entityId
          profile.code = profile.network.code
          profile.display = profile.network.display
          profile.entityType = entityType
          profile.searchedForPublications = false
          if (selectedProfiles.findIndex((item) => item.id === id) > -1) {
            selectedProfilesTemp[id] = profile
          } else {
            networkProfilesTemp[id] = profile
          }
        }
      })
    })

    selectedProfilesTemp = Object.values(selectedProfilesTemp)

    const profiles = [...selectedProfilesTemp, ...Object.values(networkProfilesTemp)]

    let foundActiveProfile = selectedProfilesTemp[0]

    if (!foundActiveProfile) {
      for (let i = 0; i < selectedEntities.length; i++) {
        foundActiveProfile = selectedEntities[i].profiles.find(
          ({ network: { code }, status }) => code === network && status !== PROFILE_STATUSES.BLOCKED
        )
        if (foundActiveProfile) {
          break
        }
      }
    }

    if (foundActiveProfile) {
      foundActiveProfile = profiles.find(({ id }) => id === foundActiveProfile.id)
    }

    setProfiles([...profiles])
    if (foundActiveProfile) {
      setActiveProfile({ ...foundActiveProfile })
    }
    setIsGettingData(false)
  }

  const getMentions = async () => {
    let response = []
    try {
      setAgencyMentionsOptions({ ...{ isLoading: true, data: [] } })

      response = await request({
        method: 'GET',
        path: ROUTE_MENTIONS,
      })

      if (!response || response.error) {
        response = []
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: NetworkPlannerModal.displayName,
        functionName: 'getMentions',
      })
    } finally {
      setAgencyMentionsOptions({ ...{ isLoading: false, data: response } })
    }
  }

  const getScheduledPublications = async () => {
    const { id, timezone } = activeProfile

    if (!postsByProfile[id]) {
      postsByProfile[id] = {
        ...activeProfile,
        posts: [],
        isGettingPublications: true,
      }
    }

    setPostsByProfile({ ...postsByProfile })

    try {
      const response = await request({
        path: `${ROUTE_CALENDAR}/network-planner/posts?timezone=${timezone}&profileIds=${id}`,
      })
      const { events, error } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else if (events && events.length !== 0) {
        // documents are not supported by Instagram and TikTok
        postsByProfile[id].posts = events.filter((post) => {
          const {
            status,
            postImages = [],
            postVideos = [],
            postMixedMedias = [],
            link,
            calendar_default_start,
            title,
          } = post
          post.publish_at = calendar_default_start
          post.postText = title

          if (status) {
            if (postImages.length !== 0) {
              post.postComponent = POST_IMAGE
            } else if (postVideos.length !== 0) {
              post.postComponent = POST_VIDEO
            } else if (postMixedMedias.length !== 0) {
              post.postComponent = POST_MIXED_MEDIA
            } else if (link && link.active) {
              post.postComponent = POST_LINK
            }
            return post
          } else {
            return false
          }
        })
      }
      // eslint-disable-next-line no-empty
    } catch (error) {
      errorHelper({
        error,
        componentName: NetworkPlannerModal.displayName,
        functionName: 'getScheduledPublications',
        showAlert: false,
      })
    } finally {
      postsByProfile[id].posts.sort((x, y) => y.publish_at_time - x.publish_at_time)
      postsByProfile[id].hasStory = postsByProfile[id].posts.find((post) => {
        const { [activeProfile.code]: { [id]: { media_type } = {} } = {} } = post.customizations || {}
        if (media_type === PUBLISH_AS_STORY) {
          return post
        } else return null
      })
      postsByProfile[id].isGettingPublications = false
      postsByProfile[id].searchedForPublications = true
      setPostsByProfile({ ...postsByProfile })
    }
  }

  useEffect(() => {
    if (isOpen) {
      setFeaturesEnabled({
        ...checkIfPlanHasFeatureEnabled({ user, features: [FEATURE_NETWORK_PLANNER, FEATURE_QUEUE_LABELS] }),
      })
      getEntitiesData()
      getMentions()
    }
  }, [isOpen])

  useEffect(() => {
    if (activeProfile && featuresEnabled[FEATURE_NETWORK_PLANNER].enabled) {
      const { id, entityId, timezone } = activeProfile

      const { posts = [] } = postsByProfile[id] || {}
      if (posts && posts.length !== 0) {
        const all_posts = posts
          .filter(({ publish_at }) => publish_at)
          .map(({ id, publish_at }) => ({ publication_gid: id, publish_at }))

        posts.forEach(({ id: postId, publish_at }) => {
          if (!publish_at) {
            getQueueData({
              posts: all_posts,
              targets: [{ profile_gid: id, timezone, publish_at: publish_at || QUEUE_LAST, entityId, postId }],
            })
          }
        })
      }
    }
  }, [postsByProfile])

  useEffect(() => {
    if (activeProfile) {
      if (!postsByProfile[activeProfile.id] || !postsByProfile[activeProfile.id].searchedForPublications) {
        getScheduledPublications()
      }
      if (!usedQueueLabelsByProfile[activeProfile.id]) {
        getQueueLabels()
      }
    }
  }, [activeProfile])

  const handleClickSelectProfile = ({ profile }) => {
    setActiveProfile({ ...profile })
  }

  const onDropMedia = ({ dropMediaType, media }) => {
    if (activeProfile) {
      const { code, id } = activeProfile
      const { isGettingPublications } = postsByProfile[id] || {}
      if (!isGettingPublications) {
        const profileType = getProfileFlavor({ profile: activeProfile })

        let updatedDropMediaType = dropMediaType
        if (!dropMediaType) {
          updatedDropMediaType = phoneComponentRef.current.getShowGridType()
        }

        const updatedMedia = media || dragMediaOptions.dragMedia

        if (updatedMedia) {
          const { type, hibernated } = updatedMedia

          if (type !== DOCUMENT) {
            if (
              network === TIKTOK ||
              (network === INSTAGRAM &&
                (((type === VIDEO || type === VIDEO_DYNAMIC) && updatedDropMediaType === GRID_TYPE_REELS) ||
                  updatedDropMediaType === GRID_TYPE_FEED ||
                  updatedDropMediaType === PUBLISH_AS_STORY))
            ) {
              const post = {
                id: `${new Date().getTime()}`,
                isNew: true,
                postText: '',
                postImages: [],
                postVideos: [],
                postMixedMedias: [],
                customizations: { [code]: { [id]: {} } },
                hasChanged: true,
                selectedQueueLabel: selectedQueueLabelByProfile[id],
              }
              if (type === IMAGE || type === IMAGE_DYNAMIC) {
                post.postImages.push(updatedMedia)
                post.postComponent = POST_IMAGE
                if (network === INSTAGRAM) {
                  post.customizations[code][id].media_type = PUBLISH_AS_IMAGE
                }

                post.postMixedMedias.push(updatedMedia)
              } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
                if (hibernated) {
                  deHibernateMedias({ medias: [updatedMedia] })

                  updatedMedia.hibernated = false
                }

                post.postVideos.push(updatedMedia)
                post.postComponent = POST_VIDEO
                if (network === INSTAGRAM) {
                  post.customizations[code][id].media_type = PUBLISH_AS_VIDEO
                }
                post.postMixedMedias.push(updatedMedia)
              }

              if (updatedDropMediaType === GRID_TYPE_REELS) {
                post.customizations[code][id].media_type = PUBLISH_AS_REELS
                post.customizations[code][id].share_to_feed = true
              } else if (updatedDropMediaType === PUBLISH_AS_STORY) {
                post.customizations[code][id].media_type = PUBLISH_AS_STORY
                postsByProfile[activeProfile.id].hasStory = true
              }

              if (network === INSTAGRAM) {
                if (
                  profileType === PROFILE_TYPE_PROFILE ||
                  (profileType === PROFILE_TYPE_CREATOR &&
                    post.customizations[code][id].media_type === PUBLISH_AS_STORY)
                ) {
                  post.customizations[code][id].publishing_option = PUBLISHING_OPTION_NOTIFICATION
                }
              }

              postsByProfile[activeProfile.id].posts.unshift(post)

              setPostsByProfile({ ...postsByProfile })
            } else if (network === INSTAGRAM) {
              Alert.error(`Only videos are supported when publishing reels.`, { timeout: 5000 })
            }
          } else {
            Alert.error(`Only images and videos are supported for publishing.`, { timeout: 5000 })
          }
        }
      } else {
        Alert.error(`Please wait a bit while we finish collecting data`, { timeout: 5000 })
      }
    } else {
      Alert.error(`Please select at least one ${networkName} profile.`, { timeout: 5000 })
    }
    setDragMediaOptions({ ...{ dragMedia: null, isActive: false } })
  }

  const handleClickRemovePost = ({ profile_gid, post_gid }) => {
    const foundPostIndex = postsByProfile[profile_gid].posts.findIndex(({ id }) => id === post_gid)
    if (foundPostIndex > -1) {
      if (postsByProfile[profile_gid].posts[foundPostIndex].publication_group_id) {
        postsByProfile[profile_gid].posts[foundPostIndex].isRemoved = !postsByProfile[profile_gid].posts[foundPostIndex]
          .isRemoved
      } else {
        postsByProfile[profile_gid].posts.splice(foundPostIndex, 1)
      }

      postsByProfile[profile_gid].hasStory = postsByProfile[profile_gid].posts.find((post) => {
        if (!post.isRemoved) {
          const { [activeProfile.code]: { [profile_gid]: { media_type } = {} } = {} } = post.customizations || {}
          if (media_type === PUBLISH_AS_STORY) {
            return post
          }
        }

        return null
      })

      setPostsByProfile({ ...postsByProfile })
    }
  }

  const handleClickEditPost = ({ profile_gid, post, timezone }) => {
    post.timezone = timezone
    post.profile_gid = profile_gid

    setEditPostModalOptions({
      ...{
        isOpen: true,
        postForEdit: { ...JSON.parse(JSON.stringify(post)), agencyMentions: agencyMentionsOptions.data },
      },
    })
  }

  const handleClickCloseEditPostModal = () => {
    setEditPostModalOptions({ ...EDIT_POST_MODAL_OPTIONS })
  }

  const handleClickUpdatePost = (post) => {
    const { profile_gid, id: postId, postComponent, postImages = [], postVideos = [], postMixedMedias = [] } = post
    const foundPostIndex = postsByProfile[profile_gid].posts.findIndex(({ id }) => id === post.id)
    if (foundPostIndex > -1) {
      delete post.agencyMentions
      delete post.errors

      if (post.publish_at.toLowerCase().includes('queue') || post.publish_at.toLowerCase().includes('draft')) {
        post.publish_at_time = null
      } else {
        post.publish_at = moment(post.publish_at, `${DATE_FORMAT} HH:mm`).format('YYYY-MM-DDTHH:mm')
        post.publish_at_time = moment.tz(post.publish_at, true, post.timezone).valueOf()

        if (post.status === DRAFT) {
          delete post.draft
          post.status = APPROVED
        }
      }
      post.hasChanged = true

      postsByProfile[profile_gid].posts[foundPostIndex] = post

      postsByProfile[profile_gid].posts.sort((x, y) => y.publish_at_time - x.publish_at_time)

      postsByProfile[profile_gid].hasStory = postsByProfile[profile_gid].posts.find((post) => {
        const { [activeProfile.code]: { [profile_gid]: { media_type } = {} } = {} } = post.customizations || {}
        if (media_type === PUBLISH_AS_STORY) {
          return post
        } else return null
      })

      let post_medias_type

      if (postComponent === POST_IMAGE && postImages && postImages.length !== 0) {
        post_medias_type = 'postImages'
      } else if (postComponent === POST_VIDEO && postVideos && postVideos.length !== 0) {
        post_medias_type = 'postVideos'
      } else if (postComponent === POST_MIXED_MEDIA && postMixedMedias && postMixedMedias.length !== 0) {
        post_medias_type = 'postMixedMedias'
      }

      const post_medias = post[post_medias_type]

      if (post_medias && post_medias.length !== 0) {
        let hasUpdatedUploadedMedias = false
        let uploadedMedias = []

        post_medias.forEach((media) => {
          const { id } = media
          // eslint-disable-next-line guard-for-in
          for (const profileId in postsByProfile) {
            const { posts = [] } = postsByProfile[profileId]
            if (posts && posts.length !== 0) {
              posts.forEach((post) => {
                if (post[post_medias_type] && post[post_medias_type].length !== 0) {
                  const foundMediaIndex = post[post_medias_type].findIndex((media) => media.id === id)
                  if (foundMediaIndex > -1) {
                    post[post_medias_type][foundMediaIndex] = media
                  }
                }
              })
            }
          }

          uploadedMedias = mediaComponentRef.current.getMedias()
          const foundUploadedMediasIndex = uploadedMedias.findIndex((media) => media.id === id)
          if (foundUploadedMediasIndex > -1) {
            uploadedMedias[foundUploadedMediasIndex] = media
            hasUpdatedUploadedMedias = true
          }
        })

        if (hasUpdatedUploadedMedias) {
          mediaComponentRef.current.setMedias({ medias: uploadedMedias })
        }
      }

      setPostsByProfile({ ...postsByProfile })

      if (post.publish_at.includes('queue')) {
        const { id, entityId, timezone } = activeProfile

        const { posts = [] } = postsByProfile[id] || {}

        getQueueData({
          posts: posts
            .filter(({ publish_at, id }) => id !== postId && publish_at)
            .map(({ id, publish_at }) => ({ publication_gid: id, publish_at })),
          targets: [{ profile_gid: id, timezone, publish_at: post.publish_at, entityId, postId }],
        })
      }
    }
    handleClickCloseEditPostModal()
  }

  const handleUpdateComponentsAfterMediasUpdate = ({ medias }) => {
    let hasPostChanged = false
    medias.forEach((media) => {
      const { id } = media

      const keys = ['postImages', 'postVideos', 'postMixedMedias']

      for (const profileId in postsByProfile) {
        const { posts = [] } = postsByProfile[profileId]
        if (posts && posts.length !== 0) {
          posts.forEach((post) => {
            keys.forEach((key) => {
              if (post[key] && post[key].length !== 0) {
                const foundMediaIndex = post[key].findIndex((media) => media.id === id)
                if (foundMediaIndex > -1) {
                  hasPostChanged = true
                  post[key][foundMediaIndex] = media
                }
              }
            })
          })
        }
      }
    })

    if (hasPostChanged) {
      setPostsByProfile({ ...postsByProfile })
    }
  }

  const handleCheckPostForError = () => {
    let hasErrors = false

    // eslint-disable-next-line guard-for-in
    for (const i in postsByProfile) {
      postsByProfile[i].hasError = false

      const { posts = [], code, id, isRemoved } = postsByProfile[i]
      posts.forEach((post, index) => {
        posts[index].hasError = false

        const networkLimit = NETWORK_LIMITS.find((limit) => limit.code === code)

        if (networkLimit && !isRemoved) {
          const { postComponent, postVideos = [], postImages = [], customizations = {} } = post

          const { video_processing = user_settings_video_processing, [code]: networkCustomizations } =
            customizations || {}

          const { [id]: profileCustomizations } = networkCustomizations || {}

          const { postImages: customizedPostImages = postImages, postVideos: customizedPostVideos = postVideos } =
            profileCustomizations || {}

          let customizedPostComponent = profileCustomizations.postComponent

          if (typeof customizedPostComponent === 'undefined') {
            customizedPostComponent = postComponent
          }

          const {
            minReelAspectRatio,
            maxReelAspectRatio,
            minReelAspectRatioVideoProcessingOff,
            maxReelAspectRatioVideoProcessingOff,
            maxVideoDuration,
            minVideoDuration,
            minImageWidth,
            minImageHeight,
            maxImageWidth,
            maxImageHeight,
            maxVideoSize,
            maxImageSize,
            imageExtensions,
            videoExtensions,
            maxImages,
            maxVideos,
            minImages,
          } = networkLimit

          let { minVideoAspectRatio, maxVideoAspectRatio, minImageAspectRatio, maxImageAspectRatio } = networkLimit

          minImageAspectRatio = minImageAspectRatio
            ? Number(
                minImageAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            : null

          maxImageAspectRatio = maxImageAspectRatio
            ? Number(
                maxImageAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            : null

          minVideoAspectRatio = minVideoAspectRatio
            ? Number(
                minVideoAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            : null

          maxVideoAspectRatio = maxVideoAspectRatio
            ? Number(
                maxVideoAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            : null

          let postCustomization = {}
          if (post.customizations && post.customizations[code] && post.customizations[code][id]) {
            postCustomization = post.customizations[code][id]
          }

          const { media_type, device_gid, publishing_option } = postCustomization || {}

          if (
            code === INSTAGRAM &&
            (media_type === PUBLISH_AS_REELS ||
              (media_type === PUBLISH_AS_STORY && publishing_option !== PUBLISHING_OPTION_NOTIFICATION) ||
              (!media_type && customizedPostComponent === POST_VIDEO && customizedPostVideos.length === 1))
          ) {
            if (minReelAspectRatio && video_processing) {
              minVideoAspectRatio = Number(
                minReelAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            } else if (minReelAspectRatioVideoProcessingOff && !video_processing) {
              minVideoAspectRatio = Number(
                minReelAspectRatioVideoProcessingOff
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            }

            if (maxReelAspectRatio && video_processing) {
              maxVideoAspectRatio = Number(
                maxReelAspectRatio
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            } else if (maxReelAspectRatioVideoProcessingOff && !video_processing) {
              maxVideoAspectRatio = Number(
                maxReelAspectRatioVideoProcessingOff
                  .split(':')
                  .reduce((previousValue, currentValue) => Number(previousValue) / Number(currentValue))
                  .toFixed(2)
              )
            }
          }

          let postMediaAspectRatioLimit = false
          let postVideoDurationLimit = false
          let postMediaResolutionLimit = false
          let postVideoExtensionError = false
          let postVideoSizeLimit = false
          let noContent = false
          let deviceLengthLimit = false
          let postVideosLengthLimit = false
          let postImageSizeLimit = false
          let postImagesMinLengthLimit = false
          let postImageExtensionError = false

          if (code === TIKTOK) {
            if (customizedPostComponent === POST_IMAGE) {
              if (customizedPostImages.length < minImages) {
                postImagesMinLengthLimit = true
              }
            } else if (customizedPostComponent === POST_VIDEO) {
              if (customizedPostVideos.length === 0) {
                postVideosLengthLimit = true
              }
            }
          }

          if (customizedPostComponent === POST_VIDEO && customizedPostVideos.length !== 0) {
            const limitedPostVideos = customizedPostVideos.slice(0, maxVideos)
            for (const video of limitedPostVideos) {
              const { id, aspect_ratio, duration, hasAspectRatioError, hasDurationError, extension, size } = video

              let updatedAspectRatio = aspect_ratio ? Number(aspect_ratio).toFixed(2) : 0
              let updatedDuration = duration

              if (!updatedAspectRatio || !updatedDuration) {
                const element = document.getElementById(`${POST_MEDIA_CLASS_NAME}-${id}`)

                if (element) {
                  const { children: { 0: videoElement } = [] } = element || {}
                  const { videoHeight, videoWidth, duration: videoDuration } = videoElement || {}
                  if (videoWidth && videoHeight) {
                    updatedAspectRatio = Number((videoWidth / videoHeight).toFixed(2))
                    video.aspect_ratio = updatedAspectRatio
                  }
                  if (videoDuration) {
                    updatedDuration = videoDuration
                    video.duration = videoDuration
                  }
                }
              }

              if (minVideoAspectRatio && maxVideoAspectRatio) {
                if (typeof hasAspectRatioError === 'boolean' && hasAspectRatioError) {
                  if (code !== INSTAGRAM || (code === INSTAGRAM && !video_processing)) {
                    postMediaAspectRatioLimit = id
                  }
                } else if (typeof hasAspectRatioError !== 'boolean') {
                  video.hasAspectRatioError = false

                  if (updatedAspectRatio) {
                    if (updatedAspectRatio < minVideoAspectRatio || updatedAspectRatio > maxVideoAspectRatio) {
                      if (code !== INSTAGRAM || (code === INSTAGRAM && !video_processing)) {
                        postMediaAspectRatioLimit = id
                      }

                      video.hasAspectRatioError = true
                    }
                  } else {
                    delete video.hasAspectRatioError
                  }
                }
              }

              if (minVideoDuration && maxVideoDuration) {
                if (typeof hasDurationError === 'boolean' && hasDurationError) {
                  postVideoDurationLimit = id
                } else if (typeof hasDurationError !== 'boolean') {
                  video.hasDurationError = false

                  if (updatedDuration) {
                    if (updatedDuration < minVideoDuration || updatedDuration > maxVideoDuration) {
                      postVideoDurationLimit = id
                      video.hasDurationError = true
                    }
                  } else {
                    delete video.hasDurationError
                  }
                }
              }

              video.hasExtensionError = false
              if (videoExtensions && !videoExtensions.includes(`.${extension}`)) {
                postVideoExtensionError = id
                video.hasExtensionError = true
              }

              video.hasSizeError = false
              if (maxVideoSize && size > maxVideoSize) {
                postVideoSizeLimit = id
                video.hasSizeError = true
              }
            }
          }

          if (customizedPostComponent === POST_IMAGE && customizedPostImages.length !== 0) {
            const limitedPostImages = customizedPostImages.slice(0, maxImages)
            for (const image of limitedPostImages) {
              const {
                id,
                aspect_ratio,
                width,
                height,
                hasAspectRatioError,
                hasResolutionError,
                size,
                extension,
              } = image

              let updatedAspectRatio = aspect_ratio ? Number(aspect_ratio).toFixed(2) : 0
              let updatedWidth = width
              let updatedHeight = height

              if (!updatedAspectRatio || !updatedWidth || !updatedHeight) {
                const element = document.getElementById(`${POST_MEDIA_CLASS_NAME}-${id}`)
                if (element) {
                  const { naturalWidth, naturalHeight } = element || {}
                  if (naturalWidth && naturalHeight) {
                    updatedAspectRatio = Number((naturalWidth / naturalHeight).toFixed(2))
                    image.aspect_ratio = updatedAspectRatio
                    updatedWidth = naturalWidth
                    image.width = naturalWidth
                    updatedHeight = naturalHeight
                    image.height = naturalHeight
                  }
                }
              }

              if (minImageAspectRatio && maxImageAspectRatio) {
                if (typeof hasAspectRatioError === 'boolean' && image.hasAspectRatioError) {
                  postMediaAspectRatioLimit = id
                } else if (typeof hasAspectRatioError !== 'boolean') {
                  image.hasAspectRatioError = false

                  if (updatedAspectRatio) {
                    if (updatedAspectRatio < minImageAspectRatio || updatedAspectRatio > maxImageAspectRatio) {
                      postMediaAspectRatioLimit = id
                      image.hasAspectRatioError = true
                    }
                  } else {
                    delete image.hasAspectRatioError
                  }
                }
              }

              if ((minImageHeight && minImageWidth) || (maxImageHeight && maxImageWidth)) {
                if (typeof hasResolutionError === 'boolean' && image.hasResolutionError) {
                  postMediaResolutionLimit = id
                } else if (typeof hasResolutionError !== 'boolean') {
                  image.hasResolutionError = false

                  if (updatedWidth && updatedHeight) {
                    if (minImageWidth && minImageHeight) {
                      if (updatedWidth < minImageWidth || updatedHeight < minImageHeight) {
                        postMediaResolutionLimit = id
                        image.hasResolutionError = true
                      }
                    }

                    if (maxImageWidth && maxImageHeight) {
                      if (updatedWidth > maxImageWidth || updatedHeight > maxImageHeight) {
                        postMediaResolutionLimit = id
                        image.hasResolutionError = true
                      }
                    }
                  } else {
                    delete image.hasResolutionError
                  }
                }
              }

              image.hasExtensionError = false
              if (imageExtensions && extension && !imageExtensions.includes(`.${extension}`)) {
                postImageExtensionError = id
                image.hasExtensionError = true
              }

              image.hasSizeError = false
              if (maxImageSize && size > maxImageSize) {
                postImageSizeLimit = id
                image.hasSizeError = true
              }
            }
          }

          if (![POST_VIDEO, POST_IMAGE, POST_LINK, POST_MIXED_MEDIA].includes(customizedPostComponent)) {
            noContent = true
          }

          if (publishing_option === PUBLISHING_OPTION_NOTIFICATION && !device_gid) {
            deviceLengthLimit = true
          }

          if (!post.customizations) {
            post.customizations = {
              [code]: {},
            }
          }
          if (!post.customizations[code]) {
            post.customizations[code] = {}
          }

          if (!post.customizations[code][id]) {
            post.customizations[code][id] = postCustomization
          }

          if (
            deviceLengthLimit ||
            postMediaAspectRatioLimit ||
            postVideoDurationLimit ||
            postMediaResolutionLimit ||
            postVideoSizeLimit ||
            postVideoExtensionError ||
            postVideosLengthLimit ||
            postImageSizeLimit ||
            postImagesMinLengthLimit ||
            postImageExtensionError ||
            noContent
          ) {
            posts[index].hasError = true
            hasErrors = true
            postsByProfile[i].hasError = true
          }
        }
      })
    }

    setPostsByProfile({ ...postsByProfile })

    return { hasErrors, postsByProfile }
  }

  const handleUpdatePost = async (data) => {
    let hasError = false
    try {
      const response = await request({
        method: 'PUT',
        body: data,
        path: `${ROUTE_PUBLISH}/${data.id}`,
      })

      const { error = '' } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        hasError = true
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: NetworkPlannerModal.displayName,
        functionName: 'handleUpdatePost',
      })
      hasError = true
    }
    return { hasError }
  }

  const handleCreatePost = async (data) => {
    let hasError = false
    let publication_ids = []
    let publication_group_id
    try {
      const response = await request({
        method: 'POST',
        body: data,
        path: ROUTE_PUBLISH,
      })

      const { error = '' } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        hasError = true
      } else {
        ;({ publication_ids, publication_group_id } = response)
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: NetworkPlannerModal.displayName,
        functionName: 'handleCreatePost',
      })
      hasError = true
    }
    return { hasError, publication_id: publication_ids[0], publication_group_id }
  }

  const handleRemovePost = async (data) => {
    let hasError = false
    try {
      const response = await request({
        method: 'DELETE',
        body: { message: '', items: [data] },
        path: ROUTE_PUBLISH,
      })

      const { error = '' } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        hasError = true
      }
    } catch (error) {
      errorHelper({
        error,
        componentName: NetworkPlannerModal.displayName,
        functionName: 'handleRemovePost',
      })
      hasError = true
    }
    return { hasError }
  }

  const handleClickSubmitPosts = async () => {
    if (!featuresEnabled[FEATURE_NETWORK_PLANNER].enabled) {
      Alert.error(featuresEnabled[FEATURE_NETWORK_PLANNER].description)
    } else {
      const { hasErrors } = handleCheckPostForError()
      if (hasErrors) {
        Alert.error(`Please check your posts content.`, { timeout: 5000 })
      } else {
        const postsForPublish = []
        const postsForUpdate = []
        const postsForRemove = []
        // eslint-disable-next-line guard-for-in

        for (const i in postsByProfile) {
          postsByProfile[i].hasError = false
          const { posts = [], code, id, timezone, entityId } = postsByProfile[i]

          const { [id]: selectedQueueLabel } = selectedQueueLabelByProfile

          // eslint-disable-next-line no-loop-func
          posts.forEach((post) => {
            const {
              isNew,
              id: postId,
              postText = '',
              postComponent,
              link,
              labels = [],
              selectedQueueLabel: postSelectedQueueLabel,
              publish_at,
              customizations,
              postImages = [],
              postVideos = [],
              postMixedMedias = [],
              publication_group_id,
              isRemoved,
              hasChanged,
              status,
              labels: postLabels,
              advocacy,
              using_ai = false,
            } = post

            if (isRemoved) {
              postsForRemove.push({ profile_gid: i, postId, data: { id: postId, type: '' } })
            } else if (hasChanged) {
              const data = {
                message: postText,
                targets: [],
                labels: postLabels || labels || [],
                customizations: [],
                using_ai,
              }

              collectAdvocacyDataBeforePublishing({ data, advocacy })

              if (publish_at === DRAFT || status === DRAFT) {
                data.draft = true
              }

              if (post.review) {
                const {
                  reviewer: { value } = {},
                  note_to_reviewer = '',
                  note_from_reviewer = '',
                  approved,
                } = post.review
                data.review = { reviewer: value, note_to_reviewer, note_from_reviewer }

                if (typeof approved !== 'undefined') {
                  data.review.approved = approved
                }
              }

              const post_attachments = collectPostAttachments({ post })

              if (post_attachments) {
                data.attachments = post_attachments
              }

              if (link && link.url) {
                if (!link.shorten) {
                  link.shorten = { type: 'DEFAULT' }
                }
                data.link = {
                  url: link.url,
                  title: link.title || '',
                  description: link.description || '',
                  shorten: link.shorten,
                  active: postComponent === POST_LINK,
                }
                if (link.utm_tracking) {
                  data.link.tracking = {
                    params: link.tracking.params.map(({ name, type, value }) => {
                      const data = { name, type: type.value }
                      if (data.type === CUSTOM_TYPE) {
                        data.value = value
                      }
                      return data
                    }),
                  }

                  if (link.save_utm_tracking) {
                    data.link.save_utm_tracking = true
                    data.link.utmTrackingId = link.utmTrackingId
                  }
                } else if (typeof link.utm_tracking !== 'undefined') {
                  data.link.tracking = {
                    disabled: true,
                  }
                }

                if (link.id) {
                  data.link.media_gid = link.id
                }
              } else {
                data.link = null
              }

              const targetsData = {
                profile_gid: id,
                network: code,
                timezone,
                labels: [],
              }

              if (publish_at) {
                const publishDate = publish_at
                if (publishDate) {
                  if (publishDate.includes('queue') || publishDate === 'now') {
                    targetsData.publish_at = publishDate
                  } else if (publishDate === DRAFT) {
                    targetsData.publish_at = moment.tz(moment(), true, timezone).format('YYYY-MM-DD HH:mm')
                  } else {
                    targetsData.publish_at = moment(publishDate).format('YYYY-MM-DD HH:mm')
                  }
                } else {
                  targetsData.publish_at = null
                }
              } else {
                targetsData.publish_at = QUEUE_LAST
              }

              let queueLabel = postSelectedQueueLabel

              if (isNew && !queueLabel && selectedQueueLabel) {
                queueLabel = selectedQueueLabel
              }

              if (queueLabel && queueLabel.value) {
                targetsData.labels.push(queueLabel.value)
              }

              targetsData.entity_gid = entityId

              data.targets.push(targetsData)

              const postCustomization = customizations?.[code]?.[id]
                ? JSON.parse(JSON.stringify(customizations[code][id]))
                : {}

              const {
                postImages: customizedPostImages = postImages,
                postVideos: customizedPostVideos = postVideos,
                postMixedMedias: customizedPostMixedMedias = postMixedMedias,
              } = postCustomization

              let customizedPostComponent = postCustomization.postComponent

              if (typeof customizedPostComponent === 'undefined') {
                customizedPostComponent = postComponent
              }

              if (customizations) {
                const { video_processing } = customizations
                if (typeof video_processing !== 'undefined') {
                  postCustomization.video_processing = video_processing
                }
              }

              postCustomization.kind = code
              postCustomization.profile_gid = id

              delete postCustomization.view

              const { message = '' } = postCustomization

              const { mentionsFromPost } = mentionsTransformer({
                body: message || postText,
                color: 'rgb(0,0,0)',
                fontWeight: 400,
                agencyMentions: agencyMentionsOptions.data,
                network: code,
              })

              const mentionsForNetwork = []
              mentionsFromPost.forEach((item) => {
                if (item[code]) {
                  const { id } = item[code]
                  mentionsForNetwork.push({ id, mention: item.name })
                }
              })
              if (mentionsForNetwork.length !== 0) {
                postCustomization.mentions = mentionsForNetwork
              }

              const attachments = collectPostAttachments({
                post: {
                  postComponent: customizedPostComponent,
                  postImages: customizedPostImages,
                  postVideos: customizedPostVideos,
                  postMixedMedias: customizedPostMixedMedias,
                },
              })

              if (attachments) {
                postCustomization.attachments = attachments
              }

              const { comments = [] } = postCustomization

              if (comments && comments.length !== 0) {
                const updated_comments = []

                comments.forEach((comment) => {
                  const data = { message: comment.message }

                  const attachments = collectPostAttachments({
                    post: comment,
                  })

                  if (attachments) {
                    data.attachments = attachments
                  }

                  if (data.message || (data.attachments && data.attachments.length > 0)) {
                    updated_comments.push(data)
                  }
                })

                if (updated_comments.length !== 0) {
                  postCustomization.comments = updated_comments
                }
              }

              data.customizations.push(postCustomization)

              if (publication_group_id) {
                data.publication_group_id = publication_group_id
                data.id = postId
                postsForUpdate.push({ profile_gid: i, postId, data })
              } else {
                postsForPublish.push({ profile_gid: i, postId, data })
              }
            }
          })
        }

        let allPostsErrors = false

        const updatePost = ({
          profile_gid,
          postId,
          hasError,
          publication_id,
          publication_group_id,
          status,
          draft,
          removed = false,
        }) => {
          if (hasError) {
            postsByProfile[profile_gid].hasError = true
            allPostsErrors = true
          }
          const foundPostIndex = postsByProfile[profile_gid].posts.findIndex(({ id }) => id === postId)

          if (foundPostIndex > -1) {
            postsByProfile[profile_gid].posts[foundPostIndex].hasError = hasError
            postsByProfile[profile_gid].posts[foundPostIndex].isRemoved = false
            if (!hasError) {
              if (removed) {
                postsByProfile[profile_gid].posts.splice(foundPostIndex, 1)
              } else {
                if (publication_id && publication_group_id) {
                  postsByProfile[profile_gid].posts[foundPostIndex].id = publication_id
                  postsByProfile[profile_gid].posts[foundPostIndex].publication_group_id = publication_group_id
                }

                let updatedStatus = APPROVED
                if (draft) {
                  updatedStatus = DRAFT
                } else if (status) {
                  updatedStatus = status
                }

                postsByProfile[profile_gid].posts[foundPostIndex].status = updatedStatus
                postsByProfile[profile_gid].posts[foundPostIndex].hasChanged = false
              }
            }
          }
        }

        if (postsForPublish.length !== 0 || postsForUpdate.length !== 0 || postsForRemove.length !== 0) {
          setIsUpdatingPosts(true)
          if (postsForUpdate.length !== 0) {
            for (const { profile_gid, postId, data, status, draft } of postsForUpdate) {
              const { hasError } = await handleUpdatePost(data)
              updatePost({ profile_gid, postId, hasError, status, draft })
            }
          }

          if (postsForPublish.length !== 0) {
            for (const { profile_gid, postId, data, draft } of postsForPublish) {
              const { hasError, publication_id, publication_group_id } = await handleCreatePost(data)
              updatePost({
                profile_gid,
                postId,
                hasError,
                draft,
                publication_id,
                publication_group_id,
              })
            }
          }

          if (postsForRemove.length !== 0) {
            for (const { profile_gid, postId, data } of postsForRemove) {
              const { hasError } = await handleRemovePost(data)
              updatePost({ profile_gid, postId, hasError, removed: true })
            }
          }

          setPostsByProfile({ ...postsByProfile })

          if (allPostsErrors) {
            Alert.error(`Some changes could not be saved.`, { timeout: 5000 })
          } else {
            Alert.success(`All changes have been saved`, { timeout: 5000 })
          }

          setIsUpdatingPosts(false)
          handleClickReloadCalendar()
        } else {
          Alert.info(`There are no changes to be saved!`, { timeout: 5000 })
        }
      }
    }
  }

  const handleClickCloseModal = () => {
    if (!isUpdatingPosts) {
      let hasUnsavedChanges = false

      const postsByProfileTemp = Object.values(postsByProfile)

      for (let i = 0; i < postsByProfileTemp.length; i++) {
        const { posts = [] } = postsByProfileTemp[i]
        const foundChangedPost = posts.find(({ hasChanged, isRemoved }) => hasChanged || isRemoved)

        if (foundChangedPost) {
          hasUnsavedChanges = true
        }

        if (hasUnsavedChanges) {
          break
        }
      }

      if (hasUnsavedChanges) {
        confirm({
          fn: () => handleDismiss,
          message: `Your changes won't be saved. Do you want to continue?`,
          action: `Yes, I'm sure`,
          cancel: 'No, cancel',
        })
      } else {
        handleDismiss()
      }
    }
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Box m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          <StyledDialogEnvironmentWrapper px="m" justifyContent="space-between" alignItems="center" $isTop>
            <H4 my="m">{networkName} planner</H4>
          </StyledDialogEnvironmentWrapper>

          <StyledDialogBodyWrapper flex="1" tabIndex={0} position="relative" justifyContent="space-between">
            <StyledProgressWrapper
              display={isGettingData ? 'flex' : 'none'}
              alignItems="center"
              justifyContent="center"
            >
              <ClipLoader size="100" color={colors.primary} />
            </StyledProgressWrapper>
            {!activeProfile ? (
              <Flex alignItems="center" justifyContent="center" width="100%" height="100%">
                <NoData
                  message={`Please select at least one active ${networkName} profile`}
                  showHeaderText={false}
                  image="/assets/noResultsIllustration.svg"
                />
              </Flex>
            ) : (
              <Fragment>
                <Flex flexDirection="column" width="370px" mt="s">
                  <MediaBodyComponent
                    ref={mediaComponentRef}
                    user={user}
                    confirm={confirm}
                    selectedProfiles={activeProfile ? [activeProfile] : selectedProfiles}
                    selectedEntities={selectedEntities}
                    entities={entities}
                    dragMediaOptions={dragMediaOptions}
                    setDragMediaOptions={setDragMediaOptions}
                    onDropMedia={onDropMedia}
                    isSmall
                    showAddMediaDropdownPosition="right"
                    isSelectable={false}
                    isFavorable={false}
                    handleClickCreatePost={({ post }) => {
                      const { postComponent, postImages = [], postVideos = [] } = post
                      let media
                      if (postComponent === POST_IMAGE) {
                        // eslint-disable-next-line prefer-destructuring
                        media = postImages[0]
                      } else if (postComponent === POST_VIDEO) {
                        // eslint-disable-next-line prefer-destructuring
                        media = postVideos[0]
                      }
                      if (media) {
                        onDropMedia({ media })
                      }
                    }}
                    handleUpdateComponentsAfterMediasUpdate={handleUpdateComponentsAfterMediasUpdate}
                    defaultTypes={DEFAULT_TYPES[network]}
                  />
                </Flex>
                <StyledCenterColumnWrapper
                  flexDirection="column"
                  onDragEnter={(e) => {
                    e.preventDefault()
                  }}
                  onDragOver={(e) => {
                    e.preventDefault()
                  }}
                  onDrop={() => {
                    onDropMedia({})
                  }}
                >
                  <Box mt="s" pt="xs" px="m" width={{ mobile: '370px', desktopLarge: '440px' }}>
                    <SelectedProfilesSlider
                      profiles={profiles}
                      handleClickSelectProfile={handleClickSelectProfile}
                      activeProfile={activeProfile}
                      postsByProfile={postsByProfile}
                    />
                  </Box>
                  <Flex flexDirection="column" height="600px" width="380px" mt="s">
                    {featuresEnabled[FEATURE_NETWORK_PLANNER].enabled ? (
                      <PhoneComponent
                        ref={phoneComponentRef}
                        activeProfile={activeProfile}
                        setPostsByProfile={setPostsByProfile}
                        postsByProfile={postsByProfile}
                        posts={
                          activeProfile && postsByProfile[activeProfile.id]
                            ? [...postsByProfile[activeProfile.id].posts]
                            : []
                        }
                        dragMediaOptions={dragMediaOptions}
                        handleClickRemovePost={handleClickRemovePost}
                        onDropMedia={onDropMedia}
                        handleClickEditPost={handleClickEditPost}
                        network={network}
                      />
                    ) : (
                      <Flex px="m" alignItems="center" height="100%">
                        <Box textAlign="center" width="100%">
                          <Image src="/assets/emptyPublish.svg" maxWidth="250px" width="100%" />

                          <Text mt="s" color="secondaryText">
                            {networkName} planner is not available with free account.{' '}
                            <StyledLearnMore color="primary" as="a" href={`/integrations/${network}`} target="_blank">
                              Learn more
                            </StyledLearnMore>{' '}
                            about planning feature.
                          </Text>
                          <Flex justifyContent="center" mt="s">
                            <Button.Gradient
                              isSmall
                              onClick={() => {
                                window.location.replace('/pricing')
                              }}
                            >
                              Please upgrade
                            </Button.Gradient>
                          </Flex>
                        </Box>
                      </Flex>
                    )}
                  </Flex>
                </StyledCenterColumnWrapper>
                <Flex flexDirection="column" width="450px" height="100%">
                  <PostsComponent
                    postsByProfile={postsByProfile}
                    handleClickRemovePost={handleClickRemovePost}
                    handleClickEditPost={handleClickEditPost}
                    setPostsByProfile={setPostsByProfile}
                    activeProfile={activeProfile}
                    selectedQueueLabelByProfile={selectedQueueLabelByProfile}
                    setSelectedQueueLabelByProfile={setSelectedQueueLabelByProfile}
                    usedQueueLabelsByProfile={usedQueueLabelsByProfile}
                    featuresEnabled={featuresEnabled}
                  />
                </Flex>
              </Fragment>
            )}
          </StyledDialogBodyWrapper>
          <StyledDialogEnvironmentWrapper p="m" justifyContent="space-between" $isBottom>
            <ButtonWithLoading buttonComp={Button.Gray} mr="m" isSmall onClick={handleClickCloseModal}>
              Cancel
            </ButtonWithLoading>

            <ButtonWithLoading isSmall onClick={handleClickSubmitPosts} isLoading={isUpdatingPosts}>
              {isUpdatingPosts ? 'Saving' : 'Save'}
            </ButtonWithLoading>
          </StyledDialogEnvironmentWrapper>

          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal} isDisabled={isUpdatingPosts}>
            <Image width="10px" height="10px" src="/assets/clear.svg" />
          </CloseIconWrapper>
        </StyledDialogContent>

        {editPostModalOptions.isOpen && (
          <BulkEditPostModal
            user={user}
            isOpen={editPostModalOptions.isOpen}
            selectedProfiles={[activeProfile]}
            selectedEntities={selectedEntities}
            selectedTimezone={activeProfile.timezone}
            postForEdit={editPostModalOptions.postForEdit}
            handleDismiss={handleClickCloseEditPostModal}
            handleUpdatePost={handleClickUpdatePost}
            dataForCustomizations={{}}
            whenToPublish={editPostModalOptions.postForEdit.publish_at}
            usedQueueLabels={usedQueueLabelsByProfile[activeProfile.id]}
            featuresEnabled={featuresEnabled}
          />
        )}
      </Box>
    </StyledDialogOverlay>
  )
}

NetworkPlannerModal.defaultProps = {
  handleClickReloadCalendar: () => {},
  network: INSTAGRAM,
}

NetworkPlannerModal.propTypes = {
  user: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  selectedProfiles: PropTypes.array.isRequired,
  selectedEntities: PropTypes.array.isRequired,
  network: PropTypes.string,
  confirm: PropTypes.func.isRequired,
  handleClickReloadCalendar: PropTypes.func,
}

NetworkPlannerModal.displayName = 'NetworkPlannerModal'

export default withConfirm(NetworkPlannerModal)
