import React, { Fragment, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { transparentize } from 'polished'
import { space as styledSpace } from 'styled-system'
import { Scrollbars } from 'react-custom-scrollbars-2'
import Modal from '@material-ui/core/Modal'
import Alert from 'react-s-alert'
import ClipLoader from 'react-spinners/ClipLoader'
import { COLOR_CONSTANTS, colors, radius } from 'theme'
import { IMAGE, VIDEO, AUDIO, ERROR_MESSAGE, IMAGE_DYNAMIC, VIDEO_DYNAMIC, DOCUMENT } from 'consts'
import { getFileIconByExtension } from 'helpers'
import request from 'utils/request'
import errorHelper from 'utils/errorHelper'
import { Text } from 'components/atoms/Typography'
import { Box, Flex, Grid } from 'components/atoms/Layout'
import Image from 'components/atoms/Image'
import ImageWithFallback from 'components/atoms/ImageWithFallback'
import Divider from 'components/atoms/Divider'
import Icon from 'components/atoms/Icon'
import VideoWithFallback from 'components/atoms/VideoWithFallback'
import Button from 'components/atoms/Button'
import Badge from 'components/atoms/Badge'
import AudioWithFallback from 'components/atoms/AudioWithFallback'
import DropdownMenu from 'components/molecules/DropdownMenu'
import DynamicMedia from 'components/molecules/DynamicMedia'
import { POST_IMAGE, POST_VIDEO, ROUTE_MEDIA, POST_DOCUMENT } from 'routes/Calendar/consts'
import { handleMediaFileChange } from '../helper'

const StyledDialogOverlay = styled(Modal)`
  &&& {
    background-color: ${transparentize(0.2, COLOR_CONSTANTS.SALUTE)};
    z-index: 2147483001 !important;
    > * {
      &:first-child {
        background: none !important;
      }
    }
  }
`

const StyledDialogContent = styled(Flex)`
  &&& {
    position: relative;
    height: 100%;
    width: 100%;
    max-width: 800px;
    min-height: 460px;
    max-height: 800px;
    padding: 0;
    border-radius: ${radius.l};
    ${styledSpace};
    margin: auto;
    display: flex;
    flex-direction: column;
    background-color: ${COLOR_CONSTANTS.WHITE};
  }
`

const StyledDialogBodyWrapper = styled(Flex)`
  overflow-y: auto;
  height: 100%;
`

const CloseIconWrapper = styled(Box)`
  position: absolute;
  top: -6px;
  right: -9px;
  background: ${COLOR_CONSTANTS.WHITE};
  height: 20px;
  width: 20px;
  border-radius: ${radius.pill};
  justify-content: center;
  display: flex;
  align-items: center;
  cursor: pointer;
  ${({ isDisabled }) => isDisabled && `pointer-events:none;`}
`

const StyledUserImage = styled(ImageWithFallback)`
  border-radius: ${radius.pill};
  width: 36px;
  height: 36px;
`

const StyledUserLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  width: calc(100% - 32px);
`

const StyledChevronWrapper = styled(Box)`
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
`

const StyledIconChevronRight = styled(Icon.VistaSocialChevronRight)`
  cursor: pointer;
  fill: ${colors.secondaryText};
  &:hover {
    fill: ${colors.white};
  }
`

const StyledIconChevronLeft = styled(Icon.VistaSocialChevronRight)`
  transform: rotate(-180deg);
  cursor: pointer;
  fill: ${colors.secondaryText};
  &:hover {
    fill: ${colors.white};
  }
`

const FavoriteWrapper = styled(Flex)`
  width: 20px;
  height: 20px;
  cursor: pointer;
  ${({ favorited }) =>
    !favorited &&
    `
    svg {
      fill-opacity: 0.3;
      fill: ${COLOR_CONSTANTS.BLACK};
      path {
        stroke: ${COLOR_CONSTANTS.WHITE};
      }
    }
  `}
  &:hover {
    ${({ favorited }) =>
      !favorited &&
      `
      svg {
        path {
          stroke: ${COLOR_CONSTANTS.YELLOW_STAR};
        }
      }
    `}
  }
`

const StyledRoundedButton = styled(Button.Gray)`
  min-width: auto;
  padding: 0;
  width: 32px;
  height: 32px;
  border: none;
  border-radius: ${radius.pill};
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const ControlsWrapper = styled(Flex)`
  z-index: 2;
  height: 32px;
  width: 32px;
  cursor: pointer;
  border-radius: ${radius.pill};
  justify-content: center;
  align-items: center;
  &:hover {
    background-color: ${COLOR_CONSTANTS.DAISY};
  }
`

const StyledProgressWrapper = styled(Flex)`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: hidden;
  z-index: 15;
  background-color: ${COLOR_CONSTANTS.DAISY};
`

const MediaDetailModal = ({
  isOpen,
  handleDismiss,
  data,
  medias,
  handleClickSendToCompose,
  handleClickRemoverFromFavorite,
  handleClickAddToFavorite,
  handleClickRemoveMedia,
  confirm,
  handleClickOpenMediaMetaInformationModal,
  handleClickOpenImageEditModal,
  handleClickOpenVideoThumbnailModal,
  handleClickOpenVideoPickSoundModal,
  handleUpdateAudioCover,
  handleUpdateMediaInMediaDetailModal,
  imageExtensions: IMAGE_EXTENSIONS_JOINED,
  updatedData,
  handleClickOpenPosts,
  handleClickOpenMediaDynamicModal,
}) => {
  const inputAudioCoverFileRef = useRef(null)
  const [activeMediaIndex, setActiveMediaIndex] = useState(-1)
  const [media, setMedia] = useState({})
  const [uploadingMedias, setUploadingMedias] = useState([])
  const [isUpdatingData, setIsUpdatingData] = useState(false)

  const mediasLength = medias.length

  const {
    id,
    type,
    url,
    thumbnail_url,
    description,
    extension,
    information = [],
    labels = [],
    entities = [],
    createdBy,
    createdByPictureUrl,
    favorite_media_gid,
    audio_cover_mime,
    thumb_offset,
    width,
    height,
    size,
    publications = [],
  } = media || {}

  const publicationsCounter = publications ? publications.length : 0

  useEffect(() => {
    if (isOpen) {
      setActiveMediaIndex(data.index)
    }
  }, [isOpen])

  useEffect(() => {
    if (activeMediaIndex > -1) {
      setMedia(medias[activeMediaIndex])
    } else {
      setMedia(data)
    }
  }, [activeMediaIndex])

  useEffect(() => {
    if (updatedData) {
      if (updatedData.id === media.id) {
        setMedia({})
        setMedia({ ...media, ...updatedData })
      }

      handleUpdateMediaInMediaDetailModal({ data: null })
    }
    if (isUpdatingData) {
      setIsUpdatingData(false)
    }
  }, [updatedData])

  useEffect(() => {
    if (uploadingMedias.length !== 0) {
      // eslint-disable-next-line no-use-before-define
      uploadCoverToServerByFile(uploadingMedias[0])
    }
  }, [uploadingMedias])

  const uploadCoverToServerByFile = async ({ file }) => {
    try {
      setIsUpdatingData(true)

      const body = new FormData()
      body.append('data', file)

      const response = await request({
        method: 'PATCH',
        body,
        path: `${ROUTE_MEDIA}/${id}/audio-cover`,
        sendFile: true,
      })

      const { error, ...urls } = response || {}

      if (!response || error) {
        Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
      } else {
        handleUpdateAudioCover({ id, ...urls })
      }
    } catch (error) {
      errorHelper({ error, componentName: MediaDetailModal.displayName, functionName: 'uploadCoverToServerByFile' })
    } finally {
      setUploadingMedias([])
    }
  }

  const handleMediaFile = async (event) => {
    if (event.currentTarget.files.length !== 0) {
      handleMediaFileChange({ acceptedFiles: event.currentTarget.files, setUploadingMedias })
    }
  }

  const handleClickOpenAudioCoverFileModal = () => {
    inputAudioCoverFileRef.current.click()
  }

  const handleClickShowMedia = ({ index }) => {
    setActiveMediaIndex(index)
  }

  const handleClickCloseModal = () => {
    handleDismiss()
  }

  const handleClickCopyMediaLink = async () => {
    await navigator.clipboard.writeText(url)

    Alert.success(`Media link has been copied`, { timeout: 5000 })
  }

  const downloadMedia = async ({ id }) => {
    document.location = `/api/${ROUTE_MEDIA}/download/${id}`
  }

  let mediaComponent = ''

  if (type === IMAGE) {
    mediaComponent = (
      <Fragment>
        {url ? (
          <ImageWithFallback
            source={url}
            width="100%"
            height="100%"
            fallbackSource="/assets/emptyData.svg"
            borderRadius={`${radius.l} 0 0 ${radius.l}`}
            objectFit="contain"
          />
        ) : (
          <Image src="/assets/emptyData.svg" width="100%" height="100%" />
        )}
      </Fragment>
    )
  } else if (type === VIDEO) {
    mediaComponent = (
      <Fragment>
        {url ? (
          <VideoWithFallback
            url={url}
            controls
            width="100%"
            style={{ position: 'relative', borderRadius: `${radius.l} 0 0 ${radius.l}` }}
            height="100%"
            fallbackSourceImage={thumbnail_url}
            config={{
              file: {
                attributes: {
                  poster: thumbnail_url || '',
                },
              },
            }}
            light={false}
          />
        ) : (
          <Image src="/assets/videocamera.svg" width="100%" height="100%" />
        )}
      </Fragment>
    )
  } else if (type === AUDIO) {
    mediaComponent = (
      <Fragment>
        {url ? (
          <AudioWithFallback
            url={url}
            controls
            width="100%"
            style={{ position: 'relative', borderRadius: `${radius.l} 0 0 ${radius.l}` }}
            height="100%"
            fallbackSourceImage={thumbnail_url}
            config={{
              file: {
                attributes: {
                  poster: audio_cover_mime ? thumbnail_url || '' : '/assets/vistasocial/musical_note.svg',
                },
              },
            }}
            light={false}
          />
        ) : (
          <Image src="/assets/vistasocial/musical_note.svg" width="100%" height="100%" />
        )}
      </Fragment>
    )
  } else if (type === IMAGE_DYNAMIC || type === VIDEO_DYNAMIC) {
    mediaComponent = <DynamicMedia media={media} />
  } else if (type === DOCUMENT) {
    mediaComponent = <Image src={getFileIconByExtension({ extension })} width="100%" height="100%" />
  }

  return (
    <StyledDialogOverlay disableEnforceFocus open={isOpen} onClose={() => {}}>
      <Flex m="0 auto" width="100%" height="100%" p="l">
        <StyledDialogContent>
          {activeMediaIndex > 0 && (
            <StyledChevronWrapper
              left="-64px"
              onClick={() => {
                handleClickShowMedia({ index: activeMediaIndex - 1 })
              }}
            >
              <StyledIconChevronLeft width="50px" height="50px" viewBox="0 0 6 16" />
            </StyledChevronWrapper>
          )}
          {activeMediaIndex !== mediasLength - 1 && activeMediaIndex >= 0 && (
            <StyledChevronWrapper
              right="-64px"
              onClick={() => {
                handleClickShowMedia({ index: activeMediaIndex + 1 })
              }}
            >
              <StyledIconChevronRight width="50px" height="50px" viewBox="0 0 6 16" />
            </StyledChevronWrapper>
          )}

          <StyledDialogBodyWrapper flex="1">
            <Flex
              width="460px"
              bg={COLOR_CONSTANTS.ZHEN_ZHU_BAI_PEARL}
              position="relative"
              borderRadius={`${radius.l} 0 0 ${radius.l}`}
            >
              <StyledProgressWrapper
                display={isUpdatingData ? 'flex' : 'none'}
                alignItems="center"
                justifyContent="center"
              >
                <ClipLoader size="100" color={colors.primary} />
              </StyledProgressWrapper>
              {mediaComponent}
            </Flex>
            <Flex width="340px" flexDirection="column" py="m">
              <Flex alignItems="center" justifyContent="space-between" px="m">
                <StyledUserLink>
                  <StyledUserImage
                    source={createdByPictureUrl || '/assets/avatar.svg'}
                    fallbackSource="/assets/avatar.svg"
                  />
                  <Flex flexDirection="column" ml="s">
                    <Text fontWeight="medium" fontSize="s" color="primaryText">
                      {createdBy}
                    </Text>
                  </Flex>
                </StyledUserLink>

                <DropdownMenu
                  WrapperComp={
                    <ControlsWrapper>
                      <ImageWithFallback width="16px" height="16px" source="/assets/vistasocial/more.svg" />
                    </ControlsWrapper>
                  }
                  isDismissedOnClickInside
                >
                  <DropdownMenu.Item
                    label="Settings"
                    iconName="Gear"
                    iconWidth="14px"
                    onClick={() => {
                      handleClickOpenMediaMetaInformationModal({ media })
                    }}
                    isCursorPointer
                  />

                  {(type === IMAGE || type === IMAGE_DYNAMIC) && (
                    <DropdownMenu.Item
                      label="Edit image"
                      iconName="VistaSocialPicture"
                      onClick={() => {
                        if (type === IMAGE) {
                          handleClickOpenImageEditModal({ id, url, size })
                        } else if (type === IMAGE_DYNAMIC) {
                          handleClickOpenMediaDynamicModal({ media })
                        }
                      }}
                      isCursorPointer
                    />
                  )}

                  {type === VIDEO_DYNAMIC && (
                    <DropdownMenu.Item
                      label="Edit video"
                      iconName="VistaSocialPicture"
                      onClick={() => {
                        handleClickOpenMediaDynamicModal({ media })
                      }}
                      isCursorPointer
                    />
                  )}

                  {type === VIDEO && (
                    <Fragment>
                      <DropdownMenu.Item
                        label="Pick thumbnail"
                        iconName="VistaSocialPicture"
                        onClick={() => {
                          handleClickOpenVideoThumbnailModal({ id, url, thumbnail_url, thumb_offset, width, height })
                        }}
                        isCursorPointer
                      />
                      <DropdownMenu.Item
                        label="Pick sound"
                        iconWidth="14px"
                        iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                        iconName="Clavis"
                        onClick={() => {
                          handleClickOpenVideoPickSoundModal(media)
                        }}
                        isCursorPointer
                      />
                    </Fragment>
                  )}

                  {type === AUDIO && (
                    <DropdownMenu.Item
                      label="Pick cover"
                      iconName="VistaSocialPicture"
                      onClick={handleClickOpenAudioCoverFileModal}
                      isCursorPointer
                    />
                  )}

                  {type !== AUDIO &&
                    type !== DOCUMENT &&
                    (handleClickSendToCompose || (handleClickOpenPosts && publicationsCounter > 0)) && (
                      <Fragment>
                        <Divider height="1px" />

                        {handleClickSendToCompose && (
                          <DropdownMenu.Item
                            label="Create a post"
                            iconName="VistaSocialPaperPlane"
                            iconWidth="14px"
                            iconStroke={COLOR_CONSTANTS.COSMIC_ENERGY}
                            onClick={() => {
                              const post = { isFromFindContentModal: true }
                              if (type === IMAGE || type === IMAGE_DYNAMIC) {
                                post.postImages = [media]
                                post.postComponent = POST_IMAGE
                              } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
                                post.postVideos = [media]
                                post.postComponent = POST_VIDEO
                              } else if (type === DOCUMENT) {
                                post.postDocuments = [media]
                                post.postComponent = POST_DOCUMENT
                              }
                              handleClickSendToCompose({ post })
                            }}
                            isCursorPointer
                          />
                        )}

                        {handleClickOpenPosts && publicationsCounter > 0 && (
                          <DropdownMenu.Item
                            label="View posts"
                            iconName="News"
                            iconWidth="18px"
                            iconHeight="18px"
                            iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                            onClick={() => {
                              handleClickOpenPosts({ publications })
                            }}
                            isCursorPointer
                          />
                        )}
                      </Fragment>
                    )}

                  <Divider height="1px" />

                  {type !== IMAGE_DYNAMIC && type !== VIDEO_DYNAMIC && (
                    <Fragment>
                      <DropdownMenu.Item
                        label="Copy direct link"
                        iconName="Link"
                        iconWidth="16px"
                        iconColor={COLOR_CONSTANTS.COSMIC_ENERGY}
                        onClick={handleClickCopyMediaLink}
                        isCursorPointer
                      />

                      <DropdownMenu.Item
                        label="Download"
                        iconName="Download"
                        onClick={() => {
                          downloadMedia({ id })
                        }}
                        isCursorPointer
                      />
                    </Fragment>
                  )}

                  {handleClickRemoveMedia && (
                    <DropdownMenu.Item
                      label="Remove"
                      iconName="Trash"
                      onClick={() => {
                        confirm({
                          fn: () => () => handleClickRemoveMedia({ medias: [id] }),
                          message: `Are you sure you want to remove this media?`,
                          action: 'Remove',
                        })
                      }}
                      isCursorPointer
                    />
                  )}
                </DropdownMenu>
              </Flex>
              <Scrollbars universal>
                <Flex px="m">
                  <Divider my="m" />
                </Flex>
                <Flex flex="1" px="m" flexDirection="column">
                  <Grid gridTemplateColumns="repeat(2, 1fr)" gridGap="m">
                    {information.map(({ title, description }) => (
                      <Flex flexDirection="column" key={title}>
                        <Text color="secondaryText" fontWeight="light" fontSize="xxs">
                          {title}
                        </Text>
                        <Text fontSize="xs">{description}</Text>
                      </Flex>
                    ))}
                  </Grid>

                  {description && (
                    <Text mt="m" fontSize="xs">
                      {description}
                    </Text>
                  )}

                  {labels.length !== 0 && (
                    <Flex flexWrap="wrap" mt="m">
                      {labels.map((label, index) => (
                        <Badge.Status mt="xs" color={COLOR_CONSTANTS.SAPPHIRE} key={index} mr="s">
                          {label}
                        </Badge.Status>
                      ))}
                    </Flex>
                  )}

                  {entities.length !== 0 && (
                    <Flex flexWrap="wrap" mt="m">
                      {entities.map(({ label }, index) => (
                        <Badge.Status mt="xs" color={COLOR_CONSTANTS.PUMPKIN_PIE} key={index} mr="s">
                          {label}
                        </Badge.Status>
                      ))}
                    </Flex>
                  )}
                </Flex>
              </Scrollbars>

              <Flex flexDirection="column" px="m">
                <Divider />
                <Flex justifyContent="space-between" mt="m" alignItems="center">
                  <Flex alignItems="center">
                    {handleClickRemoveMedia && (
                      <StyledRoundedButton
                        mr="s"
                        onClick={() => {
                          confirm({
                            fn: () => () => handleClickRemoveMedia({ medias: [id] }),
                            message: `Are you sure you want to remove this media?`,
                            action: 'Remove',
                          })
                        }}
                      >
                        <Icon.Trash />
                      </StyledRoundedButton>
                    )}

                    {handleClickRemoverFromFavorite && handleClickAddToFavorite && (
                      <FavoriteWrapper
                        alignItems="center"
                        justifyContent="center"
                        favorited={favorite_media_gid}
                        onClick={() => {
                          if (favorite_media_gid) {
                            handleClickRemoverFromFavorite({ media })
                          } else {
                            handleClickAddToFavorite({ media })
                          }
                        }}
                      >
                        {favorite_media_gid ? (
                          <Icon.StarEmpty width="20px" height="20px" fill={COLOR_CONSTANTS.YELLOW_STAR} />
                        ) : (
                          <Icon.StarEmpty width="20px" height="20px" />
                        )}
                      </FavoriteWrapper>
                    )}
                  </Flex>

                  {type !== AUDIO && handleClickSendToCompose && (
                    <Button.Primary
                      isSmall
                      onClick={() => {
                        const post = { isFromFindContentModal: true }
                        if (type === IMAGE || type === IMAGE_DYNAMIC) {
                          post.postImages = [media]
                          post.postComponent = POST_IMAGE
                        } else if (type === VIDEO || type === VIDEO_DYNAMIC) {
                          post.postVideos = [media]
                          post.postComponent = POST_VIDEO
                        } else if (type === DOCUMENT) {
                          post.postDocuments = [media]
                          post.postComponent = POST_DOCUMENT
                        }
                        handleClickSendToCompose({ post })
                      }}
                    >
                      <Flex alignItems="center">
                        <Text>Create a post</Text>
                      </Flex>
                    </Button.Primary>
                  )}
                </Flex>
              </Flex>
            </Flex>
          </StyledDialogBodyWrapper>
          <CloseIconWrapper className="modal-close-icon" onClick={handleClickCloseModal}>
            <ImageWithFallback
              width="10px"
              height="10px"
              source="/assets/clear.svg"
              fallbackSource="/assets/clear.svg"
            />
          </CloseIconWrapper>
        </StyledDialogContent>
        <input
          hidden
          type="file"
          ref={inputAudioCoverFileRef}
          onChange={(e) => handleMediaFile(e)}
          accept={IMAGE_EXTENSIONS_JOINED}
          onClick={(event) => {
            event.target.value = null
          }}
        />
      </Flex>
    </StyledDialogOverlay>
  )
}

MediaDetailModal.defaultProps = {
  handleClickSendToCompose: null,
  updatedData: null,
  handleClickOpenPosts: null,
  handleClickRemoveMedia: null,
  handleClickRemoverFromFavorite: null,
  handleClickAddToFavorite: null,
}

MediaDetailModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleDismiss: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  medias: PropTypes.array.isRequired,
  handleClickSendToCompose: PropTypes.func,
  handleClickRemoverFromFavorite: PropTypes.func,
  handleClickAddToFavorite: PropTypes.func,
  handleClickRemoveMedia: PropTypes.func,
  confirm: PropTypes.func.isRequired,
  handleClickOpenMediaMetaInformationModal: PropTypes.func.isRequired,
  handleClickOpenImageEditModal: PropTypes.func.isRequired,
  handleClickOpenVideoThumbnailModal: PropTypes.func.isRequired,
  handleClickOpenVideoPickSoundModal: PropTypes.func.isRequired,
  handleUpdateAudioCover: PropTypes.func.isRequired,
  imageExtensions: PropTypes.string.isRequired,
  updatedData: PropTypes.object,
  handleUpdateMediaInMediaDetailModal: PropTypes.func.isRequired,
  handleClickOpenPosts: PropTypes.func,
  handleClickOpenMediaDynamicModal: PropTypes.func.isRequired,
}

MediaDetailModal.displayName = 'MediaMediaDetailModal'

export default MediaDetailModal
